import { Dialog, DialogContent } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import Slot from '../../common/Slots/Slot'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '& .paperWidthSm': {
      maxWidth: '715px !important'
    },
    text: {
      color: theme.palette.text.hint,
      fontSize: 12
    },
    dialogContainer: {
      marginTop: '50px'
    }
  })
)

type Props = {
  open: boolean
  title?: string
  content?: string
  onClose?: () => void
  onSubmit?: () => void
}

const TreatmentDialog: React.FunctionComponent<Props> = ({
  open,
  title,
  content,
  onClose,
  children
}) => {
  const classes = useStyles()

  return (
    <Dialog className={classes.dialogContainer} open={open} maxWidth={'md'} onClose={onClose} fullWidth>
      <Slot title={title} color='primary' noBorder>
        {children}

        {content && (
          <DialogContent className={classes.text}>{content}</DialogContent>
        )}

        {/* <DialogActions> */}
        {/* <Button onClick={onClose} color="default">
            Отмена
          </Button> */}
        {/* <Button onClick={onClose} color="primary">
            Готово
          </Button> */}
        {/* </DialogActions> */}
      </Slot>
    </Dialog>
  )
}

export default TreatmentDialog

import React, { MouseEventHandler, useEffect, useState } from 'react';
import { SpecialtiesInterface } from '../../../api/chatApi/chatApi';
import Pagination from '@material-ui/lab/Pagination';
// @ts-ignore
import { DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, Tabs, TabList, Tab, ProgressIndicator, ProgressStep, Dropdown, TabPanels, TabPanel, RadioButtonGroup, RadioButton, Button, } from '@carbon/react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import 'carbon-components/scss/globals/scss/styles.scss';
import ArrowRight from '../../../assets/img/arrow/ArrowRightDefault.svg'
import ArrowLeft from '../../../assets/img/arrow/ArrowLeftDefault.svg'
import ImgChamp from '../../../assets/img/social_link/test.webp';


import { FormGroup, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
interface AccreditationElementInterface {
  changeTypeDoctor: (type: any) => void
  changeAttestationTypeDoctor: (type: any) => void
  typeDoctor: any
  attestationType: any
  allDoctorsTypeList: SpecialtiesInterface[]
  indexTab: number
  allQuestions: any[]
  changeRadioHandler: (value: any, event: React.ChangeEvent<HTMLInputElement> | any, id: number) => void
  sendArray: () => void
  countingResultsList: any[]
  countingAttestationList: any[]
  listOfAnswers: number[]
  currentAnswer: number | undefined
  getAccreditationTestHandler: () => Promise<void>
  testType: any[]
  handleBackButtonClick: () => void
}

const useStyles = makeStyles((theme: any) => ({
  customClassRadioContainer: {

    '& div.cds--radio-button-wrapper': {
      marginLeft: '16px'
    },
    '& .cds--label': {
      marginBottom: '18px',
      marginTop: '10px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      fontFamily: 'Helvetica Neue'
    },
    '& .cds--form-item makeStyles-customClassRadioContainer': {
      marginBottom: '16px'
    },
    '& .cds--radio-button__appearance': {
      border: '1px solid #2096F2'
    },
    '& .cds--radio-button:checked + .cds--radio-button__label .cds--radio-button__appearance::before': {
      backgroundColor: '#2096F2',
    },
    '& .cds--radio-button:checked + .cds--radio-button__label .cds--radio-button__appearance': {
      borderColor: '#2096F2'
    }
  },
  customTabsClass: {
    // marginTop: '50px',
    '& .cds--tabs .cds--tab--list': {
      width: '100%',
    },
    '& .cds--tabs .cds--tabs__nav-link': {
      minWidth: '50%',
      textAlign: 'center',
      padding: 0
    },
    '& .cds--tabs .cds--tab--overflow-nav-button--next': {
      display: 'none'
    },
    '& .cds--tabs .cds--tab--overflow-nav-button--previous': {
      display: 'none'
    },
    '& .cds--tab-content': {
      padding: 0,
      paddingTop: '1rem'
    }
  },
  comboboxContainer: {
    display: 'flex',
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      '& .cds--dropdown': {
        // width: 208
      }
    },
  },
  leftArrow: {},
  rightArrow: {},
  questionsCustomContainer: {},
  progressBarContainer: {},
  tableColor: {
    background: '#FFFFFF'
  },
  wrapperImg: {
    width: '38%',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  wrapperText: {
    backgroundColor: 'black',
    flexGrow: 3,
    width: '62%',
    paddingLeft: 88
  },
  wrapperInfo: {
    display: 'flex',
    width: '100%',
    height: '352px',
    // marginTop: 48,
    marginBottom: 20,
  },
  title: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 25,
    margin: '50px 0 20px 0',
    color: 'white'
  },
  pagination: {
    marginBottom: 20
  }
}))

export default function AccreditationElement(
  {
    changeTypeDoctor,
    typeDoctor,
    allDoctorsTypeList,
    indexTab,
    changeRadioHandler,
    allQuestions,
    sendArray,
    countingResultsList,
    listOfAnswers,
    currentAnswer,
    getAccreditationTestHandler,
    changeAttestationTypeDoctor,
    countingAttestationList,
    attestationType,
    testType,
    handleBackButtonClick
  }: AccreditationElementInterface
): JSX.Element {

  const [invalid, setInvalid] = useState(false)
  const style = useStyles()
  const [page, setPage] = useState(1);
  const [questionsPerPage] = useState(10);

  const headerData = [
    {
      key: 'id',
      header: '№'
    },
    {
      key: 'kind',
      header: 'специальность'
    },
    {
      key: 'date',
      header: 'Дата'
    },
    {
      key: 'percent',
      header: 'Процент выполнения'
    },
    {
      key: 'estimate',
      header: 'Оценка'
    }
  ]

  const container = document.getElementById('progressBarContainer')
  let unit = 200

  function scrollRight(event: any): any {
    const scrollLeft = container!.scrollLeft
    container!.scrollBy({
      top: 0,
      left: scrollLeft! + unit,
      behavior: 'smooth',
    })
  }

  function scrollLeftHandler(event: any) {
    const scrollLeft = container!.scrollLeft
    container?.scrollTo({
      top: 0,
      left: scrollLeft! - 350,
      behavior: 'smooth',
    });
  }

  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(1)
  }, [allQuestions])

  const indexOfLastQuestion = page * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = allQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);
  console.log('TESTTYPE', testType);
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', marginBottom: 20 }}>
      <div className={style.wrapperInfo}>
        <div className={style.wrapperText}>
          <h1 className={style.title}>Тестирование</h1>
        </div>
        <div className={style.wrapperImg}>
          <img className={style.img} src={ImgChamp} alt='img' />
        </div>
      </div>
      <div style={{ width: '90%' }} className={style.customTabsClass}>
        <Tabs selectedIndex={indexTab} >
          <TabList aria-label={''}>
            <Tab>
              Выбор специальности
            </Tab>
            <Tab>Пройдите тест</Tab>
            {/*<Tab>Результаты</Tab>*/}
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className={style.comboboxContainer}>
                <div style={{ marginBottom: '2%', width: '100%', marginRight: '1%' }}>
                  {allDoctorsTypeList &&
                    <Autocomplete
                      id={'1'}
                      options={allDoctorsTypeList ? allDoctorsTypeList : []}
                      key={'autocompleteMedicalType'}
                      getOptionLabel={(item: any) => (item.doctors_info_name)}
                      onChange={(event, newValue) => {
                        changeTypeDoctor(newValue);
                      }}
                      value={typeDoctor}
                      renderInput={(params) => (
                        <TextField {...params} label="Выберите специальность" variant="outlined" />
                      )}
                    />
                  }
                </div>
                <div style={{ marginBottom: '2%', width: '100%', marginRight: '1%' }}>
                  {allDoctorsTypeList &&
                    <Autocomplete
                      id={'2'}
                      options={testType ? testType : []}
                      key={'autocompleteAttestationMedicalType'}
                      getOptionLabel={(item) => (item ? item.name : '')}
                      onChange={(event, selectedItem) => changeAttestationTypeDoctor(selectedItem)}
                      value={attestationType}
                      renderInput={(params) => {
                        return (
                          <TextField {...params} label="Выберите тип теста" variant="outlined" />
                        )
                      }}
                    />
                  }
                </div>
              </div>
              <Button style={{ borderRadius: 4, fontSize: 15 }} onClick={getAccreditationTestHandler}>Приступить к тесту</Button>
              {countingResultsList.length > 0 &&
                <>
                  <div style={{ margin: '45px 0 0 auto' }}>
                    <p style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, fontSize: 32 }}>
                      История прохождения аккредитационного теста
                    </p>
                  </div>

                  <div style={{ marginBottom: '32px', marginTop: '32px' }}>
                    {
                      <DataTable
                        rows={countingResultsList}
                        headers={headerData}
                        isSortable
                      >
                        {// @ts-ignore
                          ({ rows, headers, getHeaderProps, getTableProps }) => {
                            return (
                              <TableContainer title="">
                                <Table {...getTableProps()} className={style.tableColor}>
                                  <TableHead>
                                    <TableRow>
                                      {headers.map((header: any) => (
                                        <TableHeader scope={''}>
                                          {header.header}
                                        </TableHeader >
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row: any) => {
                                      return (
                                        <TableRow key={row.id}>
                                          {row.cells.map((cell: any) => {
                                            return (
                                              <TableCell key={cell.id}>{cell.value}</TableCell>
                                            )
                                          })}
                                        </TableRow>
                                      )
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )
                          }}
                      </DataTable>
                    }
                  </div>
                </>
              }

              {countingAttestationList.length > 0 &&
                <>
                  <div style={{ margin: '45px 0 0 auto' }}>
                    <p style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, fontSize: 32 }}>
                      История прохождения аттестационного теста
                    </p>
                  </div>
                  <div style={{ marginBottom: '32px', marginTop: '32px' }}>
                    {
                      <DataTable
                        rows={countingAttestationList}
                        headers={headerData}
                        isSortable
                      >
                        {// @ts-ignore
                          ({ rows, headers, getHeaderProps, getTableProps }) => {
                            return (
                              <TableContainer title="">
                                <Table {...getTableProps()} className={style.tableColor}>
                                  <TableHead>
                                    <TableRow>
                                      {headers.map((header: any) => (
                                        <TableHeader scope={''}>
                                          {header.header}
                                        </TableHeader >
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row: any) => {
                                      return (
                                        <TableRow key={row.id}>
                                          {row.cells.map((cell: any) => {
                                            return (
                                              <TableCell key={cell.id}>{cell.value}</TableCell>
                                            )
                                          })}
                                        </TableRow>
                                      )
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )
                          }}
                      </DataTable>
                    }
                  </div>
                </>
              }
            </TabPanel>
            <TabPanel style={{ padding: 0 }}>
              <div
                className={style.questionsCustomContainer}
                style={{ display: 'flex', justifyContent: 'center', width: 'auto', overflow: 'hidden', height: 35, marginTop: 10, cursor: 'pointer' }}
              >
                {allQuestions.length > 10 &&
                  <div
                    onClick={scrollLeftHandler}
                    id={'leftArrow'}
                    style={{
                      display: 'flex',
                      width: 20,
                      height: 35,
                      marginRight: 15,
                      marginTop: 3
                    }}>
                    <img style={{ height: 25, width: 25 }} src={ArrowLeft} alt="arrowLeft" />
                  </div>}
                <div id={'progressBarContainer'} style={{ width: '100%', height: 50, overflowX: 'scroll' }}>
                  <ProgressIndicator vertical={false} currentIndex={currentAnswer}>
                    {allQuestions.map((count, index) => {
                      return (
                        <ProgressStep
                          complete={listOfAnswers[index] !== undefined}
                          invalid={listOfAnswers[index] === undefined}
                          secondaryLabel={invalid && index === currentAnswer ? "Выберите вариант ответа" : null}
                          key={index}
                          label={`Вопрос ${index + 1}`}
                        />
                      )
                    })}
                  </ProgressIndicator>
                </div>
                {allQuestions.length > 10 &&
                  <div
                    onClick={scrollRight}
                    id={'rightArrow'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: 20, height: 20,
                      marginLeft: 15,
                      marginTop: 3,
                      cursor: 'pointer'
                    }}
                  >
                    <img style={{ height: 25, width: 25 }} src={ArrowRight} alt="ArrowRight" />
                  </div>}
              </div>
              {currentQuestions.map((question, index) => {
                const questionIndex = (page - 1) * questionsPerPage + index;
                return (
                  <div style={{ margin: 32, marginBottom: '32px' }} key={`${index}${question.accreditation_question_text}`}>
                    <RadioButtonGroup
                      orientation={'vertical'}
                      color={'red'}
                      className={style.customClassRadioContainer}
                      legendText={`${(page - 1) * 10 + index + 1}. ${question.accreditation_question_text}`}
                      name={question.accreditation_question_text}
                      valueSelected=''
                      key={`${question.accreditation_question_text}${question.accreditation_question_id}`}
                      onChange={(event: any) =>
                        changeRadioHandler(question, event, questionIndex)}
                    >
                      {question.answers.map((answer: any) => (
                        <RadioButton
                          labelText={answer.accreditation_answers_text}
                          value={answer.accreditation_answers_id}
                          id={`${answer.accreditation_answers_id}`}
                          key={`${answer.accreditation_answers_text}${answer.accreditation_answers_id}`}
                        />
                      ))}
                    </RadioButtonGroup>
                  </div>
                )
              })
              }
              {allQuestions.length > 10 &&
                <Pagination
                  count={Math.ceil(allQuestions.length / questionsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  className={style.pagination}
                />
              }

              {allQuestions.length !== 0 &&
                <div>
                  <Button style={{ borderRadius: 4, marginRight: 50 }} onClick={handleBackButtonClick}>Вернуться в меню</Button>
                  <Button style={{ borderRadius: 4 }} onClick={sendArray}>Отправить ответы</Button>
                </div>
              }
            </TabPanel>
            <TabPanel>

            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import MainFooter from '../../components/MainFooter/MainFooter';
import MainHeader from '../../components/MainHeader/MainHeader';
import { Form, TextInput, TextArea } from '@carbon/react';
import { useSnackbar } from 'notistack';
import chatApi from "../../api/chatApi";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import ImgChamp from '../../assets/img/social_link/ImgChamp.svg';


const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'left',
      width: '50%',
      marginLeft: '5%',
      [theme.breakpoints.down('xs')]: {
        width: '90%'
      }
    },
    title: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 20,
      margin: '30px 0 20px 86px',
      color: 'white',
      [theme.breakpoints.down('xs')]: {
        margin: '30px 10px 20px 20px',
      }
    },
    current: {
      minWidth: 350,
      width: '100%',
      padding: 20,
      boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: 50,
    },
    customBtn: {
      borderRadius: 4,
      height: 40,
      width: 175,
      maxWidth: 225,
      background: '#0f62fe',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      marginTop: 30,
      '&:hover': {
        background: '#0050e6'
      },
      '@media (max-width: 800px)': {
        width: 225,
      },
    },
    rowInput: {
      marginTop: 10,
    },
    wrapperImg: {
      width: '32%',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    wrapperText: {
      backgroundColor: 'black',
      flexGrow: 3,
      width: '62%'
    },
    wrapperInfo: {
      display: 'flex',
      width: '100%',
      height: '362px',
      marginTop: 48,
      marginBottom: 20,
    },
    contentContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    footerWrapper: {
      flexShrink: 0,

    }
  })
)

interface FeedbackFormState {
  text: string;
  topic: string;
  email: string;
}

const Connection: React.FC = () => {
  const [formState, setFormState] = useState<FeedbackFormState>({
    text: '',
    topic: '',
    email: '',
  });
  const classes = useRowStyles()
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { text, topic, email } = formState;

    if (!text.trim() || !topic.trim() || !email.trim()) {
      enqueueSnackbar('Пожалуйста, заполните все поля', { variant: 'error' });
      return;
    }
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      enqueueSnackbar('Пожалуйста, введите корректный email', { variant: 'error' });
      return;
    }
    try {
      const response = await chatApi.sendUsersFeedback(text, topic, email);
      console.log('response', response);

      if (response.success === true) {
        enqueueSnackbar('Форма успешно отправлена!', { variant: 'success' });
        setFormState({ text: '', topic: '', email: '' });
      }
    } catch (error) {
      enqueueSnackbar(`Не удалось отправить форму: ${error.message}`, { variant: 'error' });
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <MainHeader showComponentHelpFilled={false} />
      <div className={classes.mainContainer}>
        <div className={classes.contentContainer}>
          <div className={classes.wrapperInfo}>
            <div className={classes.wrapperText}>
              <h1 className={classes.title}>Если у Вас остались вопросы, свяжитесь с нами, заполнив форму ниже</h1>
            </div>
            <div className={classes.wrapperImg}>
              <img className={classes.img} src={ImgChamp} alt='img' />
            </div>
          </div>
          <div className={classes.formContainer}>
            <Form onSubmit={handleSubmit} className={classes.current} noValidate>
              <Tooltip title='' placement="top">
                <div>
                  <TextInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Введите ваш email"
                    value={formState.email}
                    onChange={handleInputChange}
                    required
                    className={classes.rowInput}
                    labelText={undefined} />
                  <TextInput
                    id="topic"
                    name="topic"
                    placeholder="Тема сообщения"
                    value={formState.topic}
                    onChange={handleInputChange}
                    required
                    className={classes.rowInput}
                    labelText={undefined} />

                  <TextArea
                    id="text"
                    name="text"
                    placeholder="Введите Ваше сообщение"
                    value={formState.text}
                    onChange={handleInputChange}
                    required
                    className={classes.rowInput}
                    labelText={undefined}
                  />
                </div>
              </Tooltip>
              <button type="submit"
                className={classes.customBtn}
              >
                Отправить
              </button>
            </Form>
          </div>
        </div>
        <div className={classes.footerWrapper}>
          <MainFooter />
        </div>
      </div>
    </div>
  );
};

export default Connection;

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getTestingstageTaskOnTask } from '../../api/chatApi/chatApi'
import { useLocation } from 'react-router-dom';
import RatingDialog from '../../components/Chat/RatingDialog'
import CustomButton from '../../components/common/CustomButton'
import CustomHeader1 from '../../components/common/CustomHeader1'
import Slot from '../../components/common/Slots/Slot'
import SlotContainerMasonry from '../../components/common/Slots/SlotContainerMasonry'
import TooltipDialog from '../../components/common/TooltipDialog'
import MainLayout from '../../components/Layout/MainLayout'
import useDiagnosisTypes from '../../hooks/diagnosis/useDiagnosisTypes'
import useTooltipState from '../../hooks/useTooltipState'
import { SagaActionType } from '../../store/sagas/sagaActions'
import { RoutePath } from '../../types'
import CompetingClinicalDiagnosisField from '../../components/PageBlocks/ClinicalDiagnosis/CompetingClinicalDiagnosisField'
import ComplicationClinicalDiagnosisField from '../../components/PageBlocks/ClinicalDiagnosis/ComplicationClinicalDiagnosisField'
import AccompanyingClinicalDiagnosisField from '../../components/PageBlocks/ClinicalDiagnosis/AccompanyingClinicalDiagnosisField'
import MainClinicalDiagnosisField from '../../components/PageBlocks/ClinicalDiagnosis/MainClinicalDiagnosisField'
import chatApi from '../../api/chatApi'
import useIncreaseTaskStage from "../../hooks/testingTask/useIncreaseTaskStage";
import TooltipAccordion from '../../components/TooltipAccordion'
import { usePointProcentStore } from './store'
import useTestingStage from '../../hooks/testingTask/useTestingStage';


export default function ClinicalDiagnosisPage() {
  const asyncStateDiagnosisTypes = useDiagnosisTypes()
  const diagnosisTypes = asyncStateDiagnosisTypes?.value
  const [status, setStatus] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)
  const [mainClinicalDiagnosis, setMainClinicalDiagnosis] = useState<any>()
  const [competingClinicalDiagnosis, setCompetingClinicalDiagnosis] = useState<any>()
  const [complicationClinicalDiagnosis, setComplicationClinicalDiagnosis] = useState<any>()
  const [accompanyingClinicalDiagnosis, setAccompanyingClinicalDiagnosis] = useState<any>()
  const [mainClinicalDiagnosisProceed, setMainClinicalDiagnosisProceed] = useState<any>()
  const [competingClinicalDiagnosisProceed, setCompetingClinicalDiagnosisProceed] = useState<any>()
  const [complicationClinicalDiagnosisProceed, setComplicationClinicalDiagnosisProceed] = useState<any>()
  const [accompanyingClinicalDiagnosisProceed, setAccompanyingClinicalDiagnosisProceed] = useState<any>()
  const getPointProcent = usePointProcentStore(state => state.getPoints)
  const [stages, setStages] = useState([]);
  const { routePath, routhPathCode, activeStage } = useNextStagePath(stages);
  const [loading, increase, setAsyncTaskStage] = useIncreaseTaskStage(RoutePath[routhPathCode], activeStage);
  const dispatch = useDispatch()
  const history = useHistory()
  const testingStage = useTestingStage()
  const location = useLocation();
  const [readyToSendDiagnosis, setReadyToSendDiagnosis] = useState<boolean>(false)
  const [taskProceedToggle, setTaskProceedToggle] = useState<boolean>(false)
  const [manualActiveStage, setManualActiveStage] = useState(false)

  const saveMainClinicalDiagnosis = (mainClinicalDiagnosis) => {
    setMainClinicalDiagnosis(mainClinicalDiagnosis)
    setReadyToSendDiagnosis(true)
  }
  const saveCompetingClinicalDiagnosis = (competingClinicalDiagnosis) => {
    setCompetingClinicalDiagnosis(competingClinicalDiagnosis)
    setReadyToSendDiagnosis(true)

  }
  const saveComplicationClinicalDiagnosis = (complicationClinicalDiagnosis) => {
    setComplicationClinicalDiagnosis(complicationClinicalDiagnosis)
    setReadyToSendDiagnosis(true)
  }
  const saveAccompanyingClinicalDiagnosis = (accompanyingClinicalDiagnosis) => {
    setAccompanyingClinicalDiagnosis(accompanyingClinicalDiagnosis)
    setReadyToSendDiagnosis(true)
  }

  useEffect(() => {
    dispatch({ type: SagaActionType.START_LOAD_DIAGNOSIS_FOMRMS })
  }, [dispatch])


  async function getHistoryStage() {
    try {
      const res = await chatApi.getTestingStageHistory()
      const result = res.result.find((el) => el.activeStageId === 12)
      if (result && (!stages || (stages && stages.length === 0))) {
        setManualActiveStage(true)
      } else {
        setManualActiveStage(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function setHistoryStage(testingStage) {
    try {
      const res = await chatApi.setTestingStageHistory(testingStage)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getHistoryStage()
  }, [stages])


  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  function useNextStagePath(stages) {
    const location = useLocation();
    const currentPath = location.pathname;
    if (!stages || stages.length === 0) {
      return { routePath: '', routhPathCode: '' };
    }
    const sortedStages = stages.sort((a, b) => a.activeStage - b.activeStage);
    const currentIndex = sortedStages.findIndex(stage => stage.routePath === currentPath);

    if (currentIndex !== -1 && currentIndex + 1 < sortedStages.length) {
      return {
        routePath: sortedStages[currentIndex + 1].routePath,
        routhPathCode: sortedStages[currentIndex + 1].routhPathCode,
        activeStage: sortedStages[currentIndex + 1].activeStage
      };
    }

    return { routePath: '', routhPathCode: '', activeStage: '' };
  }

  useEffect(() => {
    const getDialogStatus = async () => {
      const response = await chatApi.getSettingsPiloting();
      const statusValue = response.result[0].value;
      setStatus(statusValue === 'true');
    };

    getDialogStatus();
  }, []);

  const [returnedStage, setReturnedStage] = useState<boolean>(false);

  function checkNextStage() {
    if (stages.length > 0) {
      const res = stages.find(el => el.routePath === location.pathname)
      let passedStage = 0
      if (res) {
        passedStage = stages.indexOf(res) + 1
      }
      if (testingStage > passedStage) {
        setReturnedStage(true)
      }
    }
  }

  useEffect(() => {
    checkNextStage()
  }, [activeStage])



  async function goToNextStage() {
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);
    if (!currentStageObj) return;

    setHistoryStage(currentStageObj.activeStage)
    const currentActiveStage = currentStageObj.activeStage;

    // Найти следующий этап с наименьшим activeStage, который больше текущего activeStage
    const nextStageObj = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage)[0];

    if (nextStageObj && nextStageObj.legend_id) {
      history.push(RoutePath.legend);
    } else if (nextStageObj) {
      history.push(nextStageObj.routePath);
    }
  }
  useEffect(() => {
    getPointProcent()
  }, [])

  const getTaskProceedData = async () => {
    try {
      const resultTaskProceedAll = await chatApi.getClinicalDiagnosisTaskProceed();
      if (resultTaskProceedAll && resultTaskProceedAll.result.length > 0) {
        const resultTaskProceedClean = resultTaskProceedAll.result.filter(el => el.preliminaryDiagnosis === false)
        if (resultTaskProceedClean && resultTaskProceedClean.length > 0) {
          setTaskProceedToggle(true);
        }

        const mainDiagnoses = resultTaskProceedClean.filter(d => d.typeOfInformationLevelId === 4);
        const competingDiagnoses = resultTaskProceedClean.filter(d => d.typeOfInformationLevelId === 5);
        const complicationDiagnoses = resultTaskProceedClean.filter(d => d.typeOfInformationLevelId === 6);
        const accompanyingDiagnoses = resultTaskProceedClean.filter(d => d.typeOfInformationLevelId === 7);

        setMainClinicalDiagnosisProceed(mainDiagnoses.map(diagnosis => ({
          clinicalDiagnosis: diagnosis.clinicalDiagnosis.name,
          clinicalDiagnosisId: diagnosis.clinicalDiagnosisId,
          typeOfInformationLevelId: diagnosis.typeOfInformationLevelId
        })));

        setCompetingClinicalDiagnosisProceed(competingDiagnoses.map(diagnosis => ({
          clinicalDiagnosis: diagnosis.clinicalDiagnosis.name,
          clinicalDiagnosisId: diagnosis.clinicalDiagnosisId,
          typeOfInformationLevelId: diagnosis.typeOfInformationLevelId
        })));

        setComplicationClinicalDiagnosisProceed(complicationDiagnoses.map(diagnosis => ({
          clinicalDiagnosis: diagnosis.clinicalDiagnosis.name,
          clinicalDiagnosisId: diagnosis.clinicalDiagnosisId,
          typeOfInformationLevelId: diagnosis.typeOfInformationLevelId
        })));

        setAccompanyingClinicalDiagnosisProceed(accompanyingDiagnoses.map(diagnosis => ({
          clinicalDiagnosis: diagnosis.clinicalDiagnosis.name,
          clinicalDiagnosisId: diagnosis.clinicalDiagnosisId,
          typeOfInformationLevelId: diagnosis.typeOfInformationLevelId
        })));
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getTaskProceedData()
  }, [])


  const saveClinicalDiagnosis = async () => {
    try {
      const allDiagnoses = [
        ...(mainClinicalDiagnosis || []),
        ...(competingClinicalDiagnosis || []),
        ...(complicationClinicalDiagnosis || []),
        ...(accompanyingClinicalDiagnosis || []),
      ];

      const validDiagnoses = allDiagnoses.filter(diagnosis => diagnosis && diagnosis.clinicalDiagnosisId);

      if (validDiagnoses.length === 0) {
        console.log('Нет данных для сохранения');
        return;
      }

      await Promise.all(
        validDiagnoses.map(diagnosis =>
          chatApi.createClinicalDiagnosisTaskProceed(
            diagnosis.clinicalDiagnosisId,
            diagnosis.typeOfInformationLevelId,
            false
          )
        )
      );
      console.log('Клинические диагнозы успешно сохранены');
    } catch (error) {
      console.log('Ошибка при сохранении клинических диагнозов:', error);
    } finally {
      goToNextStage();
    }
  };


  const taskName = '16'

  return (
    <>
      {diagnosisTypes ? (
        <MainLayout
          title='Клинический диагноз'
        // onClickTooltip={openTooltip}
        >

          {/* {open ? <RatingDialog open={open} onSubmit={hideRatingDialog} /> : null} */}
          {/* <TooltipDialog {...tooltipState}>
            <PreDiagnosisTooltip />
          </TooltipDialog>
          <TooltipAccordion taskName={taskName} /> */}

          <div style={{ paddingBottom: `25px` }}>
            {taskProceedToggle ?
              <React.Fragment>
                <CustomHeader1>Клинический диагноз уже отправлен</CustomHeader1>
              </React.Fragment> :
              null}
            <React.Fragment>
              <SlotContainerMasonry>
                <Slot title={diagnosisTypes && diagnosisTypes[0]?.description} color='primary'>
                  <MainClinicalDiagnosisField
                    saveMainClinicalDiagnosis={saveMainClinicalDiagnosis}
                    initialDiagnosis={mainClinicalDiagnosisProceed}
                    taskProceedToggle={taskProceedToggle}
                  />
                </Slot>
                <Slot title={diagnosisTypes && diagnosisTypes[1]?.description} color='primary'>
                  <CompetingClinicalDiagnosisField
                    saveCompetingClinicalDiagnosis={saveCompetingClinicalDiagnosis}
                    initialDiagnosis={competingClinicalDiagnosisProceed}
                    taskProceedToggle={taskProceedToggle}
                  />
                </Slot>
                <Slot title={diagnosisTypes && diagnosisTypes[2]?.description} color='primary'>
                  <ComplicationClinicalDiagnosisField
                    saveComplicationClinicalDiagnosis={saveComplicationClinicalDiagnosis}
                    initialDiagnosis={complicationClinicalDiagnosisProceed}
                    taskProceedToggle={taskProceedToggle}
                  />
                </Slot>
                <Slot title={diagnosisTypes && diagnosisTypes[3]?.description} color='primary'>
                  <AccompanyingClinicalDiagnosisField
                    saveAccompanyingClinicalDiagnosis={saveAccompanyingClinicalDiagnosis}
                    initialDiagnosis={accompanyingClinicalDiagnosisProceed}
                    taskProceedToggle={taskProceedToggle}
                  />
                </Slot>
              </SlotContainerMasonry>
              {!taskProceedToggle ?
                <CustomButton
                  onClick={saveClinicalDiagnosis}
                  disabled={!readyToSendDiagnosis}
                >
                  Завершить шаг
                </CustomButton> :
                <CustomButton
                  onClick={goToNextStage}
                >
                  Следующий этап
                </CustomButton>}
            </React.Fragment>
          </div>
        </MainLayout>
      ) : (
        <p>Загрузка данных...</p>
      )}
    </>
  )
}



import React from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import SlotContainer from '../../../components/common/Slots/SlotContainer'
import SlotPlaceholder from '../../../components/common/Slots/SlotPlaceholder'
import MainLayout from '../../../components/Layout/MainLayout'
import InstrumentalDialog from '../../../components/PageBlocks/Research/Instrumental/InstrumentalDialog'
import InstrumentalSelectBlock from '../../../components/PageBlocks/Research/Instrumental/InstrumentalSelectBlock'
import LabSelectBlock from '../../../components/PageBlocks/Research/Lab/LabSelectBlock'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

export default function ResearchInstrumentalDialogPage() {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <InstrumentalDialog
        title='УЗИ'
        content='какая-нибудь подсказка'
        open={true}
      />
      <SlotContainer>
        <div>
          <LabSelectBlock />

          <SlotPlaceholder title='Здесь появятся данные лабораторных исследований' />
        </div>
        <div>
          <InstrumentalSelectBlock />

          <SlotPlaceholder title='Здесь появятся данные Инструментальных исследований' />
        </div>
      </SlotContainer>
    </MainLayout>
  )
}

import React, { useState, useEffect } from 'react';
//@ts-ignore
import { Form, Stack, TextInput, Button } from '@carbon/react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import chatApi from "../../api/chatApi";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: '49%',
      display: 'flex',
      flexDirection: 'column',
      '@media (max-width: 1280px)': {
        width: '98%',

      },
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 20,
      fontWeight: 500,
      margin: '10px 0',
    }
  })
)

const CreateProfile = ({ login, error, success, switch1, switchMy }) => {
  const classes = useStyles()
  const initial = { firstName: '', lastName: '', patronymic: '', email: '', langKey: 'ru', imageUrl: '', activate: true }

  const [datas, setDatas] = useState<any>(initial)
  const [invName, setInvName] = useState(false)
  const [invFam, setInvFam] = useState(false)
  const [invEmail, setInvEmail] = useState(0)

  const infoUser = async login => {
    await chatApi
      .getUserAdmin(login)
      .then(res => {
        setDatas({
          ...datas, firstName: res.result.firstName,
          lastName: res.result.lastName,
          patronymic: res.result.patronymic,
          email: res.result.email,
          activate: res.result.activated,
          langKey: 'ru'
        })

      })
      .catch(e => console.log(e))
  };


  useEffect(() => {
    infoUser(login)
  }, [switchMy]);

  const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
  function isEmailValid(value) {
    return EMAIL_REGEXP.test(value);
  }

  const valName = () => {
    if (datas.firstName.length < 1) {
      setInvName(true)
    }
    else {
      setInvName(false)
    }
  }


  const valFam = () => {
    if (datas.lastName.length < 1) {
      setInvFam(true)
    }
    else {
      setInvFam(false)
    }
  }

  const valEmail = (e) => {
    const val = isEmailValid(e)
    if (val === true) {
      setInvEmail(0)
    }
    if (e.length < 1) {
      setInvEmail(2)
    }
    if (val === false && e.length > 1) {
      setInvEmail(1)
    }
  }


  const sub = async () => {
    switch1(true)
    success(false)
    error(false)
    valName()
    valFam()
    valEmail(datas.email)
    if (invName === false
      && invFam === false
      && invEmail === 0
      && datas.firstName.length > 1
      && datas.lastName.length > 1
      && datas.email.length > 1) {
      const logins = login;
      await chatApi.editUserAdmin(logins,
        datas.firstName,
        datas.lastName,
        datas.patronymic,
        datas.email,
        datas.imageUrl,
        datas.langKey,
        datas.activate)
        .then(() => {
          success(true)
          error(false)
        })
        .catch(e => {
          success(false)
          error(true)
          console.log(e)
        })
        .finally(() => switch1(false))
      setDatas(initial);
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.title}>
        Личные данные
      </div>
      <div style={{
        minWidth: 200,
        padding: 20,
        boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 50
      }}>

        <Form>
          <Stack gap={7}>
            <TextInput
              id="test2"
              invalid={invFam}
              invalidText="Поле обязательно к заполнению."
              value={datas.lastName}
              labelText="Фамилия"
              onChange={(e) => setDatas({ ...datas, lastName: e.target.value })}
              onBlur={valFam}
            />
            <TextInput
              id="test2"
              invalid={invName}
              required
              invalidText="Поле обязательно к заполнению."
              labelText="Имя"
              value={datas.firstName}
              onChange={(e) => setDatas({ ...datas, firstName: e.target.value })}
              onBlur={valName}
            />
            <TextInput
              id="test2"
              labelText="Отчество"
              value={datas.patronymic}
              onChange={(e) => setDatas({ ...datas, patronymic: e.target.value })}
            />
            <TextInput
              id="test2"
              invalid={invEmail === 1 || invEmail === 2 ? true : false}
              invalidText={invEmail === 1 ? 'Неккоректный email.' : 'Поле обязательно к заполнению.'}
              labelText="Email"
              value={datas.email}
              onChange={(e) => setDatas({ ...datas, email: e.target.value })}
              onBlur={(e) => valEmail(e.target.value)}
            />
            {/* <Select
              id="select-1"
              invalidText="This is an invalid error message."
              labelText="Cтрана"
              value={datas.langKey}
              onChange={(e) => setDatas({ ...datas, langKey: e.target.value })}
            >
              <SelectItem
                text="Ru"
                value="ru"
              />
              <SelectItem
                text="En"
                value="en"
              />
              <SelectItem
                text="De"
                value="de"
              />
            </Select> */}
            <div>
              <Button
                kind="primary"
                tabIndex={0}
                onClick={sub}
                style={{ borderRadius: 4 }}
              >
                Сохранить
              </Button>
            </div>
          </Stack>
        </Form>
      </div>
    </div>
  );
}

export default CreateProfile;
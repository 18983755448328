import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import Slot from '../../../../common/Slots/Slot'
import InstrumentalDialogSelect from './InstrumentalDialogSelect'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.text.hint,
      fontSize: 12
    }
  })
)

type Props = {
  open: boolean
  title?: string
  content?: string
  onClose?: () => void
  onSubmit?: () => void
}

export default function InstrumentalDialog({
  open,
  title,
  content,
  onClose,
  onSubmit
}: Props) {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <Slot title={title} notPadded>
        <InstrumentalDialogSelect />

        {content && (
          <DialogContent className={classes.text}>{content}</DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose} color='default'>
            Отмена
          </Button>
          <Button onClick={onSubmit} color='primary'>
            Готово
          </Button>
        </DialogActions>
      </Slot>
    </Dialog>
  )
}

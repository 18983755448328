import { Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const grayBorderStyle = `url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: RGBA(0,0,0,0.12); stroke-width: 2; stroke-dasharray: 8 8'/></svg>")`
const BlueBorderStyle = `url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: RGBA(33,150,243,1); stroke-width: 2; stroke-dasharray: 8 8'/></svg>")`
const LightBlueBorderStyle = `url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: RGBA(203,232,255,1); stroke-width: 2; stroke-dasharray: 8 8'/></svg>")`

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 4,
      // border: `dashed 1px `,
      // borderColor: theme.palette.divider,
      backgroundImage: (p: Props) =>
        p.onClick ? LightBlueBorderStyle : grayBorderStyle,
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      cursor: 'pointer',
      '&:hover': {
        backgroundImage: BlueBorderStyle
      }
    },
    title: {
      display: 'inline-block',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 20,
      fontWeight: 300,
      padding: theme.spacing(2),
      color: (p: Props) =>
        p.onClick ? theme.palette.primary.main : theme.palette.divider
    }
  })
)

interface Props {
  title?: string
  onClick?: () => void
}

const SlotPlaceholder = ({ title, onClick }: Props) => {
  const classes = useStyles({ title, onClick } as Props)
  return (
    <Grid item xs={12} sm={12} onClick={onClick}>
      <div className={classes.root}>
        {title && <div className={classes.title}>{title}</div>}
      </div>
    </Grid>
  )
}

export default SlotPlaceholder

import { Hidden } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { ReactElement } from 'react'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 250
  },
  thead: {
    '& th': { fontWeight: 600 }
  },
  value: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    textAlign: 'center'
  }, cell: {
    textAlign: 'center',
  },
}))

export interface LaboratoryRowData {
  name: string
  value?: number
  unit: string
  reference: string
}

interface Props {
  rows: any
}

export default function TableSelectResearch({ rows }: Props) {
  return (
    <React.Fragment>
      {/* DESKTOP ONLY */}
      <Hidden xsDown>
        <DesctopTable rows={rows} />
      </Hidden>
      {/* MOBILE ONLY */}
      <Hidden smUp>
        <MobileTable rows={rows} />
      </Hidden>
    </React.Fragment>
  )
}

function DesctopTable({ rows }: Props): ReactElement {
  const classes = useStyles();
  return (
    <Table
      className={classes.table}
      size='small'
      aria-label='a lab research table'
    >
      <TableHead className={classes.thead}>
        <TableRow>
          <TableCell align='center'>Название</TableCell>
          <TableCell align='center'>Значение</TableCell>
          <TableCell align='center'>ед. изм</TableCell>
          <TableCell align='center'>Норма</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row: any, index: number) => {
          const formattedValue = row && row.value ? row.value.replace(/\n/g, '<br>') : '';
          const formattedReference = row && row.reference ? row.reference.replace(/\n/g, '<br>') : '';

          return (
            <TableRow key={index}>
              <TableCell component='th' scope='row' padding='none' className={classes.cell}>
                {row && row.name ? row.name : ''}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                <span dangerouslySetInnerHTML={{ __html: formattedValue }}></span>
              </TableCell>
              <TableCell align='center'>{row && row.unit ? row.unit : ''}</TableCell>
              <TableCell align='center' className={classes.cell}>
                <span dangerouslySetInnerHTML={{ __html: formattedReference }}></span>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const MobTh = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.divider,
      backgroundColor: theme.palette.primary.contrastText
    }
  })
)(TableCell)

const MobTd = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 600
      // backgroundColor: theme.palette.divider,
    },
    body: {
      border: 0
      // borderBottom: `10px solid ${theme.palette.divider}`,
    }
  })
)(TableCell)

const MobTr = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow)

function MobileTable({ rows }: Props) {
  const classes = useStyles()
  return (
    <Table size='small' aria-label='a dense table'>
      <TableHead>
        <MobTr>
          <MobTh style={{ color: 'black', fontSize: 10, alignContent: 'left' }}>Название</MobTh>
          <MobTh
            style={{ color: 'black', textAlign: 'center', fontSize: 10 }}
            align='right'
          >
            Значение
          </MobTh>
          <MobTh style={{ color: 'black', fontSize: 10 }} align='center'>
            ед. изм
          </MobTh>
          <MobTh style={{ color: 'black', fontSize: 10 }} align='center'>
            Норма
          </MobTh>
        </MobTr>
      </TableHead>

      {rows.map((row: any) => (
        <React.Fragment key={row.name}>
          <TableHead key={row.name}>
            <MobTr>
              {/* <MobTd component="th" scope="row" colSpan={3}>
                {row.name}
              </MobTd> */}
            </MobTr>
          </TableHead>
          <TableBody>
            {rows.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  style={{ fontSize: 9 }}
                  component='th'
                  scope='row'
                  padding='none'
                >
                  {row.name}
                </TableCell>
                <TableCell style={{ fontSize: 9 }} align='center'>
                  {row.value}
                </TableCell>
                <TableCell style={{ fontSize: 9 }} align='center'>
                  {row.unit}
                </TableCell>
                <TableCell style={{ fontSize: 9 }} align='center'>
                  {row.reference}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {/* <TableBody key={row.name}>
            <MobTr>
              <MobTd align="right"></MobTd>
              <MobTd align="right" className={classes.value}>
                {row.value}
              </MobTd>
              <MobTd align="right">{row.unit}</MobTd>
              <MobTd align="right">{row.reference}</MobTd>
            </MobTr>
          </TableBody> */}
        </React.Fragment>
      ))}
    </Table>
  )
}

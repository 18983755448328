import React, { ReactElement } from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import CustomButton from '../../../components/common/CustomButton'
import CustomHeader1 from '../../../components/common/CustomHeader1'
import SlotContainerMasonry from '../../../components/common/Slots/SlotContainerMasonry'
import MainLayout from '../../../components/Layout/MainLayout'
import DiagnosisResultSlot from '../../../components/PageBlocks/Diagnosis/DiagnosisResultSlot'
import ResultSlot from '../../../components/PageBlocks/Diagnosis/ResultSlot'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

export type iResItem = {
  success: boolean
  title: string
  points: number
  quantity?: number
  link?: any
}
export type iResItems = iResItem[]

const goToSomeStep = () => {
  alert('Go To step Number...')
}

const OPROS_RESULTS: iResItems = [
  { success: false, title: 'Некорректные вопросы', points: -120 },
  { success: true, title: 'Корректные вопросы', points: 250 },
  {
    success: false,
    title: 'Не заданные вопросы',
    points: -130,
    quantity: 3,
    link: goToSomeStep
  }
]
const OSMOTR_RESULTS: iResItems = [
  { success: true, title: 'Измерение давления', points: 420 },
  { success: true, title: 'Измерение пульса', points: 250 },
  { success: false, title: 'Фото Бедра', points: -200 },
  {
    success: false,
    title: 'Не сделанные',
    points: -400,
    quantity: 2,
    link: goToSomeStep
  }
]
const RESEARCH_RESULTS: iResItems = [
  { success: false, title: 'УЗИ Матки', points: -120 },
  { success: true, title: 'Рентген бедра', points: 250 },
  { success: true, title: 'Анализ мочи', points: 250 },
  {
    success: false,
    title: 'Не сделанные',
    points: -400,
    quantity: 4,
    link: goToSomeStep
  }
]

export default function DiagnosisSuccessPage(): ReactElement {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <CustomHeader1>Успех!</CustomHeader1>
      <SlotContainerMasonry>
        <ResultSlot title='Жалобы и Анамнез' results={OPROS_RESULTS} />
        <ResultSlot title='Статус' results={OSMOTR_RESULTS} />
        <ResultSlot
          title='Дополнительное обследование'
          results={RESEARCH_RESULTS}
        />
        <DiagnosisResultSlot />
      </SlotContainerMasonry>
      <CustomButton>Вперед!</CustomButton>
    </MainLayout>
  )
}

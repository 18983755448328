import React, { useEffect, useState } from 'react';
import chatApi from '../../../api/chatApi';
import { checkRole } from '../../../common/checkRole';
import { makeStyles } from '@material-ui/core/styles';
import { allUsers } from '../../../api/chatApi/chatApi';
import Role from './options/Role';
import { ToastNotification } from '@carbon/react';
import EnhancedTable from './newRole';
import { useDebounce } from 'react-use';


const useRowStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0 10px',
  },
  btn: {
    position: 'relative',
    background: '#2196f3',
    color: 'white',
    textTransform: 'uppercase',
    border: 'none',
    marginTop: 20,
    padding: 20,
    fontSize: 16,
    display: 'block',
    appearance: 'none',
    borderRadius: 4,
    width: '50%',
    fontWeight: 400,
    letterSpacing: '0.5rem',
    transition: '0.3s all',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
    },
  },
  p: {
    color: '#bf1650',
    margin: 0,
    marginBottom: 20,
    '&:before': {
      display: 'inline',
      content: '⚠',
    },
  },
  text: {
    fontSize: 17,
    fontWeight: 600,
    marginBottom: 30,
    display: 'flex',
  },
});

const Role1 = () => {
  const classes = useRowStyles();

  const roleUserNews = [
    { role: 'ROLE_USER', toggle: false },
    { role: 'ROLE_ADMIN', toggle: false },
    { role: 'ROLE_TEST_TRAINING_CHECK', toggle: false },
    { role: 'ROLE_ADMIN_ORG', toggle: false },
    { role: 'ROLE_ADMIN_TRAINER', toggle: false },
    { role: 'ROLE_ANALIST', toggle: false },
    { role: 'ROLE_DEPERSONALIZED_DATA', toggle: false },
    { role: 'ROLE_EXPERT', toggle: false },
    { role: 'ROLE_STUDENT', toggle: false },
    { role: 'ROLE_TEACHER', toggle: false },
    { role: 'ROLE_CONSTRUCTOR', toggle: false },
    { role: 'ROLE_MESSAGE_ANSWER', toggle: false },
  ]

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(0);
  const [authority, setAuthority] = useState(0);
  const [roleUser, setRoleUser] = useState([]);
  const [roleUserNew, setRoleUserNew] = useState(roleUserNews);
  const [login, setLogin] = useState('');
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false)
  const [text, setText] = useState('')
  const [filterUser, setFilterUser] = useState([])

  const getUsers = async () => {
    setLoading(true)
    await allUsers()
      .then(res => {
        setUsers(res.data.all_users);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  };

  useDebounce(
    async () => {
      setLoading(true)
      const keys = ['fio', 'login']
      if (!text) {
        return getUsers();
      }
      await
        allUsers()
          .then(res => {
            const newFilter = res.data.all_users.filter((row) => keys.some(key => {
              if (row[key] !== null) {
                return row[key].toLowerCase().includes(text.toLowerCase())
              }
            }));
            setUsers(newFilter);
          })
          .catch(err => console.log(err))
          .finally(() => setLoading(false))
    },
    700,
    [text]
  )

  const getUserRole = async id => {
    setWarning(false)
    setLoading(true);
    await chatApi
      .createRole(id)
      .then(res => {
        setLoading(false);
        let results = [];
        const cloneExist = [...res.result.exist];
        const cloneNonExist = [...res.result.nonExist];
        cloneExist.map(el => results.push({ role: el, toggle: true }));
        cloneNonExist.map(el => results.push({ role: el, toggle: false }));
        const sortResults = results.sort((a, b) => parseFloat(a.role) - parseFloat(b.role));
        setRoleUser(sortResults);
      })
      .catch(err => {
        setRoleUser([])
        setWarning(true)
      });
  };

  const toggleRole = async (toggle, role) => {
    let arr = []
    const newRole = roleUser.map(el => (el.role === role ? { ...el, toggle: !toggle } : el));
    const filterRole = newRole.filter(el => {
      if (el.toggle) {
        arr.push(el.role)
      }
    })
    const sortResults = newRole.sort((a, b) => parseFloat(a.role) - parseFloat(b.role));
    setRoleUser(sortResults);
    if (toggle === false) {
      await chatApi
        .addRole(userId, arr)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    }
    if (toggle === true) {
      await chatApi
        .removeRole(userId, role)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    }
  };

  const toggleRoleNew = async (toggle, role) => {
    console.log(filterUser);
    let arr = []
    const newRole = roleUserNew.map(el => (el.role === role ? { ...el, toggle: !toggle } : el));
    const filterRole = newRole.filter(el => {
      if (el.toggle) {
        arr.push(el.role)
      }
    })
    const sortResults = newRole.sort((a, b) => parseFloat(a.role) - parseFloat(b.role));
    setRoleUserNew(sortResults);
    if (toggle === false) {
      filterUser.map(async (el) => {
        await chatApi
          .addRole(el.user_id, arr)
          .then(res => console.log(res))
          .catch(err => console.log(err));
      })

    }
    if (toggle === true) {
      filterUser.map(async (el) => {
        await chatApi
          .removeRole(el.user_id, role)
          .then(res => console.log(res))
          .catch(err => console.log(err));
      })
    }
  };

  const getUserId = (jhiUserId, userId, login) => {
    setLogin(login);
    setUserId(jhiUserId);
    setAuthority(userId);
  };


  useEffect(() => {
    checkRole();
    getUsers();
    chatApi.addOrg();
  }, []);

  useEffect(() => {
    if (userId !== 0)
      getUserRole(userId);
  }, [userId]);


  return (
    <div style={{ margin: '0' }}>
      <div >
        {
          warning &&
          <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
            <ToastNotification
              kind="warning"
              hideCloseButton={false}
              lowContrast={false}
              title="Ошибка"
              timeout={10000}
            />
          </div>
        }
        <div className={classes.title}>Редактор ролей</div>
        <div>
          <EnhancedTable
            users={users}
            setFilterUser={setFilterUser}
            getUserId={getUserId}
            setRoleUserNew={setRoleUserNew}
            role={roleUserNews}
            setText={setText}
            text={text} />
        </div>
      </div>
      {filterUser.length === 1 ? (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
          <div className={classes.title}>Пользователь: {filterUser[0].fio !== '  ' ? filterUser[0].fio : filterUser[0].login}</div>
          <Role toggleRole={toggleRole} roleUser={roleUser} />
        </div>
      ) : filterUser.length > 1 ? (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
          <div className={classes.title}> Выбрано {filterUser.length} {filterUser.length > 4 ? 'пользователей' : 'пользователя'}</div>
          <Role toggleRole={toggleRoleNew} roleUser={roleUserNew} />
        </div>
      )
        : null}

    </div>
  );
}

export default Role1;

import { Grid } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    media: {
      width: '100%',
      heigth: '100%',
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      textAlign: 'center',
      margin: '0 auto',
      paddingBottom: theme.spacing(1)
    }
  })
)

interface Props {
  children?: any
}

const SlotMedia = ({ children }: Props) => {
  const classes = useStyles()
  return (
    <Grid xs={12}>
      <div className={classes.media}>{children}</div>
    </Grid>
  )
}

export default SlotMedia

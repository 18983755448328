import React from 'react'
import { Avatar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { User } from '../../types'
import { pxToRem, pxToRemGutter } from '../../utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: mini => (mini ? 'row' : 'column'),
    justifyContent: mini => (mini ? 'space-around' : 'center'),
    alignItems: 'center',
    padding: pxToRemGutter(20, 24, 20)
  },
  avatar: {
    width: mini => (mini ? pxToRem(24) : pxToRem(116)),
    height: mini => (mini ? pxToRem(24) : pxToRem(116)),
    margin: '0px 2px',
    marginBottom: mini => (mini ? '0' : '17px')
  },
  name: {
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: '#212936'
  }
}))

type Props = {
  user: User
  mini: boolean
}
export default function UserInfo({ user, mini }: Props) {
  const classes = useStyles(mini)

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar} alt={user.name} src={`data:image/jpeg;base64,${user.avatar}`} />
      <Typography className={classes.name}>{user.name}</Typography>
    </div>
  )
}

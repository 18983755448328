import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'
import { pxToRemGutter } from '../../../../../utils'
import SearchInput from '../../../../common/SearchInput'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: pxToRemGutter(15, 15, 0, 15),
      minWidth: 250
    },
    text: {
      color: theme.palette.text.primary
    }
  })
)

export default function InstrumentalDialogSelect(): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <SearchInput />
    </div>
  )
}

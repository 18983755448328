import React from 'react'


interface ArrowRightTopInterface {
  color: string
}
export default function ArrowRightTop ({color}: ArrowRightTopInterface) {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 0V2H16.59L0 18.59L1.41 20L18 3.41V16H20V0H4Z" fill={color} />
      </svg>
    </>
  )
}

import './style.css';
import { useState, useEffect } from 'react';
import GfgInput from './GfgInput';
import React from 'react';

interface Form148Props {
  onInputChange?: (id: number, value: string) => void;
  initialValues?: { [key: number]: string };
  isReadOnly?: boolean;
  isShow?: boolean;
  onCheckboxStateChange?: (checkboxStates: { [key: number]: boolean }) => void;
  onCheckboxWrongAnswerStateChange?: (checkboxStates: { [key: number]: boolean }) => void;
  isShowWrongAnswerCheckbox?: boolean
}

export default function Form148({
  onInputChange,
  initialValues,
  isReadOnly,
  isShow,
  onCheckboxStateChange,
  onCheckboxWrongAnswerStateChange,
  isShowWrongAnswerCheckbox
}: Form148Props) {
  const [value, setValue] = useState(initialValues?.[1] || '');
  const [number, setNumber] = useState(initialValues?.[2] || '');
  const [patientFIO, setPatientFIO] = useState(initialValues?.[6] || ''); 
  const [date, setDate] = useState(initialValues?.[3] || '');
  const [month, setMonth] = useState(initialValues?.[4] || '');
  const [year, setYear] = useState(initialValues?.[5] || '');
  const [dateBirth, setDateBirth] = useState(initialValues?.[7] || ''); 
  const [address, setAddress] = useState(initialValues?.[8] || ''); 
  const [fio, setFio] = useState(initialValues?.[9] || ''); 
  const [dotted1, setDotted1] = useState(initialValues?.[10] || '');
  const [dotted2, setDotted2] = useState(initialValues?.[11] || '');
  const [dotted3, setDotted3] = useState(initialValues?.[12] || '');
  const [dotted4, setDotted4] = useState(initialValues?.[13] || '');

  const [checkboxStates, setCheckboxStates] = useState<{ [key: number]: boolean }>(() => {
    const initialCheckboxState = {};
    for (let i = 1; i <= 13; i++) {
      initialCheckboxState[i] = initialValues?.[i] === 'true';
    }
    return initialCheckboxState;
  });

  useEffect(() => {
    if (initialValues) {
      setValue(initialValues[1] || '');
      setNumber(initialValues[2] || '');
      setDate(initialValues[3] || '');
      setMonth(initialValues[4] || '');
      setYear(initialValues[5] || '');
      setPatientFIO(initialValues[6] || ''); 
      setDateBirth(initialValues[7] || ''); 
      setAddress(initialValues[8] || '');
      setFio(initialValues[9] || '');
      setDotted1(initialValues[10] || '');
      setDotted2(initialValues[11] || '');
      setDotted3(initialValues[12] || '');
      setDotted4(initialValues[13] || '');

      setCheckboxStates(() => {
        const initialCheckboxState = {};
        for (let i = 1; i <= 13; i++) {
          initialCheckboxState[i] = initialValues[i] === 'true';
        }
        return initialCheckboxState;
      });
    }
  }, [initialValues]);

  const handleInputChange = (id, value) => {
    if (id === 3 && isNaN(Date.parse(value))) {
      console.error('Invalid date format');
      return;
    }
    if (onInputChange) {
      onInputChange(id, value);
    }

    switch (id) {
      case 1:
        setValue(value);
        break;
      case 2:
        setNumber(value);
        break;
      case 3:
        setDate(value);
        break;
      case 4:
        setMonth(value);
        break;
      case 5:
        setYear(value);
        break;
      case 6:
        setPatientFIO(value);
        break; 
      case 7:
        setDateBirth(value);
        break;
      case 8:
        setAddress(value);
        break;
      case 9:
        setFio(value);
        break;
      case 10:
        setDotted1(value);
        break;
      case 11:
        setDotted2(value);
        break;
      case 12:
        setDotted3(value);
        break;
      case 13:
        setDotted4(value);
        break;
      default:
        break;
    }
    if (onInputChange) {
      onInputChange(id, value);
    }
  };
  const handleCheckboxChange = (id) => {
    setCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };
      if (onCheckboxStateChange) {
        onCheckboxStateChange(newState);
      }
      return newState;
    });
  };


  const [checkboxWrongAnswerStates, setCheckboxWrongAnswerStates] = useState<{ [key: number]: boolean }>(() => {
    const initialCheckboxState = {};
    for (let i = 1; i <= 9; i++) {
      initialCheckboxState[i] = false; // Начальное состояние для чекбоксов "неправильных ответов"
    }
    return initialCheckboxState;
  });

  const handleWrongAnswerCheckboxChange = (id: number) => {
    setCheckboxWrongAnswerStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };
      if (onCheckboxWrongAnswerStateChange) {
        onCheckboxWrongAnswerStateChange(newState);
      }
      return newState;
    });
  };

  console.log('initialValues', initialValues)

  return (
    <div className={'pharmacyContainer'}>
      <div className={'pharmacy-title'}>
        <span>ФОРМА РЕЦЕПТУРНОГО БЛАНКА N 148-1/У-88</span>
      </div>
      <div className={'pharmacy-content-block'}>
        <div>
          <div className={'pharmacy-block'}>
            <span>Министерство здравоохранения Российской Федерации</span>
          </div>
          <div className={'pharmacy-block'}>
            <span>Наименование (штамп) медицинской организации</span>
          </div>
          <div className={'pharmacy-block'}>
            <span>
              Наименование (штамп) индивидуального предпринимателя (указать адрес, номер и дату лицензии, наименование органа государственной власти, выдавшего лицензию)
            </span>
          </div>
        </div>
        <div className={'pharmacy-block'}>
          <span>
            Код формы по ОКУД 3108805 Медицинская документация Форма N 148-1/у-88 Утверждена приказом Министерства здравоохранения Российской Федерации от 24 ноября 2021 г. N 1094н
          </span>
        </div>
        <span style={{ width: '100%', fontSize: 18 }}>-------------------------------------------------------------------------------------</span>
      </div>
      <div className={'pharmacy-input-block'}>
        <span>Серия</span>
        <GfgInput
          id={1}
          onChange={handleInputChange}
          inputCount={4}
          value={value}
          disabled={isReadOnly}
        />
        <span>N</span>
        <GfgInput
          id={2}
          onChange={handleInputChange}
          inputCount={5}
          value={number}
          disabled={isReadOnly}
        />
        {isShow && (
          <input
            id="checkbox-2"
            type="checkbox"
            checked={checkboxStates[2] || false}
            onChange={() => handleCheckboxChange(2)}
          />
        )}
        {isShowWrongAnswerCheckbox && (
          <input
            style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
            id="checkbox-1-1"
            type="checkbox"
            checked={checkboxWrongAnswerStates[2] || false}
            onChange={() => handleWrongAnswerCheckboxChange(2)}
          />
        )}
      </div>
      <div className={'pharmacy-date-block'}>
        <span>РЕЦЕПТ</span>
        <div>
          <span>"</span>
          <input
            id="date-input"
            className={'date-input day'}
            onChange={(event) => {
              setDate(event.target.value);
              handleInputChange(3, event.target.value);
            }}
            type="text"
            value={date}
            disabled={isReadOnly}
          />
          <span>"</span>
          <input
            id="month-input"
            className={'date-input month'}
            onChange={(event) => {
              setMonth(event.target.value);
              handleInputChange(4, event.target.value);
            }}
            type="text"
            value={month}
            disabled={isReadOnly}
          />
          <span>20</span>
          <input
            id="year-input"
            className={'date-input year'}
            onChange={(event) => {
              setYear(event.target.value);
              handleInputChange(5, event.target.value);
            }}
            type="text"
            value={year}
            disabled={isReadOnly}
          />
          <span>г.</span>
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-2"
              type="checkbox"
              checked={checkboxWrongAnswerStates[5] || false}
              onChange={() => handleWrongAnswerCheckboxChange(5)}
            />
          )}
          {isShow && (
            <input
              id="checkbox-5"
              type="checkbox"
              checked={checkboxStates[5] || false}
              onChange={() => handleCheckboxChange(5)}
            />
          )}
        </div>
      </div>

      <div className={'date-input-description'}>
        <span>(дата оформления рецепта)</span>
      </div>

      <div className={'type-block'}>
        <span style={{ textAlign: 'center' }}>(взрослый, детский - нужное подчеркнуть)</span>
      </div>

      <div className={'fio-block-patient'}>
        <span>Фамилия, инициалы имени и отчества (последнее - при наличии) пациента</span>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <input
            id="fio-patient"
            className={'date-born'}
            onChange={(event) => {
              setPatientFIO(event.target.value);
              handleInputChange(6, event.target.value);
            }}
            value={patientFIO}
            type={'text'}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-6"
              type="checkbox"
              checked={checkboxStates[6] || false}
              onChange={() => handleCheckboxChange(6)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-3"
              type="checkbox"
              checked={checkboxWrongAnswerStates[6] || false}
              onChange={() => handleWrongAnswerCheckboxChange(6)}
            />
          )}
        </div>
      </div>
      <div className={'date-born-block'}>
        <span style={{ whiteSpace: 'pre' }}>Дата рождения</span>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <input
            id="date-birth-input"
            className={'date-born'}
            onChange={(event) => {
              setDateBirth(event.target.value);
              handleInputChange(7, event.target.value);
            }}
            value={dateBirth}
            type={'text'}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-6"
              type="checkbox"
              checked={checkboxStates[7] || false}
              onChange={() => handleCheckboxChange(7)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-3"
              type="checkbox"
              checked={checkboxWrongAnswerStates[7] || false}
              onChange={() => handleWrongAnswerCheckboxChange(7)}
            />
          )}
        </div>
      </div>

      <div>
        <span>Адрес места жительства или N медицинской карты амбулаторного пациента, получающего медицинскую помощь в амбулаторных условиях</span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            id="address-input"
            className={'date-born'}
            onChange={(event) => {
              setAddress(event.target.value);
              handleInputChange(8, event.target.value);
            }}
            value={address}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-7"
              type="checkbox"
              checked={checkboxStates[8] || false}
              onChange={() => handleCheckboxChange(8)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-4"
              type="checkbox"
              checked={checkboxWrongAnswerStates[8] || false}
              onChange={() => handleWrongAnswerCheckboxChange(8)}
            />
          )}
        </div>
      </div>

      <div className={'fio-block-doctor'}>
        <span>Фамилия, инициалы имени и отчества (последнее - при наличии) лечащего врача (фельдшера, акушерки)</span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            id="fio-input"
            className={'date-born'}
            onChange={(event) => {
              setFio(event.target.value);
              handleInputChange(9, event.target.value);
            }}
            value={fio}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-8"
              type="checkbox"
              checked={checkboxStates[9] || false}
              onChange={() => handleCheckboxChange(9)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-5"
              type="checkbox"
              checked={checkboxWrongAnswerStates[9] || false}
              onChange={() => handleWrongAnswerCheckboxChange(9)}
            />
          )}
        </div>
      </div>
      <div className={'money-block'}>
        <span>Руб.</span>
        <span>Коп.</span>
        <span>Rp.</span>
      </div>
      <div className={'dotted-block'}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            id="dotted-input-1"
            className={'dottedSmall'}
            type="text"
            onChange={(event) => handleInputChange(10, event.target.value)}
            value={dotted1}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-9"
              type="checkbox"
              checked={checkboxStates[10] || false}
              onChange={() => handleCheckboxChange(10)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-6"
              type="checkbox"
              checked={checkboxWrongAnswerStates[10] || false}
              onChange={() => handleWrongAnswerCheckboxChange(10)}
            />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            id="dotted-input-2"
            className={'dottedSmall'}
            type="text"
            onChange={(event) => handleInputChange(11, event.target.value)}
            value={dotted2}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-10"
              type="checkbox"
              checked={checkboxStates[11] || false}
              onChange={() => handleCheckboxChange(11)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-7"
              type="checkbox"
              checked={checkboxWrongAnswerStates[11] || false}
              onChange={() => handleWrongAnswerCheckboxChange(11)}
            />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            id="dotted-input-3"
            className={'dottedSmall'}
            type="text"
            onChange={(event) => handleInputChange(12, event.target.value)}
            value={dotted3}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-11"
              type="checkbox"
              checked={checkboxStates[12] || false}
              onChange={() => handleCheckboxChange(12)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-8"
              type="checkbox"
              checked={checkboxWrongAnswerStates[12] || false}
              onChange={() => handleWrongAnswerCheckboxChange(12)}
            />
          )}</div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            id="dotted-input-4"
            className={'dottedLong'}
            type="text"
            onChange={(event) => handleInputChange(13, event.target.value)}
            value={dotted4}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-12"
              type="checkbox"
              checked={checkboxStates[13] || false}
              onChange={() => handleCheckboxChange(13)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25 }}
              id="checkbox-1-9"
              type="checkbox"
              checked={checkboxWrongAnswerStates[13] || false}
              onChange={() => handleWrongAnswerCheckboxChange(13)}
            />
          )}
        </div>
      </div>
      <div className={'caption-block'}>
        <div className={'caption-block-text'}>
          <span>Подпись и печать лечащего врача</span>
          <span>(подпись фельдшера, акушерки)</span>
        </div>
        <div>
          <span>М.П.</span>
        </div>
      </div>

      <div className={'valid-block'}>
        <span>Рецепт действителен в течение 15 дней</span>
      </div>
    </div>
  );
}

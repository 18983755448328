import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Man2(props) {

  const { nodes, materials, animations } = useGLTF('patient/youngmale_30072024.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])


  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.94, 0]} rotation={[0.11, 0, 0]}>
        <group position={[0, 0.12, 0]}>
          <group position={[0, 0.13, 0]} rotation={[-0.2, 0, 0]}>
            <group position={[0, 0.16, 0]} rotation={[0.09, 0, 0]}>
              <group position={[0, 0.17, 0]} rotation={[0.35, 0, 0]}>
                <group position={[0, 0.06, 0]} rotation={[0.05, 0, 0]}>
                  <group position={[0, 0.04, 0]} rotation={[-0.42, 0, 0]}>
                    <group rotation={[0.01, 0, 0]}>
                      <group position={[0, 0.12, 0.12]} rotation={[2.66, 0, 0]}>
                        <group position={[0, 0.04, 0]} rotation={[0.21, 0, 0]}>
                          <group position={[0, 0.01, 0]} rotation={[0.6, 0, 0]}>
                            <group position={[0, 0.01, 0]} rotation={[0.93, 0, 0]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.04, 0.12]} rotation={[-1.56, 0.13, -1.35]} />
                      <group position={[0, 0.03, 0.12]} rotation={[-1.43, -0.03, -1.25]} />
                      <group position={[0, 0, 0.05]} rotation={[1.67, 0, 0]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.84, 0, 0]} />
                      </group>
                      <group position={[0.07, 0.08, 0.02]} rotation={[-0.12, -0.18, -0.23]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.34, -0.1, -0.13]}>
                          <group position={[0, 0.03, 0]} rotation={[-1.9, -0.34, -0.15]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.45, -0.74, 0.37]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.08, 0.02]} rotation={[-0.12, 0.18, 0.23]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.34, 0.1, 0.13]}>
                          <group position={[0, 0.03, 0]} rotation={[-1.9, 0.34, 0.15]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.45, 0.74, -0.37]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.04, 0.12]} rotation={[-1.56, -0.13, 1.35]} />
                      <group position={[0, 0.03, 0.12]} rotation={[-1.43, 0.03, 1.25]} />
                      <group position={[0.06, 0.13, 0.09]} rotation={[-0.99, 0.96, 1.99]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.61, 0.73, 0.1]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.14, 0.44, -0.14]} />
                        </group>
                      </group>
                      <group position={[0.05, 0.11, 0.1]} rotation={[-0.94, 1.11, 1.84]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.49, 0.77, 0.04]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.34, 0.36, -0.48]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.06, 0.5, -0.61]}>
                              <group position={[0, 0.01, 0]} rotation={[0.87, 0.06, -2.7]}>
                                <group position={[0, 0.01, 0]} rotation={[0.35, 0.25, 0.45]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.3, 0.17, 0.19]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.06, 0.13, 0.09]} rotation={[-0.99, -0.96, -1.99]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.61, -0.73, -0.1]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.14, -0.44, 0.14]} />
                        </group>
                      </group>
                      <group position={[-0.05, 0.11, 0.1]} rotation={[-0.94, -1.11, -1.84]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.49, -0.77, -0.04]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.34, -0.36, 0.48]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.06, -0.5, 0.61]}>
                              <group position={[0, 0.01, 0]} rotation={[0.87, -0.06, 2.7]}>
                                <group position={[0, 0.01, 0]} rotation={[0.35, -0.25, -0.45]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.3, -0.17, -0.19]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.02, 0.19, 0.11]} rotation={[-2.12, 1.46, -1.25]}>
                        <group position={[-0.02, 0, 0.03]} rotation={[-0.05, -0.27, -0.08]}>
                          <group position={[-0.02, 0, 0.02]} rotation={[0.11, -0.57, 0.04]}>
                            <group position={[-0.02, 0.04, 0.03]} rotation={[0.49, -0.61, 0.44]}>
                              <group position={[0, 0.04, 0]} rotation={[-0.2, -0.26, 0.21]}>
                                <group position={[0, 0.04, 0]} rotation={[-0.2, -0.66, 0.56]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.92, -0.02, 1.3]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.58, -0.22, -0.61]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.3, 0.52, 0.25]}>
                                        <group position={[0, 0.06, 0]} rotation={[0.89, 0.56, 0.23]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.89, 0.74, 2.23]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.35, 0.92, -0.33]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.02, 0.19, 0.11]} rotation={[-2.12, -1.46, 1.25]}>
                        <group position={[0.02, 0, 0.03]} rotation={[-0.05, 0.27, 0.08]}>
                          <group position={[0.02, 0, 0.02]} rotation={[0.11, 0.57, -0.04]}>
                            <group position={[0.02, 0.04, 0.03]} rotation={[0.49, 0.61, -0.44]}>
                              <group position={[0, 0.04, 0]} rotation={[-0.2, 0.26, -0.21]}>
                                <group position={[0, 0.04, 0]} rotation={[-0.2, 0.66, -0.56]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.92, 0.02, -1.3]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.58, 0.22, 0.61]}>
                                      <group position={[0, 0.04, 0]} rotation={[0.3, -0.52, -0.25]}>
                                        <group position={[0, 0.06, 0]} rotation={[0.89, -0.56, -0.23]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.89, -0.74, -2.23]}>
                                            <group position={[0, 0.03, 0]} rotation={[-0.35, -0.92, 0.33]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.07, 0.11, 0.06]} rotation={[3.02, -1.05, 0.91]}>
                        <group position={[0, 0.05, 0]} rotation={[2.93, -0.75, 1.53]}>
                          <group position={[0, 0.04, 0]} rotation={[2.89, 0.98, 1.24]} />
                        </group>
                      </group>
                      <group position={[-0.07, 0.11, 0.06]} rotation={[3.02, 1.05, -0.91]}>
                        <group position={[0, 0.05, 0]} rotation={[2.93, 0.75, -1.53]}>
                          <group position={[0, 0.04, 0]} rotation={[2.89, -0.98, -1.24]} />
                        </group>
                      </group>
                      <group position={[0, 0.04, 0.1]} rotation={[-1.34, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.19, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.03, 0.15, 0.05]} rotation={[-1.56, -0.01, -1.26]}>
                <group position={[0.01, 0.18, -0.03]} rotation={[1.47, 1.33, -2.39]}>
                  <group position={[0, 0.25, 0]} rotation={[0.85, 0.18, 0.05]}>
                    <group position={[0, 0.31, 0]} rotation={[0.01, -0.01, -0.24]}>
                      <group position={[-0.01, 0.03, 0.03]} rotation={[3.11, 1.46, -2.97]}>
                        <group position={[0, 0.08, 0]} rotation={[0.05, -0.22, 0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.23, -0.17, -0.04]} />
                        </group>
                        <group position={[-0.02, -0.01, 0.02]} rotation={[-1.3, 0.96, 1.5]}>
                          <group position={[0, 0.04, 0]} rotation={[0.17, -0.1, 0.1]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, 0.01]} rotation={[2.67, 1.52, -2.72]}>
                        <group position={[0, 0.07, 0]} rotation={[0.13, -0.25, 0.01]}>
                          <group position={[0, 0.04, 0]} rotation={[0.14, -0.29, 0]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, -0.01]} rotation={[0.36, 1.51, -0.53]}>
                        <group position={[0, 0.07, 0]} rotation={[0.08, -0.33, -0.08]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, -0.13, -0.02]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, -0.02]} rotation={[0.22, 1.44, -0.48]}>
                        <group position={[0, 0.06, 0]} rotation={[0.07, -0.51, -0.1]}>
                          <group position={[0, 0.03, 0]} rotation={[0.2, -0.06, -0.07]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.03, 0.15, 0.05]} rotation={[-1.56, 0.01, 1.26]}>
                <group position={[-0.01, 0.18, -0.03]} rotation={[1.47, -1.33, 2.39]}>
                  <group position={[0, 0.25, 0]} rotation={[0.85, -0.18, -0.05]}>
                    <group position={[0, 0.31, 0]} rotation={[0.01, 0.01, 0.24]}>
                      <group position={[0.01, 0.03, 0.03]} rotation={[3.11, -1.46, 2.97]}>
                        <group position={[0, 0.08, 0]} rotation={[0.05, 0.22, -0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.23, 0.17, 0.04]} />
                        </group>
                        <group position={[0.02, -0.01, 0.02]} rotation={[-1.3, -0.96, -1.5]}>
                          <group position={[0, 0.04, 0]} rotation={[0.17, 0.1, -0.1]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, 0.01]} rotation={[2.67, -1.52, 2.72]}>
                        <group position={[0, 0.07, 0]} rotation={[0.13, 0.25, -0.01]}>
                          <group position={[0, 0.04, 0]} rotation={[0.14, 0.29, 0]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, -0.01]} rotation={[0.36, -1.51, 0.53]}>
                        <group position={[0, 0.07, 0]} rotation={[0.08, 0.33, 0.08]}>
                          <group position={[0, 0.04, 0]} rotation={[0.16, 0.13, 0.02]} />
                        </group>
                      </group>
                      <group position={[0, 0.03, -0.02]} rotation={[0.22, -1.44, 0.48]}>
                        <group position={[0, 0.06, 0]} rotation={[0.07, 0.51, 0.1]}>
                          <group position={[0, 0.03, 0]} rotation={[0.2, 0.06, 0.07]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.12, -0.04, 0.01]} rotation={[2.91, 0, -0.11]}>
          <group position={[0, 0.4, 0]} rotation={[0.24, 0.02, -0.02]}>
            <group position={[0, 0.39, 0]} rotation={[-1.06, -0.11, 0.06]} />
          </group>
        </group>
        <group position={[-0.12, -0.04, 0.01]} rotation={[2.91, 0, 0.11]}>
          <group position={[0, 0.4, 0]} rotation={[0.24, -0.02, 0.02]}>
            <group position={[0, 0.39, 0]} rotation={[-1.06, 0.11, -0.06]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.cloth_retopology.geometry}
          material={materials.FABRIC_1_FRONT_164566}
          skeleton={nodes.cloth_retopology.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Plane.geometry}
          material={materials['Material.001']}
          skeleton={nodes.Plane.skeleton}
        />
        <skinnedMesh
          geometry={nodes.young_male_1.geometry}
          material={materials.Eyeblue}
          skeleton={nodes.young_male_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.young_male_2.geometry}
          material={materials.bodyMaterial}
          skeleton={nodes.young_male_2.skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes.young_male_3.geometry}
          material={materials.Eye_glass}
          skeleton={nodes.young_male_3.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.young_male001.geometry}
          material={materials.young_asian_male}
          skeleton={nodes.young_male001.skeleton}
        />
      </group>
    </group>
  )
}


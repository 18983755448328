import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText
} from '@material-ui/core'
import React from 'react'
import Slot from '../../../../common/Slots/Slot'
import TableSelectResearch, { LaboratoryRowData } from './TableSelectResearch'

function createData(
  name: string,
  unit: string,
  reference: string,
  value?: number
): LaboratoryRowData {
  return { name, unit, reference, value }
}

const rows = [
  createData('Гемоглобин', 'г./с.кв.', '4.0', undefined),
  createData('Эритроциты', 'г./с.кв.', '4.3', undefined),
  createData('Лейкоциты', 'г./с.кв.', '6.0', 4.4),
  createData('Гемоглобин', 'г./с.кв.', '4.0', undefined),
  createData('Эритроциты', 'г./с.кв.', '4.3', undefined),
  createData('Лейкоциты', 'г./с.кв.', '6.0', undefined),
  createData('Гемоглобин', 'г./с.кв.', '4.0', undefined),
  createData('Эритроциты', 'г./с.кв.', '4.3', undefined),
  createData('Лейкоциты', 'г./с.кв.', '6.0', undefined)
]

type Props = {
  open: boolean
  title?: string
  content?: string
  onClose?: () => void
  onSubmit?: () => void
}

export default function LabDialog({
  open,
  title,
  content,
  onClose,
  onSubmit
}: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Slot title={title} notPadded>
        <TableSelectResearch rows={rows} />
        {content && <DialogContentText>{content}</DialogContentText>}
        <DialogActions>
          <Button onClick={onClose} color='default'>
            Отмена
          </Button>
          <Button onClick={onSubmit} color='primary'>
            Готово
          </Button>
        </DialogActions>
      </Slot>
    </Dialog>
  )
}

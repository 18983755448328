import React from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core'
import CognitiveBlockByType from './CognitiveBlockByType'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioBtnWrap: {
      alignItems: 'flex-start',
      marginBottom: 10
    },
    radioBtn: {
      padding: 0,
      marginRight: 10
    }
  })
)

type AnswersBlockProps = {
  correct: boolean
  id: number
  text: string
  typePhoto: boolean
  typeSound: boolean
  typeText: boolean
  typeVideo: boolean
}
type Props = {
  answers: Array<AnswersBlockProps>
  selectedAnswer: (val: string) => void
}

const CognitiveAnswersBlock = (props: Props) => {
  const classes = useStyles()

  const [value, setValue] = React.useState<string | null>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
    props.selectedAnswer((event.target as HTMLInputElement).value)
  }

  return (
    <>
      <FormControl>
        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue='female'
          name='radio-buttons-group'
          value={value}
          onChange={handleChange}
        >
          {props?.answers ? (
            <>
              {props?.answers?.map((el, i) => (
                <FormControlLabel
                  value={String(el.id)}
                  control={
                    <Radio className={classes.radioBtn} color='primary' />
                  }
                  className={classes.radioBtnWrap}
                  label={
                    <CognitiveBlockByType
                      typePhoto={el.typePhoto}
                      typeSound={el.typeSound}
                      typeText={el.typeText}
                      typeVideo={el.typeVideo}
                      text={el.text}
                    />
                    // <b>{el.text}</b>
                  }
                  key={i}
                />
              ))}
            </>
          ) : null}
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default CognitiveAnswersBlock

//@ts-ignore
import { Modal, TextInput, Select, SelectItem, TextArea, ToastNotification, FileUploader, Button } from '@carbon/react';
import React, { useState, useEffect } from 'react';
import chatApi from '../../api/chatApi';
import { setLibrariesReference, uploadMediaLibraries } from '../../api/chatApi/chatApi';
import './index.css'

const MainModal = ({open, title, setOpen, item, setReRequest}) => {
    
    const [name, setName] = useState('')
    const [type, setType] = useState('0')
    const [value, setValue] = useState('')
    const [themes, setThemes] = useState([])
    const [theme, setTheme] = useState('0')
    const [id, setId] = useState(null)
    const [id_p, setIdP] = useState(null)

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const getThemes = async() => {
        await chatApi.getThemesGroup()
        .then(res => setThemes(res.result))
        .catch(err => console.log(err))
    }

    useEffect(() => {
        setIdP(item.p_id)
        setId(item.id)
        getThemes()
        if(title === 'Редактирование') {
            const theme = themes.filter((el: any) => el.theme === item.theme)
            setTheme(String(theme[0].group))
            setValue(item.text)
            setName(item.name)
            setType(item.type)
        }
        if(title === 'Создать раздел верхнего уровня') {
            setType(item.type)
        }
        if(title === 'Создать') {
            setValue('')
            setName('')
            setType('0')
            setTheme('0')   
        }
    }, [item, title]);


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };

    const uploadFile = async (e) => {
        setError(false)
        setSuccess(false)
        const type = e.target.files[0].name.split('.')[1]        
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        //@ts-ignore
        const b64 = base64.split(',')[1]
        await uploadMediaLibraries(type, b64)
        .then(res => {
            setError(false)
            setSuccess(true)
            setValue(res.data.url_path)})
        .catch(err => {
            setError(true)
            setSuccess(false)
            console.log(err)}
            )
    };


    const submit = async() => {
        setError(false)
        if(title === 'Создать') {
            await setLibrariesReference(true, Number(theme), String(name),  Number(id),  String(value), Number(type))
            .then(res => {
                setReRequest(true)
                setOpen(false)})
            .catch(err => {
                setError(true)
                console.log(err)
                setOpen(true)})
        }
        else {
            await setLibrariesReference(true, Number(theme), String(name),  Number(id_p),  String(value), Number(type), Number(id),)
            .then(res => {
                setReRequest(true)
                setOpen(false)})
            .catch(err => {
                setError(true)
                console.log(err)
                setOpen(true)})
        }
        setValue('')
        setName('')
        setType('0')
        setTheme('0')
    }

    const disebled = () => {
        if(theme === '0' || type === '0') {
            return true
        }
        else return false
    }
    
    return ( 
        <Modal
            open={open}
            modalHeading={title}
            primaryButtonText="Сохранить"
            secondaryButtonText="Отмена"
            primaryButtonDisabled={disebled()}
            onRequestClose={() => setOpen(false)}
            onRequestSubmit={() => submit()}>
                      {
				error &&
				<div style={{position: 'fixed', right: 0, zIndex: 1000}}>
					<ToastNotification
						kind="warning"
						hideCloseButton={false}
						lowContrast={false}
						timeout={7000}
						title="Ошибка"
					/>
				</div>
			}

            {
				success &&
				<div style={{position: 'fixed', right: 0, zIndex: 1000}}>
					<ToastNotification
                        kind="success"
                        hideCloseButton={false}
                        lowContrast={false}
                        title="Загружено"
                        timeout={7000}
                    />
				</div>
			}
            <TextInput
                data-modal-primary-focus
                id="text-input-1"
                labelText="Наименование"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ marginBottom: '1rem' }}
            />
            {item.type === 2 || type === '2'?
            <TextArea
                cols={60}
                id="test2"
                labelText="Значение"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                rows={4}
                style={{ marginBottom: '1rem' }}
            />
            :
            <div style={{
                display: 'flex',
                alignItems: 'center'}}>
                <TextInput
                    data-modal-primary-focus
                    id="text-input-2"
                    labelText="Значение"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    style={{ marginBottom: '1rem' }}
                />
                <FileUploader
                    onChange={(e) => uploadFile(e)}
                    buttonKind="tertiary"
                    style={{marginLeft: 30, marginBottom: 8}}
                    buttonLabel="Загрузить"
                    filenameStatus="edit"
                    />
            </div>
            
            }
            <div style={{marginBottom: 20}}>
                <Select id="select-1" 
                    value={String(type)} 
                    onChange={(e) => setType(e.target.value)} 
                    labelText="Тип">
                    {title === 'Создать раздел верхнего уровня' ?
                    <SelectItem value="1" text="Раздел" />
                    :
                    <>
                    <SelectItem disabled value="0" text="Выберите тип" />
                    <SelectItem value="1" text="Раздел" />
                    <SelectItem value="2" text="Текст" />
                    <SelectItem value="3" text="Файл" />
                    <SelectItem value="4" text="Фото" />
                    <SelectItem value="5" text="Аудио" />
                    <SelectItem value="6" text="Видео" />
                    </>
                    }
                </Select>
            </div>
            
            {themes &&
            <div style={{marginBottom: 10}}>
                <Select id="select-2"
                defaultValue="0" 
                value={theme}
                labelText="Тема"
                onChange={(e) => setTheme(e.target.value)}>
                    <SelectItem value="0" text="Выберите тему"  disabled/>
                    {themes.map((el: any) => {
                        return(
                        <SelectItem value={el.group[0]} text={el.theme} />
                        )
                    })}
                </Select>
            </div>
            
            }
        </Modal>
     );
}
 
export default MainModal;
import { createStyles, makeStyles } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { FunctionComponent } from 'react'

type Props = {
  style?: CSSProperties
}

const NewTaskContentLayout: FunctionComponent<Props> = props => {
  const styles = useStyles()
  return (
    <div className={styles.root} style={props.style}>
      <div className={styles.content}>{props.children}</div>
    </div>
  )
}

export default NewTaskContentLayout

const useStyles = makeStyles(theme => {
  return createStyles({
    root: {
      display: 'flex',
      minHeight: '100%',
      width: '100%',
      alignItems: 'start',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    content: {
      minHeight: '100%',
      width: '80%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      margin: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  })
})

import React from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import CustomButton from '../../../components/common/CustomButton'
import CustomHeader1 from '../../../components/common/CustomHeader1'
import SlotContainerMasonry from '../../../components/common/Slots/SlotContainerMasonry'
import SlotPlaceholder from '../../../components/common/Slots/SlotPlaceholder'
import MainLayout from '../../../components/Layout/MainLayout'
import TreatmentDialog from '../../../components/PageBlocks/Treatment/TreatmentDialog'
import TreatmentSetSlot from '../../../components/PageBlocks/Treatment/TreatmentSetSlot'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

const TITLES_MEDICAL = [
  { title: 'Медикаментозное лечение', color: 'info' },
  { title: 'Питание' },
  { title: 'Режим' }
]
const TITLES_FOOD = [
  { title: 'Питание', color: 'info' },
  { title: 'Питание' },
  { title: 'Режим' }
]
const TITLES_BEHAVIOR = [
  { title: 'Режим', color: 'info' },
  { title: 'Медикаментозное лечение' },
  { title: 'Питание' }
]

const TREATMENT_MEDICAL = [
  {
    title: 'Ацетилсалициловая кислота',
    text: 'таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 15 дней',
    item: null
  },
  {
    title: 'Аспирин',
    text:
      'Аспирин, таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 71 день',
    item: null
  }
]
const TREATMENT_FOOD = [
  {
    title: 'Мед',
    text: 'таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 15 дней',
    item: null
  },
  {
    title: 'Лимон',
    text: 'таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 71 день',
    item: null
  }
]
const TREATMEN_BEHAVIOR = [
  { title: 'Сон', text: '3 раза в день', item: null },
  { title: 'Свежий воздух', text: '2 раза в день', item: null }
]

export default function TreatmentAddPage() {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <TreatmentDialog open={true} title='Добавление лечения' />
      <CustomHeader1>Назначение лечения</CustomHeader1>
      <SlotContainerMasonry>
        <TreatmentSetSlot
          titles={TITLES_MEDICAL}
          treatment={TREATMENT_MEDICAL}
        />
        <TreatmentSetSlot titles={TITLES_FOOD} treatment={TREATMENT_FOOD} />
        <TreatmentSetSlot
          titles={TITLES_BEHAVIOR}
          treatment={TREATMEN_BEHAVIOR}
        />
        <SlotPlaceholder
          title='Добавить еще диагноз'
          onClick={() => alert('action!')}
        />
      </SlotContainerMasonry>
      <CustomButton>Вперед!</CustomButton>
    </MainLayout>
  )
}

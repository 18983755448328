import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'left',
    fontWeight: 400,
    fontSize: '100%'
  },
  buttonSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bodyTable: {
    textAlign: 'left',
    fontWeight: 400,
    background: '#f4f4f4',
    cursor: 'pointer',
    '&:hover': {
      background: '#e8e8e8',
    },
  },
});

const Role = props => {
  const classes = useRowStyles();
  return (
    <div style={{ margin: '25px 0px 30px' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead style={{ background: '#E7F0FE' }}>
            <TableRow>
              <TableCell style={{
                fontWeight: 600,
                fontSize: 14
              }}>
                Роли</TableCell>
              <TableCell align="right"
                style={{
                  fontWeight: 600,
                  fontSize: 14
                }}>Выбор ролей</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.roleUser.map((row, index) => (
              <TableRow
                onClick={() => props.toggleRole(row.toggle, row.role)}
                className={classes.bodyTable}
                key={index}
              >
                <TableCell component="th" scope="row">
                  {row.role}
                </TableCell>
                <TableCell>
                  <div className={classes.buttonSwitch}>
                    <Grid style={{ marginRight: 20 }}>Нет</Grid>
                    <FormControlLabel control={<Switch checked={row.toggle} color="primary" />} />
                    <Grid>Да</Grid>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Role;

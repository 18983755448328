import React, { useEffect, useState } from 'react';
import MainHeader from '../../components/MainHeader/MainHeader'
import MainFooter from '../../components/MainFooter/MainFooter'
import { makeStyles, createStyles } from '@material-ui/core'
//@ts-ignore
import jwt from 'jsonwebtoken';
//@ts-ignore
import { ToastNotification, Select, SelectItem, Button, TextInput, Checkbox, FileUploader } from '@carbon/react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import SearchBar from 'material-ui-search-bar';
import { allUsers, checkFile, downloadFiles, getDownloads, getListDownloads } from '../../api/chatApi/chatApi';
import chatApi from '../../api/chatApi';
import { TablePaginationActions } from '../adminPanel/CreateRole/options/TablePagination';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      margin: '0 24px',
      marginTop: 70,
      fontFamily: 'Helvetica Neue'
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 24,
    },
    block: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    block1: {
      maxWidth: 400
    },
    block2: {
      marginBottom: 20
    },
    titleTable: {
      textAlign: 'center',
      fontWeight: 600,
    },
    buttonSwitch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cellInput: {
      width: '100%',
      background: 'transparent',
      border: 'none',
      outline: 'none',
    },
    error: {
      display: 'flex',
      marginTop: 25,
      justifyContent: 'center',
      fontSize: 17,
    },
    check: {
      margin: '10px 0'
    }
  })
)


const LoadHandbooks = () => {

  const style = useStyles()

  const [list, setList] = useState<any>([])
  const [id, setId] = useState<string>('')
  const [data, setData] = useState<any>([])
  const [searched, setSearched] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [toggle, setToggle] = useState(false)
  const [dataHandbooks, setDataHandbooks] = useState([])
  const [check, setCheck] = useState(false)
  const [check1, setCheck1] = useState(false)
  const [textNew, setTextNew] = useState('')
  const [file, setFile] = useState('')
  const [com, setCom] = useState('')
  const [name, setName] = useState('')
  const [value, setValue] = useState('')
  const [ck, setCk] = useState(false)
  const [org, setOrg] = useState<any>([])
  const [orgData, setOrgData] = useState<any>(null)
  const [comma, setComma] = useState<any>(null)

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [title, setTitle] = useState('')



  const roleDecode = jwt.decode(localStorage.getItem('role'));
  const role = roleDecode.auth.split(',');


  const getTable = async () => {

    if (id !== 'placeholder') {
      await getDownloads(Number(id))
        .then(res => {
          setToggle(true)
          setData(res.data.downloads)
        })
        .catch(err => console.log(err))
    }
  }

  const requestSearch = async searchedVal => {
    if (!searchedVal) {
      return getTable();
    }
    await getDownloads(Number(id))
      .then(res => {
        const filteredRows = res.data.downloads.filter(row => {
          return row.name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setData(filteredRows);
      })
      .catch(err => console.log(err));
  };


  const getList = async () => {
    setCk(false)
    await chatApi.getUserTrainers()
      .then(res => {
        allUsers(res.result.login)
          .then(async (res) => {
            const resultOrg = await chatApi.getAllOrgProfile()

            setOrg(resultOrg.result)
            const org = resultOrg.result

            const result = org.map(el => el.id)

            if (result.lenght === 0) {
              getListDownloads()
                .then(r => setList(r.data.list_downloads))
            }
            else {
              getListDownloads(result)
                .then(r => setList(r.data.list_downloads))
            }

          })
          .catch(err => console.log(err))

      })
      .catch(err => console.log(err))

  }

  useEffect(() => {
    getList()
  }, [ck]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const uploadFile = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    //@ts-ignore
    const b64 = base64.split(',')[1]
    setFile(b64)
    await checkFile(b64)
      .then(res => {
        const arr1 = res.data.variants[0]
        const arr2 = res.data.variants[1]
        if (arr1.fields_name.length > arr2.fields_name.length) {
          setComma(0)
        }
        if (arr1.fields_name.length < arr2.fields_name.length) {
          setComma(1)
        }
        setDataHandbooks(res.data.variants)
      })
      .catch(err => setDataHandbooks([]))
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const downloadFile = async (toggle) => {
    setSuccess(false)
    setError(false)
    const type = () => {
      if (check1) {
        return 1
      }
      return 0
    }
    console.log(comma);

    if (check) {
      await downloadFiles(toggle, file, Number(orgData), type(), Number(comma) === 0 ? dataHandbooks[0].comma : dataHandbooks[1].comma, com, name, value, textNew)
        .then(res => {
          setSuccess(true)
          setTitle('Справочник загружен')
          setCk(true)
        })
        .catch(err => {
          setError(true)
          console.log(err)
        })
    }
    else {
      await downloadFiles(toggle, file, Number(orgData), type(), Number(comma) === 0 ? ',' : ';', com, name, value, textNew, Number(id))
        .then(res => {
          if (toggle === true) {
            setTitle('Справочник удален')
          }
          if (toggle === false) {
            setTitle('Справочник загружен')
          }
          setSuccess(true)
          setCk(true)
        })
        .catch(err => {
          setError(true)
          console.log(err)
        })
    }
    setData(null)
  }



  const disabled = () => {
    if (check) {
      if (com.length !== 0 && value.length !== 0 && name.length !== 0 && textNew.length !== 0) {
        return false
      }
      return true
    }
    else {
      if (com.length !== 0 && value.length !== 0 && name.length > 0) {
        return false
      }
      return true
    }
  }
  console.log(comma);

  return (
    <>
      <MainHeader />
      {
        error &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={false}
            lowContrast={false}
            // subtitle={<span>Нет результатов</span>}
            timeout={10000}
            title="Ошибка"
          />
        </div>
      }

      {
        success &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="success"
            hideCloseButton={false}
            lowContrast={false}
            title={title}
            timeout={10000}
          />
        </div>
      }
      <div className={style.wrapper}>
        <div className={style.title}>Загрузка справочников</div>
        <div className={style.block}>
          <div className={style.block1} style={{ marginRight: 200 }}>
            <div style={{ margin: '20px 0' }}>
              <Select
                defaultValue=""
                id="select-1"
                labelText="Список"
                onChange={(e) => {
                  setId(e.target.value)
                }}
              >
                <SelectItem
                  disabled
                  text="Выберите вариант"
                  value=""
                />
                {list.length > 0 &&
                  list.map(el => {
                    return (
                      <SelectItem
                        key={el.id}
                        text={el.name}
                        value={el.id}
                      />
                    )
                  })
                }
              </Select>
            </div>

            <div style={{ margin: '20px 0' }}>
              <Button disabled={id === '' ? true : false} onClick={getTable} style={{ marginRight: 10 }}>Выбрать</Button>
              <Button disabled={check || id.length < 1 ? true : false} kind='danger--tertiary' onClick={() => downloadFile(true)}>Удалить</Button>
            </div>
          </div>
          <div>
            {
              role.includes('ROLE_ADMIN_ORG') ||
                role.includes('ROLE_ADMIN') ?
                <div className={style.block1}>
                  <FileUploader
                    accept={[
                      '.csv'
                    ]}
                    onChange={(e) => uploadFile(e)}
                    buttonKind="primary"
                    buttonLabel="Загрузить справочник"
                    filenameStatus="edit"
                    iconDescription="Clear file"
                    labelTitle=""
                  />
                  {dataHandbooks.length > 0 ?
                    <div style={{ marginBottom: 50 }}>
                      <div className={style.check} >
                        <Checkbox labelText="Новый справочник" id="checked" checked={check} onChange={() => setCheck(!check)} />
                      </div>
                      <div>
                        <TextInput
                          onChange={(e) => setTextNew(e.target.value)}
                          // disabled={check ? false : true}
                          id="test"
                          labelText=""
                          placeholder="Введите текст"
                        />
                      </div>
                      <div className={style.check} >
                        <Checkbox labelText="Очистить перед загрузкой" id="checked1" checked={check1} onChange={() => setCheck1(!check1)} />
                      </div>
                      <div>
                        <div className={style.block2}>
                          <Select
                            defaultValue=""
                            id="select-1"
                            labelText="Организация"
                            // disabled={check ? false : true}
                            onChange={(e) => setOrgData(e.target.value)}
                          >
                            <SelectItem
                              disabled
                              text="Выберите вариант"
                              value=""
                            />
                            {org.map(el => {
                              return (
                                <SelectItem
                                  text={el.name}
                                  value={el.id}
                                />
                              )
                            })}
                          </Select>
                        </div>
                        <div className={style.block2}>
                          <Select
                            defaultValue=""
                            id="select-4"
                            labelText="Разделитель"
                            onChange={(e) => {
                              setComma(e.target.value)
                            }}
                            value={comma}
                          >
                            <SelectItem
                              disabled
                              text="Выберите вариант"
                              value=""
                            />
                            {dataHandbooks.map((el, index) => {
                              return (
                                <SelectItem
                                  text={el.comma}
                                  value={index}
                                />
                              )
                            })}
                          </Select>
                        </div>
                        <div className={style.block2}>
                          <Select
                            defaultValue=""
                            id="select-1"
                            labelText="Название"
                            onChange={(e) => setName(e.target.value)}
                          >
                            <SelectItem
                              disabled
                              text="Выберите вариант"
                              value=""
                            />
                            {dataHandbooks[Number(comma)].fields_name.map(el => {
                              return (
                                <SelectItem
                                  text={el}
                                  value={el}
                                />
                              )
                            })}
                          </Select>
                        </div>
                        <div className={style.block2}>
                          <Select
                            defaultValue=""
                            id="select-1"
                            labelText="Значение"
                            onChange={(e) => setValue(e.target.value)}
                          >
                            <SelectItem
                              disabled
                              text="Выберите вариант"
                              value=""
                            />
                            {dataHandbooks[Number(comma)].fields_name.map(el => {
                              return (
                                <SelectItem
                                  text={el}
                                  value={el}
                                />
                              )
                            })}
                          </Select>
                        </div>
                        <div className={style.block2}>
                          <Select
                            defaultValue=""
                            id="select-1"
                            labelText="Комментарий"
                            onChange={(e) => setCom(e.target.value)}
                          >
                            <SelectItem
                              disabled
                              text="Выберите вариант"
                              value=""
                            />
                            {dataHandbooks[Number(comma)].fields_name.map(el => {
                              return (
                                <SelectItem
                                  text={el}
                                  value={el}
                                />
                              )
                            })}
                          </Select>
                        </div>
                      </div>
                      <div>
                        <Button disabled={disabled()} style={{ marginRight: 10 }} kind='tertiary' onClick={() => downloadFile(false)}>{check ? 'Загрузить' : 'Редактировать'}</Button>
                      </div>
                    </div>
                    : null}
                </div>
                : null}
          </div>
        </div>
        <div>
          {toggle ?
            <>
              <SearchBar value={searched} placeholder='Поиск' onChange={searchVal => requestSearch(searchVal)} />
              {data ?
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead style={{ background: '#e0e0e0' }}>
                      <TableRow>
                        <TableCell className={style.titleTable}>Название</TableCell>
                        <TableCell className={style.titleTable} align="right">
                          Значение
                        </TableCell>
                        <TableCell className={style.titleTable} align="right">
                          Комментарий
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map(
                        (row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center" component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {row.value}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {row.comment}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                    <TableFooter>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}

                      />
                    </TableFooter>
                  </Table>
                </TableContainer>
                : <div className={style.error}>Данных не найдено</div>}
            </>
            : null}
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default LoadHandbooks;
import React, { ReactElement } from 'react'
import SearchInput from '../../../common/SearchInput'
import Slot from '../../../common/Slots/Slot'

export default function InstrumentalSelectBlock(): ReactElement {
  return (
    <Slot title='Инструментальные исследования' color='primary'>
      <SearchInput
        placeholder='Введите название исследования'
        label='Инструментальные исследования'
      />
    </Slot>
  )
}

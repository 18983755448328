import { Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { ReactElement } from 'react'
import Slot from '../../../common/Slots/Slot'

const useStyles = makeStyles(theme => ({
  firstCell: {
    paddingLeft: (p: Props) => (p.padLevel ? theme.spacing(p.padLevel * 3) : 0)
  }
}))

interface Props {
  title?: string
  rows?: { name: string; val: number; units: string; norma: number }[]
  padLevel?: number
  rowsGorupTitle?: string
}

export default function SlotResearchTable({ title, rows }: Props) {
  return (
    <React.Fragment>
      {/* DESKTOP ONLY */}
      <Hidden xsDown>
        <Slot title={title}>
          <ResearchTable title={title} rows={rows} />
        </Slot>
      </Hidden>
      {/* MOBILE ONLY */}
      <Hidden smUp>
        <Slot title={title} notPadded>
          <ResearchTable title={title} rows={rows} />
        </Slot>
      </Hidden>
    </React.Fragment>
  )
}

function ResearchTable({ rows }: Props) {
  // const classes = useStyles();
  return (
    <Table size='small' aria-label='a dense table'>
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: 600 }}>Название</TableCell>
          <TableCell align='right'>Значение</TableCell>
          <TableCell align='right'>ед. изм</TableCell>
          <TableCell align='right'>Норма</TableCell>
        </TableRow>
      </TableHead>

      <RowBlock rows={rows} padLevel={0} />
      <RowsGroupHeader rowsGorupTitle='Общий Анализ крови' padLevel={0} />
      <RowBlock rows={rows} padLevel={1} />
      <RowBlock rows={rows} padLevel={0} />
    </Table>
  )
}

function RowsGroupHeader({ padLevel, rowsGorupTitle }: Props): ReactElement {
  const classes = useStyles({ padLevel } as Props)
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={4} className={classes.firstCell}>
          {rowsGorupTitle}
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

function RowBlock({ rows, padLevel }: Props) {
  const classes = useStyles({ padLevel } as Props)
  return (
    <TableBody>
      {rows &&
        rows.map(row => (
          <TableRow key={row.name}>
            <TableCell component='th' scope='row' className={classes.firstCell}>
              {row.name}
            </TableCell>
            <TableCell align='right'>{row.val}</TableCell>
            <TableCell align='right'>{row.units}</TableCell>
            <TableCell align='right'>{row.norma}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  )
}

import React, { FunctionComponent, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { TESTING_STAGE_TO_ROUTE } from '../constants/default'
import useCurrentTask from '../hooks/useCurrentTask'
import Login from '../pages/auth/LoginPage'

const RootRedirect: FunctionComponent = () => {
  const stage = useCurrentTask()

  const rootRoute = useMemo(() => {
    if (!stage?.value?.testingStage) return null
    return TESTING_STAGE_TO_ROUTE.get(stage.value.testingStage)
  }, [stage])

  return rootRoute ? <Redirect to={rootRoute} /> : <Login/>;
}

export default RootRedirect

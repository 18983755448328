import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Rating } from '@material-ui/lab'
import React, { useState } from 'react'
import chatApi from '../../api/chatApi'

import { pxToRemGutter } from '../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: pxToRemGutter(48, 0, 24)
    },
    text: {
      color: theme.palette.text.primary
    },
    textField: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  })
)

type Props = {
  open: boolean
  title?: string
  onSubmit: () => void
}
// AddNoteDialog
export default function RatingDialog({ open, title, onSubmit }: Props) {
  const classes = useStyles()
  const [grade, setGrade] = useState()
  // const [descriptionField, setDescriptionField] = useState<string>('');
  const [dislikeText, setDislikeText] = useState<string>('')
  const [likeText, setLikeText] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setGrade(newValue)
  }

  const sendReview = () => {
    chatApi.setTaskRating(Number(grade), dislikeText)
    onSubmit()
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{title ? title : 'Оценка шага'}</DialogTitle>
      <DialogContent>
        {/* <Rating name="simple-controlled" value={grade} onChange={handleChange} /> */}

        <TextField
          value={dislikeText}
          onChange={e => setDislikeText(e.target.value)}
          type='text'
          label='Что можно сделать лучше?'
          name='message'
          className={classes.textField}
          fullWidth
          variant='outlined'
        />
        <TextField
          value={likeText}
          onChange={e => setLikeText(e.target.value)}
          type='text'
          label='Что понравилось?'
          name='message'
          className={classes.textField}
          fullWidth
          variant='outlined'
        />
      </DialogContent>

      <DialogActions>
        {/* <Button onClick={onClose} color="primary">
          Нет
        </Button> */}
        <Button onClick={sendReview} color='primary' variant='text' autoFocus>
          Отправить оценку
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React, { useState } from 'react'
import useUrlQuery from '../../hooks/useUrlQuery'
import ChooseDifficultPage from './ChooseDifficultPage'
import ChooseCategoryPage from './ChooseCategoryPage'
import ChooseTaskPage from './ChooseTaskPage'
import ChooseAcademLevelPage from './ChooseAcademLevelPage'
import { useQuery } from 'react-query'
import chatApi from '../../api/chatApi'
import ChooseHardTaskPage from './ChooseHardTaskPage'
import MainHeader from '../../components/MainHeader/MainHeader'


enum NewChatStep {
  CHOOSE_UNIVERSITY_LEVEL,
  CHOOSE_DIFFICULT,
  CHOOSE_CATEGORY,
  CHOOSE_TASK,
  CHOOSE_HARD_TASK
}

export default function NewChatPage() {
  const { step, universityLevelId, categoryId, difficultId } = useWizardState()
  const [noAcademLevel, setNoAcademLevel] = React.useState<boolean>(false)
  const [userOrganizations, setUserOrganizations] = useState([])

  const userTrainers = useQuery(['userTrainers', step], async () => {
    const data = await chatApi.getUserTrainers()
    setNoAcademLevel(data.result.trainers[0].id === 2)
    if (data.result.organizations.length > 0) {
      let allUsersOrgArr = []
      data.result.organizations.forEach((el) => {
        allUsersOrgArr.push(el.name)
      })
      setUserOrganizations(allUsersOrgArr)
    }
    return data.result
  })


  return (
    <>
      <MainHeader showComponentHelpFilled={false} />
      {step === NewChatStep.CHOOSE_UNIVERSITY_LEVEL ? (
        <ChooseAcademLevelPage
          noAcademLevel={noAcademLevel}
          taskName={userTrainers?.data?.trainers?.[0]?.name}
          showComponentHelpFilled={false}
          userOrganizations={userOrganizations}
        />
      ) : null}
      {step === NewChatStep.CHOOSE_DIFFICULT ? (
        <ChooseDifficultPage
          universityLevelId={universityLevelId!}
          noAcademLevel={noAcademLevel}
          taskName={userTrainers?.data?.trainers?.[0]?.name}
          taskId={userTrainers?.data?.trainers?.[0]}
          userOrganizations={userOrganizations}
        />
      ) : null}
      {step === NewChatStep.CHOOSE_CATEGORY ? (
        <ChooseCategoryPage
          universityLevelId={universityLevelId!}
          difficultId={difficultId!}
          noAcademLevel={noAcademLevel}
          taskName={userTrainers?.data?.trainers?.[0]?.name}
          userOrganizations={userOrganizations}
        />
      ) : null}
      {step === NewChatStep.CHOOSE_TASK ? (
        <ChooseTaskPage
          universityLevelId={universityLevelId!}
          medicineId={categoryId!}
          difficultId={difficultId!}
          noAcademLevel={noAcademLevel}
          taskName={userTrainers?.data?.trainers?.[0]?.name}
          userOrganizations={userOrganizations}
        />
      ) : null}
      {step === NewChatStep.CHOOSE_HARD_TASK ? (
        <ChooseHardTaskPage
          universityLevelId={universityLevelId!}
          medicineId={categoryId!}
          difficultId={difficultId!}
          noAcademLevel={noAcademLevel}
          taskName={userTrainers?.data?.trainers?.[0]?.name}
          userOrganizations={userOrganizations}
        />
      ) : null}
    </>
  )
}

type NewTaskWizardState = {
  step: NewChatStep
  difficultId?: number
  categoryId?: number
  universityLevelId?: number
}

const useWizardState: () => NewTaskWizardState = () => {
  const urlQuery = useUrlQuery()

  const universityLevelId = urlQuery.has('universityLevelId')
    ? parseInt(urlQuery.get('universityLevelId')!)
    : undefined
  const difficultId = urlQuery.has('difficultId')
    ? parseInt(urlQuery.get('difficultId')!)
    : undefined
  const categoryId = urlQuery.has('categoryId')
    ? parseInt(urlQuery.get('categoryId')!)
    : undefined

  const step: NewChatStep = getStepByUrl(
    universityLevelId,
    difficultId,
    categoryId
  )

  return {
    step: step,
    universityLevelId: universityLevelId,
    difficultId: difficultId,
    categoryId: categoryId
  }
}

const getStepByUrl = (
  universityLevelId?: number,
  difficultId?: number,
  categoryId?: number
) => {
  if (universityLevelId && difficultId === 3 && 3)
    return NewChatStep.CHOOSE_HARD_TASK
  if (universityLevelId && difficultId !== 3 && categoryId)
    return NewChatStep.CHOOSE_TASK
  if (universityLevelId && difficultId) return NewChatStep.CHOOSE_CATEGORY
  if (universityLevelId) return NewChatStep.CHOOSE_DIFFICULT
  return NewChatStep.CHOOSE_UNIVERSITY_LEVEL
}

import React from 'react'
import {
  createStyles,
  FormControl,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField
} from '@material-ui/core'
import 'flatpickr/dist/l10n/ru.js';
// import Search from '@material-ui/icons/Search'
import {
  DatePicker,
  DatePickerInput,
  Search,
  Select,
  SelectItem
} from '@carbon/react';

const useStyles = makeStyles(theme =>
  createStyles({
    wrapperFilter: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      marginTop: 24
    },
    sorted: {
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.between('sm', 'md')]: {
        marginTop: 20
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      }
    },
    filter: {
      width: 200,
      marginLeft: 10,
      [theme.breakpoints.between('sm', 'md')]: {
        width: 150,
        marginLeft: 0,
        marginRight: 10
      },
      [theme.breakpoints.down('xs')]: {
        width: 150,
        marginLeft: 0,
        marginTop: 10
      }
    },
  })
)

const FilterChampionships = ({ filter, setFilter }) => {
  const handleDateChange = e => {
    setFilter({ ...filter, data: String(e.target.value.replace(/-/g, '.')) })
  };


  const style = useStyles()
  return (
    <div>
      <h2>Все чемпионаты</h2>
      <div className={style.wrapperFilter}>
        <div style={{ width: 800 }}>
          <Search
            placeholder='Поиск по имени и описанию'
            value={filter.query}
            onChange={e => setFilter({ ...filter, query: e.target.value })}
          />
        </div>
        <div className={style.sorted}>
          <div style={{ marginRight: 10 }}>
            <DatePicker datePickerType="single" dateFormat="d-m-Y" allowInput={false} locale='ru' >
              <DatePickerInput
                id="date-picker-calendar-id"
                labelText="Дата начала"
                locale='ru'
                onFocus={(e) => handleDateChange(e)}
              />
            </DatePicker>
          </div>
          <div className={style.filter}>
            <Select
              id="select-1"
              labelText='Cтатус'
              value={filter.sort}
              onChange={e => setFilter({ ...filter, sort: e.target.value })}
            >
              <SelectItem value="Все" text="Все" />
              <SelectItem value="Идут" text="Идут" />
              <SelectItem value="Зарегистрирован" text="Зарегистрирован" />
              <SelectItem value="Открытые" text="Открытые" />
              <SelectItem value="Закрытые" text="Закрытые" />
            </Select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterChampionships

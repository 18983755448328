import { ChatMessage, CheckResult } from '../types';
import { TestingStage, TypeOfDifficultLevel_ResponseData } from './chatApi/chatApi';
import imageEasy from '../assets/img/level/EasyDisabled.svg';
import imageEasyHover from '../assets/img/level/Easy.svg';
import imageMiddle from '../assets/img/level/MiddleDisabled.svg';
import imageMiddleHover from '../assets/img/level/Middle.svg';
import imageHard from '../assets/img/level/HardDisabled.svg';
import imageHardHover from '../assets/img/level/Hard.svg';
import imageRandom from '../assets/img/level/RandomDisabled.svg';
import imageRandomHover from '../assets/img/level/Random.svg';

export type ChatHistory = {
  points: number;
  penalty: number;
  messages: Array<ChatMessage>;
};

export type CheckHistory = {
  points: number;
  penalty: number;
  messages: Array<CheckResult>;
};

export type Task = {
  id: number;
  taskProceedId: number;
  difficultCode: string;
  patient: Patient;
  patientModel?: PatientModel;
  testingStage: TestingStage;
};
export type PatientModel = {
  patient?: any;
};
export type Patient = {
  firstName: string;
  name?: string;
  secondName: string;
  photo: string;
  id: number;
};

export interface DifficultLevel extends TypeOfDifficultLevel_ResponseData {
  image?: string;
  imageHover?: string;
  title?: string;
  stepsDescription?: Array<[string, boolean]>;
  stepText?: string;
}

export enum DifficultCode {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
}

export const DIFFICULT_DATA_BY_CODE: {
  [key: string]: {
    code: DifficultCode;
    image: string;
    imageHover: string;
    stepsDescription?: Array<[string, boolean]>;
  };
} = {
  H1: {
    code: DifficultCode.H1,
    image: imageEasy,
    imageHover: imageEasyHover,
    stepsDescription: [
      ['Жалобы и Анамнез', true],
      ['Статус', true],
      ['Предварительный диагноз', true],
      ['Дополнительное обследование', true],
      ['Диагноз', true],
      ['Лечение', false],
    ],
  },
  H2: {
    code: DifficultCode.H2,
    image: imageMiddle,
    imageHover: imageMiddleHover,
    stepsDescription: [
      ['Жалобы и Анамнез', true],
      ['Статус', true],
      ['Предварительный диагноз', true],
      ['Дополнительное обследование', true],
      ['Диагноз', true],
      ['Лечение', true],
    ],
  },
  H3: {
    code: DifficultCode.H3,
    image: imageHard,
    imageHover: imageHardHover,
    stepsDescription: [
      ['Жалобы и Анамнез', true],
      ['Статус', true],
      ['Предварительный диагноз', true],
      ['Дополнительное обследование', true],
      ['Диагноз', true],
      ['Лечение', true],
    ],
  },
  Default: { code: DifficultCode.H1, image: imageRandom, imageHover: imageRandomHover },
};

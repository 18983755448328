import React from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'
import SocialButtonsPanel from '../../components/auth/SocialButtonsPanel'
import BackPanel from '../../components/auth/BackPanel'
import PasswordResetForm from '../../components/auth/Forms/PasswordResetForm'

export default function PasswordResetPage() {
  return (
    <AuthLayout>
      <BackPanel text='Восстановление пароля' />
      <PasswordResetForm />
      <SocialButtonsPanel />
    </AuthLayout>
  )
}

import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItemData, MenuItemDataSteps } from '../../../types';
import SidebarStep from './SidebarStep';
import { getTestingstageTaskOnTask } from '../../../api/chatApi/chatApi';
import chatApi from '../../../api/chatApi';
import Box from '@mui/material/Box';
import useTestingStage from '../../../hooks/testingTask/useTestingStage';

interface LevelPageProps {
  menuItems?: MenuItemData[];
  nameStagesOnTask?: MenuItemDataSteps[];
}

const LevelPage: React.FC<LevelPageProps> = ({ menuItems = [], nameStagesOnTask = [] }) => {
  const history = useHistory();
  const location = useLocation();
  const [stages, setStages] = useState<MenuItemDataSteps[]>([]);
  const [lastStage, setLastStage] = useState<number>(0);
  const [loadMenuItems, setLoadMenuItems] = useState<boolean>(false);
  const currentStage = useTestingStage();


  const onClickStep = useCallback(
    (menuItem: MenuItemData) => {
      if (currentStage >= menuItem.activateStage!!) {
        history.push(menuItem.routePath);
      }
    },
    [currentStage, history]
  );

  const onClickNewSteps = useCallback(
    (menuItem) => {
      console.log('menuItem.routePath', menuItem.routePath)
      history.push(menuItem.routePath);
    },
    [history]
  );

  // Функция для получения этапов
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task || []);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  async function getHistoryStage() {
    setLoadMenuItems(true);
    try {
      const res = await chatApi.getTestingStageHistory();
      const lastActiveStageId = res.result.splice(-1, 1)[0]?.activeStageId || 0;
      setLastStage(lastActiveStageId);
      setLoadMenuItems(false);
    } catch (error) {
      console.log(error);
      setLoadMenuItems(false);
    }
  }

  useEffect(() => {
    getHistoryStage();
  }, []);



  const getStatus = (it, currentPath) => {
    if (it.routePath === currentPath && currentPath === '/legend' && it.activeStage === lastStage + 1) {
      return 'active';
    } else if (it.routePath === currentPath && currentPath !== '/legend') {
      return 'active';
    } else if (it.activeStage <= lastStage) {
      return 'complete';
    } else if (it.activeStage > lastStage) {
      return 'inactive';
    }
  };

  if (loadMenuItems) {
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
    </Box>;
  }


  return (
    <>
      {nameStagesOnTask.length === 0 ? (
        menuItems.map((it, inx) => {
          let status: 'inactive' | 'active' | 'complete';
          if (lastStage >= it.activateStage!!) status = 'complete';
          else if (lastStage < it.activateStage!!) status = 'inactive';
          if (history.location.pathname === it.routePath) status = 'active';

          return (
            <SidebarStep
              number={inx + 1}
              key={inx + 1}
              title={it.title}
              status={status!!}
              onClick={() => onClickStep(it)}
            />
          );
        })
      ) : (
        nameStagesOnTask.sort((a, b) => a.activeStage - b.activeStage).map((it, inx) => {
          let status = getStatus(it, location.pathname);
          return (
            <SidebarStep
              number={inx + 1}
              key={inx + 1}
              status={status}
              name={it.testingstage_descr}
              onClick={() => onClickNewSteps(it)}
            />
          );
        })
      )}
    </>
  );
};

export default LevelPage;

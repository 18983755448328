import React, { useEffect, useState } from 'react';
import chatApi from '../../api/chatApi';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import { checkRole } from '../../common/checkRole';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { ComboBox, ToastNotification } from '@carbon/react';
import SearchBar from 'material-ui-search-bar';
import { Loading } from '@carbon/react';
import { useDebounce } from 'react-use';
import jwt from 'jsonwebtoken';
import { Checkbox } from '@material-ui/core';

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'center',
    fontWeight: 600,
    backgroundColor: '#E7F0FE'

  },
  bodyTable: {
    textAlign: 'left',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      background: '#e8e8e8',
    },
  },
  item: {
    display: 'flex',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 500,
    margin: '30px 0 0',
  },
  btn: {
    position: 'relative',
    background: '#2196f3',
    color: 'white',
    textTransform: 'uppercase',
    border: 'none',
    marginTop: 20,
    padding: 20,
    fontSize: 16,
    display: 'block',
    appearance: 'none',
    borderRadius: 4,
    width: '50%',
    fontWeight: 400,
    letterSpacing: '0.5rem',
    transition: '0.3s all',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
    },
  },
  input: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 4,
    border: '1px solid black',
    padding: '10px 15px',
    fontSize: 14,
  },
  wrapper: {
    padding: '0 20px 50px',
    marginTop: 90,
  },
  p: {
    color: '#bf1650',
    margin: 0,
    marginBottom: 20,
    '&:before': {
      display: 'inline',
      content: '⚠',
    },
  },
  label: {
    width: '100%',
    marginBottom: 30,
    marginRight: 30,
  },
  text: {
    fontSize: 17,
    fontWeight: 600,
    marginBottom: 30,
    display: 'flex',
  },
  newBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  textInput: {
    borderRadius: '8px'
  },
  formWrapper: {
    width: '50%'
  }
});

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const AssignGroups = () => {
  const classes = useRowStyles();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [login, setLogin] = useState('');
  const [dataUser, setDataUser] = useState(null);
  const [warningState, setWarningState] = useState(false)
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    checkRole();
  }, []);


  const bottom = { marginBottom: 450 }

  useDebounce(
    async () => {
      const keys = ['nameGroup', 'descriptionGroup']
      if (!text) {
        return getStudentsGroup(idOrg);
      }
          const newFilter = groupsOrgs.filter((row) => keys.some(key => {
            if (row[key] !== null) {
              return row[key].toLowerCase().includes(text.toLowerCase())
            }
          }));
          setGroupsOrgs(newFilter);
    },
    700,
    [text]
  )

  ////////////мое
  const [orgs, setOrgs] = useState([])
  const [groupsOrgs, setGroupsOrgs] = useState([])
  const [idOrg, setIdOrg] = useState(null)

  const getOrgs = async() => {
    const roleDecode = jwt.decode(localStorage.getItem('role'));
    const role = roleDecode.auth.split(',');
    console.log(role)
    if(role.includes('ROLE_ADMIN')) {
      await chatApi.getAllTaskOrganization()
      .then(res => {
        const sort = res.result.filter(el => el.name.trim() !== '')
        setOrgs(sort)
      })
      .finally(() => setLoading(false))
    }
    else {
      await chatApi.getUserTrainers()
      .then(res => {
        setOrgs(res.result.organizations)
      })
      .finally(() => setLoading(false))
    }
  }

  const getStudentsGroup = async(id) => {
    setWarningState(false)
    setLoading(true)
    setIdOrg(id)
    await chatApi.getStudentsGroupTask()
    await chatApi.getStudentsGroupOrganization(id)
    .then(res => setGroupsOrgs(res.result))
    .catch(err => {
      setWarningState(true)
      console.log(err)
      setGroupsOrgs([])
    })
    .finally(() => setLoading(false))
  }


  useEffect(() => {
    getOrgs()
  }, []);

  return (
    <>
      <div style={{ margin: '0' }}>
        <Loading active={loading} small={false} withOverlay={true} description="Загрузка..." />
        <div style={dataUser ? null : bottom}>
          {
            warningState &&
            <div style={{ position: 'fixed', right: 10, top: 100, zIndex: 1000 }}>
              <ToastNotification
                kind="warning"
                hideCloseButton={false}
                lowContrast={false}
                title="Ошибка"
                subtitle={
                  <span>Пользователь не принадлежит этой организации{" "}</span>
                }
                timeout={5000}
              />
            </div>
          }
          <div className={classes.title}>Назначение задач на группы</div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <ComboBox
              className={classes.comboBox}
              placeholder={'Выберите организацию'}
              items={orgs ? orgs : []}
              itemToString={(item) => item ? item.name : ''}
              onChange={({ selectedItem }) => getStudentsGroup(selectedItem.id)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <SearchBar value={text} placeholder='Поиск' onChange={searchVal => setText(searchVal)} style={{ marginBottom: 10, width: 300 }} />
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead style={{ background: '#e0e0e0' }}>
                <TableRow>
                  <TableCell className={classes.titleTable} align="center">Название группы</TableCell>
                  <TableCell className={classes.titleTable} align="center">
                    Описание
                  </TableCell>
                  <TableCell className={classes.titleTable} align="center">
                    Выбор
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0 ? groupsOrgs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : groupsOrgs).map((row, index) => (
                  <TableRow className={classes.bodyTable}  key={index}>
                    <TableCell align="center" component="th" scope="row">
                      {row.nameGroup}
                    </TableCell>
                    <TableCell align="center">
                      {row.descriptionGroup}
                    </TableCell>
                    <TableCell align="center">
                    <Checkbox
                        checked={row.active}
                        // onChange={() => setAddFIO(!addFIO)}
                        color='primary' />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ background: '#E7F0FE' }}
                    rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                    colSpan={3}
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default AssignGroups;


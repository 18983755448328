import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import chatApi from '../../api/chatApi'
import { getTaskPoint } from '../../api/chatApi/chatApi'
import { SidebarWidth } from '../../constants/default'
import useFinishTaskDialogState from '../../hooks/testingTask/useFinishTaskDialogState'
import { usePointProcentStore } from '../../pages/chat/store'
import { MenuItemData, Notes, ProgressBarType, User } from '../../types'
import { pxToRem, pxToRemGutter } from '../../utils'
import CustomButton from '../common/CustomButton'
import ConfirmDialog from '../ConfirmDialog'
import MedNotes from '../MainSidebar/MedNotes'
import ProgressBar from '../MainSidebar/ProgressBar'
// import SidebarMenu from '../MainSidebar/SidebarMenu';
import UserInfo from '../MainSidebar/UserInfo'
import SidebarSteps from './SidebarSteps'
import { LinearProgress, Box, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  sidebar: {
    width: pxToRem(SidebarWidth),
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper
    // paddingBottom: 90,
  },

  progress_container: {
    width: '100%',
    padding: pxToRemGutter(0, 24, 16),
    margin: 0
  },
  blockWrap: {
    width: '100%',
    padding: pxToRemGutter(0, 24, 16),
    margin: 0,
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  blockModelBtn: {
    textAlign: 'center'
  }
}))

type Props = {
  user: User
  notes?: Notes
  anamnesisProgress: ProgressBarType
  anamnesisErrors: ProgressBarType
  mini: boolean
  menuItems: Array<MenuItemData>
  patientModel: any
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress style={{ background: 'red', opacity: 0.7, height: 9 }} variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography style={{ fontSize: 18 }} variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function MobileSidebar({
  user,
  notes,
  anamnesisProgress,
  anamnesisErrors,
  mini,
  patientModel
}: Props) {
  const classes = useStyles()

  const { close, onSubmit, open, show } = useFinishTaskDialogState()
  const [maxPointTask, setMaxPointTask] = useState(0);
  const [mainPont, setMainPoint] = useState(0);
  const [mainProcent, setMainProcent] = useState(0);
  const [task, setTask] = useState(0);
  const champ = localStorage.getItem('idChampForTask')

  const datas = usePointProcentStore(state => state.data)

  // const patientModel = useQuery('patientModel', async () => {
  //   const currTask: any = async () => await chatApi.unfinishedTask();
  //   const task = await currTask();
  //   return task;
  // });

  const getTaskPoints = async (task) => {
    await getTaskPoint(task)
      .then(res => setMaxPointTask(res.data.task_point.max_point_task))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (datas) {
      const point = datas.reduce(function (acc, obj) { return acc + obj.point; }, 0);
      const procent = datas.reduce(function (acc, obj) { return acc + obj.task_procent; }, 0);
      setMainPoint(point)
      setMainProcent(procent)
    }
  }, [datas]);

  useEffect(() => {
    unfinishedTaskHandler()
    // window?.ym(87333080, 'hit', window.location.href);
  }, [])

  async function unfinishedTaskHandler() {
    try {
      const response = await chatApi.unfinishedTask()
      setTask(response.result.taskId)
      console.log(response);
      getTaskPoints(response.result.taskId)
    } catch (err) {
      console.log('при выполнении unfinishedTaskHandler', err);
    }
  }

  const saveProcced = async () => {
    await chatApi.saveProcced(task, Number(champ), false)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div className={classes.sidebar}>
      <div>
        <UserInfo user={user} mini={mini} />
        <div className={classes.progress_container}>
          <LinearProgressWithLabel value={mainProcent < 0 ? 0 : mainProcent} />
        </div>
        <div className={classes.blockWrap}>
          {/* show */}
          {champ
            ?
            <div>
              <Button onClick={() => {
                saveProcced()
                onSubmit()
                // localStorage.removeItem('newResult');
                // // localStorage.removeItem('conditionType');
                // localStorage.removeItem('numberOfCondition');
                // localStorage.removeItem('surgeons');
                // localStorage.removeItem('surgeonList');
                // localStorage.removeItem('imagePointsView');

              }} color='primary' variant='outlined'>
                Закончить задачу
              </Button>
              <div style={{ marginTop: 10, fontSize: 19 }}>Задача по чемпионату</div>
            </div>
            :
            <Button onClick={() => {
              onSubmit()
              // localStorage.removeItem('newResult');
              // // localStorage.removeItem('conditionType');
              // localStorage.removeItem('numberOfCondition');
              // localStorage.removeItem('surgeons');
              // localStorage.removeItem('surgeonList');
              // localStorage.removeItem('imagePointsView');

            }} color='primary' variant='outlined'>
              Закончить задачу
            </Button>
          }
          {/* <Button onClick={show}>Закончить задачу</Button> */}
        </div>
      </div>
      <div className={classes.blockModelBtn}>
        {/* {patientModel?.data?.result?.task?.patientModel?.view3dLink && (
          <Button style={{ marginTop: '12px' }} color="primary" variant="contained" href="/testModel">
            Осмотр пациента
          </Button>
        )} */}
      </div>

      <SidebarSteps />

      {/* <SidebarMenu mini={mini} menuItems={menuItems} /> */}
      {/* <MedNotes notes={notes} /> */}
      {/* <AddMedNoteBtn /> */}
      <ConfirmDialog
        open={open}
        title='Завршение задачи'
        content='Вы уверены, что хотите завершить задачу?'
        onClose={close}
        onSubmit={onSubmit}
      />
    </div>
  )
}

import { setTestCognitive } from "../../api/chatApi/chatApi";
import React, { useEffect, useState } from "react";
import { ProgressIndicator, ProgressStep, Button } from '@carbon/react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import RatingDialog from "../../components/Chat/RatingDialog";
import CustomButton from "../../components/common/CustomButton";

const Test = ({ data, setDataTest, onSubmit, openDialog }) => {
  const [current, setCurrent] = useState(0)
  const [answer, setAnswer] = useState([])
  const [value, setValue] = useState('')
  const [invalid, setInvalid] = useState(false)
  const [bodyTest, setBodyTest] = useState({})
  const [toggle, setToggle] = useState(false)
  const [procent, setProcent] = useState('')


  const handleChange = event => {
    setValue(String(event.target.value));
  };

  useEffect(() => {
    if (data) {
      setAnswer(data[current])
    }
  }, [current, data]);


  const next = () => {
    setInvalid(false)
    if (value === '') {
      setInvalid(true)
      setCurrent(current)
    }
    else {
      if (current < data.length) {
        const id_question = data.filter((el, index) => index === current)
        setBodyTest(prev => ({ ...prev, [`question_${current + 1}`]: id_question[0].question_id, [`answer_${current + 1}`]: Number(value) }))
        setCurrent((prev) => prev + 1)
        setValue('')
      }
      if (current === data.length - 1) {
        const id_question = data.filter((el, index) => index === current)
        setBodyTest(prev => ({ ...prev, [`question_${current + 1}`]: id_question[0].question_id, [`answer_${current + 1}`]: Number(value) }))
        setToggle(true)
      }
    }
  }


  const end = () => {
    setTestCognitive(bodyTest)
      .then(res => {
        const result = res.data.question
        const filter = result.filter(el => el.correct === true)
        const totalAnswer = filter.length / result.length * 100
        setProcent(String(Math.round(totalAnswer)))
      })
      .catch(err => console.log(err))
  }


  const nextUp = () => {
    setDataTest(null)
  }

  useEffect(() => {
    if (!data && !openDialog) {
      onSubmit()
    }
  })

  return (
    <div style={{ margin: 50 }}>
      {data ?
        <>
          <div style={{ fontWeight: 500, fontSize: 25, marginBottom: 25 }}>Оценка когнитивных функций</div>
          {procent ?
            <div>
              <div style={{ fontSize: 18, margin: '16px 0' }}>Итог: вы правы на {procent}%</div>
              <CustomButton
                onClick={nextUp}
              >
                Завершить тест
              </CustomButton>
              {/* <Button onClick={nextUp}>Завершить тест</Button> */}
            </div>
            :
            <div>
              <div className="some-container">
                <ProgressIndicator vertical={window.innerWidth > 900 ? false : true} currentIndex={current}>
                  {data.map((el, index) => {
                    return (
                      <ProgressStep
                        invalid={index === current ? invalid : null}
                        secondaryLabel={invalid === true && index === current ? "Выберите вариант ответа" : null}
                        key={index}
                        label={`Вопрос ${index + 1}`}
                      />
                    )
                  })}
                </ProgressIndicator>
              </div>
              <div style={{ margin: '32px 0px', display: 'flex', flexDirection: 'column' }}>
                {answer ?
                  answer.answers ?
                    <FormControl error={invalid ? true : false}>
                      <FormLabel id="test-radio-buttons-group">Выберите ответ</FormLabel>
                      <RadioGroup aria-labelledby="test-radio-buttons-group" name="test-radio-buttons-group" value={value} onChange={handleChange}>
                        {answer.answers.map(el => (
                          <FormControlLabel value={String(el.answers_id)} control={<Radio color="primary" />} label={el.answers} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    :
                    null
                  :
                  null}
                {toggle ?
                  <CustomButton
                    onClick={end}
                  >
                    Завершить тест
                  </CustomButton>
                  // <Button style={{marginRight: 10, marginTop: 32}} onClick={end}>Завершить тест</Button>
                  :
                  <CustomButton
                    onClick={next}
                  >
                    Следующий вопрос
                  </CustomButton>
                  // <Button style={{marginRight: 10, marginTop: 32}} onClick={next}>Следующий вопрос</Button>
                }
              </div>
            </div>
          }
        </>
        : openDialog ? <RatingDialog open={true} onSubmit={onSubmit} /> : null

      }

    </div>
  );
}

export default Test;
import { AsyncState } from 'react-use/lib/useAsync';
import { TestingStage } from '../api/chatApi/chatApi';



export enum RoutePath {
  root = '/',
  med = '/med',
  testRoot = '/testRoot',
  errorTable = '/errorTable',
  studentsRating = '/studentsRating',
  specificBallTable = '/specificBallTable',
  ratingTable = '/ratingTable',
  depersonalizedRatingData = '/depersonalizedRatingData',
  analyticsTable = '/analyticsTable',
  modelPoint = '/modelPoint',
  accreditationPage = '/AccreditationPage',
  orgDescriptionPage = '/orgDescriptionPage',
  orgTeachersPage = '/orgTeachersPage',
  // demo = '/demo',
  login = '/login',
  register = '/register',
  registerInfo = '/register/info',
  passReset = '/password-reset',
  passResetConfirm = '/password-reset/confirm',
  newPassword = '/new-password',
  emailConfirm = '/register/confirm',
  dashboard = '/dashboard',
  chat = '/chat',
  chatInitial = '/chat#initial',
  inspection = '/inspection',
  inspectionInitial = '/inspection#initial',
  preDiagnosis = '/preDiagnosis',
  pharmacy = '/pharmacy',
  preDiagnosisInitial = '/preDiagnosis#initial',
  research = '/research',
  consultationPage = '/consultation-page',
  researchInitial = '/research#initial',
  diagnosis = '/diagnosis',
  rheumatology = '/rheumatic_status',
  lastAnalyses = '/last_spe',
  diagnosisInitial = '/diagnosis#initial',
  clinical_diagnosis = '/clinical_diagnosis',
  preliminary_diagnosis = '/preliminary_diagnosis',
  therapy = '/therapy',
  newChat = '/newChat',
  Cognitive = '/cognitive',
  CognitiveTest = '/cognitive/test',
  CognitiveHistory = '/cognitive/history',
  examination = '/examination',
  aboutCompany = '/aboutCompany',
  infoUniversity = '/infoUniversity',
  connection = '/connection',
  informationSystems = '/information-systems',
  conclusion = '/conclusion',
  //Profile
  profile = '/profile',
  //Champ
  championshipList = '/championshipList',
  championshipNew = '/championshipNew',
  championshipItem = '/championshipItem',
  championshipRating = '/championshipRating',
  championship = '/championship',
  //Handbooks
  handbooks = '/handbooks',
  //ADMIN
  admin = '/admin',
  //Methodological
  methodological = '/methodological',
  // DEMOPAGES
  chooseAcademLevel = '/chooseAcademLevel',
  researchEmpty = '/researchEmpty',
  researchLabDialog = '/researchLabDialog',
  researchInstrumentalDialog = '/researchInstrumentalDialog',
  researchFilled = '/researchFilled',
  researchPhotoDialog = '/researchPhotoDialog',
  diagnosisSet = '/diagnosisSet',
  diagnosisSuccess = '/diagnosisSuccess',
  diagnosisFailed = '/diagnosisFailed',
  treatmentSet = '/treatmentSet',
  treatmentAddDialog = '/treatmentAddDialog',
  treatmentResult = '/treatmentResult',
  finalTaskResult = '/finalTaskResult',
  demoResult = '/demo-result',
  rating = '/rating',
  constructors = '/constructors',
  testModel = '/testModel',
  stress = '/stress',
  legend = '/legend'
}

export enum MessageType {
  message = 'message',
  action = 'end',
  humanBody = 'humanBody',
}

export enum MessageAuthor {
  USER,
  SYSTEM,
}

export type ChatMessage = {
  author?: MessageAuthor;
  message: string;
  type: MessageType;
  attachment: Array<Attachment>;
  tdMediaId: number;
};

export type CheckResult = {
  paramName: string;
  value: string;
  anamnesisId: string;
  points: number;
  penalty: number;
};

interface Model3DAttachment extends Attachment {
  modelSource: string;
}

export type Attachment = {
  id: number;
  type: AttachmentType;
};

export enum AttachmentType {
  TEXT = 'text',
  MODEL_3D = 'model3d',
}

export type ExamLevelsTypes = {
  [key: string]: { title: string; image: string; imageDisabled: string; accessLevel?: number };
};

export type ConverstionTypes = {
  [key: string]: { title: string; image: string };
};

export type HpParamItemTypes = {
  key: string;
  title: string;
  image: string;
};
export type HpParamsTypes = { [key: string]: HpParamItemTypes };
export type ParamDataListTypes = {
  [key: string]: ParamDataTypes;
};
export type ParamDataTypes = { text?: string; image?: string; video?: string; sound?: string } | null;
export type MenuItemData = {
  title: string;
  routePath: string;
  icon?: any;
  activateStage?: TestingStage;
  accessLevel?: number;
};

export type MenuItemDataSteps = {
  id: number;
  active: boolean;
  code: string;
  testingstage_descr: string;
  routePath: string;
  activeStage: TestingStage;
};

export type User = { name: string; avatar: string; status: number };
export type Notes = { key: string; value: string }[];
export type ProgressBarType = {
  currentVal: number;
  maxVal: number;
  text: string;
};

export const NavbarHeight = 65;
export const SidebarWidth = 256;

export type HumanAreaImageMapTypes = {
  name: string;
  shape: string;
  coords: number[];
  scaledCoords: number[];
  center: [number, number];
};

export const mapAsyncState = <T, R>(mapFn: (value: T) => R, state?: AsyncState<T>): AsyncState<R> | undefined => {
  if (state?.value) return { ...state, value: mapFn(state.value) } as AsyncState<R>;
  return state as AsyncState<R>;
};

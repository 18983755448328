import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { FunctionComponent } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.default,
    overflowY: 'hidden'
  }
}))

const FallbackPage: FunctionComponent = () => {
  const styles = useStyles()
  return (
    <div className={styles.root}>
      <Typography variant='h4'>Загрузка...</Typography>
    </div>
  )
}

export default FallbackPage

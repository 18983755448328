import React, { useState, useEffect } from "react";
import './style.css'; // Предполагается, что стили аналогичны другим компонентам

interface Form14804BackProps {
  formId: number; // Идентификатор формы
  onInputChange?: (formId: number, inputId: number, value: string) => void; // Сделаем опциональным
  initialValues?: { [key: number]: string }; // Начальные значения
  isReadOnly?: boolean;
  isShow?: boolean;
  onCheckboxStateChange?: (checkboxStates: { [key: number]: boolean }) => void;
  onCheckboxWrongAnswerStateChange?: (checkboxStates: { [key: number]: boolean }) => void;
  isShowWrongAnswerCheckbox?: boolean;
}

export default function Form14804Back({
  formId,
  onInputChange,
  initialValues,
  isReadOnly = false,
  isShow = false,
  onCheckboxStateChange,
  onCheckboxWrongAnswerStateChange,
  isShowWrongAnswerCheckbox = false
}: Form14804BackProps) {
  const headers = ['Приготовил', 'Проверил', 'Отпустил'];

  // Управление состоянием для полей ввода
  const [inputs, setInputs] = useState<{ [key: number]: string }>(() => {
    const initialInputs: { [key: number]: string } = {};
    headers.forEach((_, index) => {
      const inputId = 2 + index; // Предполагаем, что inputId начинается с 2
      initialInputs[inputId] = initialValues?.[inputId] || '';
    });
    return initialInputs;
  });

  // Управление состоянием для чекбоксов
  const [checkboxStates, setCheckboxStates] = useState<{ [key: number]: boolean }>(() => {
    const initialCheckboxState: { [key: number]: boolean } = {};
    headers.forEach((_, index) => {
      const checkboxId = 2 + index; // Соответствует inputId
      initialCheckboxState[checkboxId] = initialValues?.[checkboxId] === 'true';
    });
    return initialCheckboxState;
  });

  // Управление состоянием для чекбоксов "неправильных ответов"
  const [checkboxWrongAnswerStates, setCheckboxWrongAnswerStates] = useState<{ [key: number]: boolean }>(() => {
    const initialWrongCheckboxState: { [key: number]: boolean } = {};
    headers.forEach((_, index) => {
      const wrongCheckboxId = 2 + index; // Соответствует inputId
      initialWrongCheckboxState[wrongCheckboxId] = false; // Начальное состояние
    });
    return initialWrongCheckboxState;
  });

  // Обновление состояния при изменении initialValues
  useEffect(() => {
    if (initialValues) {
      // Обновляем значения полей ввода
      const updatedInputs: { [key: number]: string } = {};
      headers.forEach((_, index) => {
        const inputId = 2 + index;
        updatedInputs[inputId] = initialValues[inputId] || '';
      });
      setInputs(updatedInputs);

      // Обновляем состояния чекбоксов
      const updatedCheckboxStates: { [key: number]: boolean } = {};
      headers.forEach((_, index) => {
        const checkboxId = 2 + index;
        updatedCheckboxStates[checkboxId] = initialValues[checkboxId] === 'true';
      });
      setCheckboxStates(updatedCheckboxStates);
    }
  }, [initialValues]);

  // Обработчик изменений в полях ввода
  const handleInputChangeInternal = (inputId: number, value: string) => {
    setInputs(prev => ({
      ...prev,
      [inputId]: value
    }));

    onInputChange?.(formId, inputId, value); // Безопасный вызов через опциональную цепочку
  };

  // Обработчик изменений в чекбоксах
  const handleCheckboxChange = (checkboxId: number) => {
    setCheckboxStates(prev => {
      const newState = { ...prev, [checkboxId]: !prev[checkboxId] };
      onCheckboxStateChange?.(newState); // Безопасный вызов через опциональную цепочку
      return newState;
    });
  };

  // Обработчик изменений в чекбоксах "неправильных ответов"
  const handleWrongAnswerCheckboxChange = (checkboxId: number) => {
    setCheckboxWrongAnswerStates(prev => {
      const newState = { ...prev, [checkboxId]: !prev[checkboxId] };
      onCheckboxWrongAnswerStateChange?.(newState); // Безопасный вызов через опциональную цепочку
      return newState;
    });
  };

  return (
    <div className={'form14804BackContainer'} style={{ width: 700, margin: '0 auto' }}>
      {/* Заголовок */}
      <div className={'form14804Back-title'}>
        <span>Оборотная сторона</span>
      </div>

      {/* Блок отметки назначения препарата */}
      <div className={'form14804Back-content-block'} style={{ display: 'flex', justifyContent: 'end', margin: '25px 0' }}>
        <div className={'form14804Back-block'} style={{ border: '1px solid black', padding: 15, width: 300, textAlign: 'center' }}>
          <span>Отметка о назначении лекарственного препарата по решению врачебной комиссии</span>
        </div>
        {isShow && (
          <input
            id={`checkbox-1`}
            type="checkbox"
            checked={checkboxStates[1] || false}
            onChange={() => handleCheckboxChange(1)}
            style={{ marginLeft: 10 }}
          />
        )}
        {isShowWrongAnswerCheckbox && (
          <input
            id={`checkbox-1-wrong`}
            type="checkbox"
            checked={checkboxWrongAnswerStates[1] || false}
            onChange={() => handleWrongAnswerCheckboxChange(1)}
            style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
          />
        )}
      </div>

      {/* Блок заголовков и полей ввода */}
      <div className={'form14804Back-headers'} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {headers.map((header, index) => {
          const inputId = 2 + index; // Соответствует inputId и checkboxId
          return (
            <div key={inputId} className={'form14804Back-header-item'} style={{ display: 'flex', flexDirection: 'column', border: '1px solid black', width: 300 }}>
              <div className={'form14804Back-header-title'} style={{ borderBottom: '1px solid black', padding: 15, textAlign: 'center' }}>
                <span>{header}</span>
              </div>
              <div className={'form14804Back-header-input'} style={{ display: 'flex', justifyContent: 'center' }}>
                <input
                  id={`input-${inputId}`}
                  type="text"
                  value={inputs[inputId] || ''}
                  onChange={(event) => handleInputChangeInternal(inputId, event.target.value)}
                  disabled={isReadOnly}
                  style={{ width: '98%', border: 'none' }}
                />
                {isShow && (
                  <input
                    id={`checkbox-${inputId}`}
                    type="checkbox"
                    checked={checkboxStates[inputId] || false}
                    onChange={() => handleCheckboxChange(inputId)}
                    style={{ marginLeft: 10 }}
                  />
                )}
                {isShowWrongAnswerCheckbox && (
                  <input
                    id={`checkbox-${inputId}-wrong`}
                    type="checkbox"
                    checked={checkboxWrongAnswerStates[inputId] || false}
                    onChange={() => handleWrongAnswerCheckboxChange(inputId)}
                    style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

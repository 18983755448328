import React, { useState, useEffect } from 'react';
// @ts-ignore
import { UserAvatarFilledAlt, Menu, HelpFilled, TaskSettings, DnsServices } from '@carbon/icons-react';
// @ts-ignore
import { Header, HeaderName, HeaderGlobalAction, HeaderGlobalBar, Theme, HeaderPanel, Switcher, SwitcherItem } from '@carbon/react';
import './index.scss';
import { Tooltip } from '@material-ui/core';
// @ts-ignore
import { RoutePath, userInfo } from '../../types';
import chatApi from '../../api/chatApi';
import useLogout from '../../hooks/useLogout';
// @ts-ignore
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { mergeLeft } from 'ramda';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& .cds--header': {
        // maxWidth: 1443,
        background: 'none',
        backgroundColor: 'black',
        opacity: 0.8,
        position: 'relative',
        borderBottom: 'none'
      },
      '& a.cds--header__name': {
        border: 'none'
      }
    },
    // '& .cds--header': {
    //   maxWidth: 1443,
    //   background: 'none',
    //   backgroundColor: 'black',
    //   opacity: 0.8,
    //   position: 'relative',
    //   borderBottom: 'none'
    // },
    headerCustomClassDiv: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'space-between',
      // maxWidth: 1443,
      width: '100%',
      alignItems: 'center',
      borderBottom: '1px solid white',
    },
    headerCustomContainer: {
      background: 'none',
      minWidth: 1443,
      width: '100%',
      display: 'flex',
      // justifyContent: 'center',
      position: 'absolute',
      margin: '0 auto',
      borderBottom: 'none',

      '@media (max-width: 860px)': {
        minWidth: '100%',
        justifyContent: 'flex-start'
      }
    },
    headerCustomContainerNoRoot: {
      background: 'white',
      minWidth: 1443,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      margin: '0 auto',
    },
    headerCustomContainerWithScroll: {
      background: '#FFFFFF',
      color: 'black',
      // maxWidth: 1443,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      margin: '0 auto'
    },
    headerWithScrollNoTestRoot: {
      background: '#FFFFFF',
      color: 'black',
      // maxWidth: 1443,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      margin: '0 auto',
      left: 0,
      top: 0
    },
    tooltip: {
      fontSize: '1em',
    },
    arrow: {
      display: 'none',
    },

    // customIconsClass: {
    //  '& svg.outer-icon-fill': {
    //    fill: 'white'
    //  }
    // }
  })
)

type Props = {
  title?: string
  onClickTooltip?: () => void
  hiddenSidebar?: boolean
  showComponentHelpFilled?: boolean
}

export default function MainHeader({
  title,
  hiddenSidebar,
  onClickTooltip: onClick,
  showComponentHelpFilled
}: Props) {
  const [userPanel, setUserPanel] = useState(false);
  const [menuPanel, setMenuPanel] = useState(false);
  const [user, setUser] = useState<userInfo | null>(null);
  const history = useHistory()

  const style = useStyles()

  const roleDecode = jwt.decode(localStorage.getItem('role'));
  const role = roleDecode.auth.split(',');

  const logout = useLogout();

  const getUser = async () => {
    const data = await chatApi.getUserTrainers();
    setUser(data.result);

    return data.result;
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleMenuPanel = () => {
    if (menuPanel === false) {
      setMenuPanel(true);
    } else {
      setMenuPanel(false);
    }
  };

  const handleUserPanel = () => {
    if (userPanel === false) {
      setUserPanel(true);
    } else {
      setUserPanel(false);
    }
  };

  const styleTitle = { position: 'absolute', left: '48%', fontSize: '18px' }
  const styleTitle1 = { position: 'absolute', left: '36%', fontSize: '9px' }

  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  let loc = window.location.pathname
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const renderTooltipTitle = () => {
    if (!user) return "Пользователь не определен";

    if (user.firstName || user.lastName) {
      return `${user.firstName || ''} ${user.lastName ? ' ' + user.lastName : ''}`.trim();
    }

    return "Пользователь без имени";
  };


  return (
    <div className="container" style={{ fontFamily: 'Helvetica Neue', color: 'black', position: 'relative', width: '100%' }} >
      {/*<Theme theme="g100">*/}
      <Header aria-label="" className={loc === '/testRoot' ? style.headerCustomContainer : null}>
        {
          loc === '/testRoot' ?
            <div className={scroll > 50 && loc === '/testRoot' ? style.headerCustomContainerWithScroll : style.headerCustomClassDiv}>
              <HeaderGlobalAction aria-label="Меню" onClick={handleMenuPanel} style={{ fill: 'white' }}>
                <Menu style={{ fill: 'black' }} />
              </HeaderGlobalAction>
              <HeaderName aria-label="" href={RoutePath.testRoot} prefix="" style={{ color: 'black' }}>
                NeuroDoc
              </HeaderName>
              {/* <Box className={title}  >{title}</Box> */}

              {
                window.innerWidth > 500 ?
                  <HeaderName className={title} prefix="" href={''} style={{
                    position: 'absolute',
                    left: '48%',
                    fontSize: '18px',
                  }}>{title}</HeaderName> :
                  <HeaderName
                    className={title}
                    prefix=""
                    href={''}
                    style={{ position: 'absolute', left: '36%', fontSize: '9px' }}
                  >{title}
                  </HeaderName>
              }


              <HeaderGlobalBar >
                {showComponentHelpFilled ? <HelpFilled onClick={onClick} style={{ marginTop: '16px', marginRight: '16px', cursor: 'pointer' }} /> : null}
                {/* {role.includes('ROLE_ADMIN') &&
                  <HeaderGlobalAction aria-label="Подключение к внешним информационным системам" onClick={() => history.push(`${RoutePath.informationSystems}`)}>
                    <DnsServices style={scroll > 50 ? { fill: 'black' } : { fill: 'white' }} />
                  </HeaderGlobalAction>
                } */}
                {/* <HeaderGlobalAction aria-label="Загрузка справочников" onClick={() => history.push(`${RoutePath.handbooks}`)}>
                    <TaskSettings style={scroll > 50 ? {fill: 'black'} : {fill: 'white'}}/>
                  </HeaderGlobalAction> */}
                <Tooltip
                  title={renderTooltipTitle()}
                  classes={{ arrow: style.arrow, tooltip: style.tooltip }}
                  arrow placement='bottom-end'>
                  <HeaderGlobalAction onClick={handleUserPanel} style={{ fill: 'black', marginRight: '0px' }}>
                    <UserAvatarFilledAlt />
                  </HeaderGlobalAction>
                </Tooltip>
                <p
                  onClick={() => window.location.href = `${RoutePath.profile}`}
                  style={scroll > 50 ? { color: 'black', alignSelf: 'center', marginRight: 20, cursor: 'pointer' } :
                    { color: 'black', alignSelf: 'center', marginRight: 20, cursor: 'pointer' }}>
                  {user && user.firstName} {user && user.lastName}
                </p>
              </HeaderGlobalBar>

              {menuPanel ? (
                <HeaderPanel
                  onMouseLeave={() => setMenuPanel(false)}
                  aria-label=""
                  style={{ height: 'fit-content', left: 0, paddingBottom: 10, position: 'absolute' }}
                  expanded
                >
                  <Switcher>
                    {
                      role.includes('ROLE_EXPERT') ||
                        role.includes('ROLE_CONSTRUCTOR') ||
                        role.includes('ROLE_ADMIN') ?
                        <SwitcherItem href={RoutePath.constructors} >
                          Конструкторы
                        </SwitcherItem>
                        :
                        null
                    }
                    <SwitcherItem href={RoutePath.championship} >
                      Чемпионаты
                    </SwitcherItem>
                    <SwitcherItem href={RoutePath.newChat} >
                      Тренажер
                    </SwitcherItem>
                    <SwitcherItem href={RoutePath.accreditationPage} >
                      Тестирование
                    </SwitcherItem>

                    <SwitcherItem href={RoutePath.methodological} >
                      Методические материалы
                    </SwitcherItem>
                    {role.includes('ROLE_ANALIST') ||
                      role.includes('ROLE_TEACHER') ||
                      role.includes('ROLE_ADMIN') ||
                      role.includes('ROLE_TEST_TRAINING_CHECK') ?
                      <SwitcherItem href={RoutePath.rating} >
                        Рейтинг
                      </SwitcherItem>
                      :
                      null
                    }
                    {role.includes('ROLE_ADMIN') ? (
                      <SwitcherItem href={RoutePath.admin} >
                        Администрирование
                      </SwitcherItem>
                    ) : null}
                  </Switcher>
                </HeaderPanel>
              ) : null}

              {userPanel ? (
                <HeaderPanel onMouseLeave={() => setUserPanel(false)} style={{ height: '90px', position: 'absolute' }} expanded>
                  <Switcher>
                    <SwitcherItem isSelected href={RoutePath.profile}>
                      Профиль
                    </SwitcherItem>
                    <SwitcherItem onClick={logout} >
                      Выход
                    </SwitcherItem>
                  </Switcher>
                </HeaderPanel>
              ) : null}
            </div> :
            <div className={style.headerWithScrollNoTestRoot}>
              <HeaderGlobalAction aria-label="Меню" onClick={handleMenuPanel} style={{ fill: 'white' }}>
                <Menu style={{ fill: 'black' }} />
              </HeaderGlobalAction>
              <div style={{ marginLeft: 20 }}>
                <HeaderName aria-label="" href={RoutePath.testRoot} prefix="" style={{ fill: 'black' }}>
                  NeuroDoc
                </HeaderName>
              </div>

              {/* <Box className={title}  >{title}</Box> */}
              {
                window.innerWidth > 500 ?
                  <HeaderName className={title} prefix="" style={{ position: 'absolute', left: '48%', fontSize: '18px' }}>{window.innerWidth > 980 ? title : ''}</HeaderName> :
                  <HeaderName className={title} prefix="" style={{ position: 'absolute', left: '36%', fontSize: '9px' }}>{window.innerWidth > 980 ? title : ''}</HeaderName>
              }

              <HeaderGlobalBar >
                {showComponentHelpFilled ? <HelpFilled onClick={onClick} style={{ marginTop: '16px', marginRight: '16px', cursor: 'pointer' }} /> : null}
                {/* {role.includes('ROLE_ADMIN') &&
                  <HeaderGlobalAction aria-label="Подключение к внешним информационным системам" onClick={() => history.push(`${RoutePath.informationSystems}`)}>
                    <DnsServices style={{ fill: 'black' }} />
                  </HeaderGlobalAction>
                } */}
                {/* <HeaderGlobalAction aria-label="Загрузка справочников" onClick={() => history.push(`${RoutePath.handbooks}`)}>
                  <TaskSettings style={{ fill: 'black' }} />
                </HeaderGlobalAction> */}
                <Tooltip
                  title={renderTooltipTitle()}
                  classes={{ arrow: style.arrow, tooltip: style.tooltip }}
                  arrow placement='bottom-end'>
                  <HeaderGlobalAction onClick={handleUserPanel} style={{ fill: 'black', marginRight: '0px' }}>
                    <UserAvatarFilledAlt />
                  </HeaderGlobalAction>
                </Tooltip>
                <p onClick={() => window.location.href = `${RoutePath.profile}`} style={window.innerWidth < 400 ?
                  { color: 'black', alignSelf: 'center', marginRight: 20, fontSize: 10, cursor: 'pointer' } :
                  { color: 'black', alignSelf: 'center', marginRight: 20, fontSize: 15, cursor: 'pointer' }}>
                  {user && user.firstName} {user && user.lastName}
                </p>
              </HeaderGlobalBar>

              {menuPanel ? (
                <HeaderPanel onMouseLeave={() => setMenuPanel(false)} aria-label="" style={{ height: 'fit-content', left: 0, paddingBottom: 10, position: 'absolute' }} expanded>
                  <Switcher>
                    {
                      role.includes('ROLE_EXPERT') ||
                        role.includes('ROLE_CONSTRUCTOR') ||
                        role.includes('ROLE_ADMIN') ?
                        <SwitcherItem href={RoutePath.constructors} >
                          Конструкторы
                        </SwitcherItem>
                        :
                        null
                    }
                    <SwitcherItem href={RoutePath.championship} >
                      Чемпионаты
                    </SwitcherItem>
                    <SwitcherItem href={RoutePath.newChat} >
                      Тренажер
                    </SwitcherItem>
                    <SwitcherItem href={RoutePath.accreditationPage} >
                      Тестирование
                    </SwitcherItem>

                    <SwitcherItem href={RoutePath.methodological} >
                      Методические материалы
                    </SwitcherItem>
                    {role.includes('ROLE_ANALIST') ||
                      role.includes('ROLE_TEACHER') ||
                      role.includes('ROLE_ADMIN') ||
                      role.includes('ROLE_TEST_TRAINING_CHECK') ?
                      <SwitcherItem href={RoutePath.rating} >
                        Рейтинг
                      </SwitcherItem>
                      :
                      null
                    }
                    {role.includes('ROLE_ADMIN') ? (
                      <SwitcherItem href={RoutePath.admin} >
                        Администрирование
                      </SwitcherItem>
                    ) : null}
                  </Switcher>
                </HeaderPanel>
              ) : null}

              {userPanel ? (
                <HeaderPanel onMouseLeave={() => setUserPanel(false)} style={{ height: '90px', position: 'absolute' }} expanded>
                  <Switcher>
                    <SwitcherItem isSelected href={RoutePath.profile}>
                      Профиль
                    </SwitcherItem>
                    <SwitcherItem onClick={logout} >
                      Выход
                    </SwitcherItem>
                  </Switcher>
                </HeaderPanel>
              ) : null}
            </div>
        }
      </Header>
      {/*</Theme>*/}
    </div>
  );
}

// export default MainHeader;

import { useMemo, useEffect, useState } from 'react';
import moment from 'moment';

export const useSortedChampionships = (championships: any, sort: any, data: any) => {
  const [date, setDate] = useState(0);
  useEffect(() => {
    setDate(new Date().getTime());
    const timer = setInterval(() => {
    setDate(new Date().getTime());
  }, 60 * 1000);
  return () => {
    clearInterval(timer);
  }
}, [])
 
    const sortedPost = useMemo(() => {
      if(championships) {
      if (sort === 'Открытые') {
        return [...championships].filter(item => new Date(item.date_stop).getTime() > date && new Date(item.date_start).getTime() > date);
      } else if (sort === 'Закрытые') {
        return [...championships].filter(item => new Date(item.date_start).getTime() < date && new Date(item.date_stop).getTime() < date);
      }
      else if (sort === 'Идут') {
        return [...championships].filter(item => new Date(item.date_start).getTime() <= date && new Date(item.date_stop).getTime() >= date);
      }
      else if (sort === 'Зарегистрирован') {
        return [...championships].filter(item => item.registration === true);
      }
      else if (data) {
        return [...championships].filter(item => moment(item.date_start).format('L') === data);
      }
      return championships;
    }
    }, [sort, championships, data, date]);
  
    return sortedPost;

};

export const useChampionships = (championships: any, sort: any, query: any, data: any) => {
  const keys = ['championship_name', 'championship_descriptoon'];

  const sortedPost = useSortedChampionships(championships, sort, data);

  const searchAndSortedPost = useMemo(() => {
    if(sortedPost) {
      return sortedPost.filter((championship: any) => keys.some(key => championship[key].toLowerCase().includes(query.toLowerCase())));
    }
  }, [keys, query, sortedPost]);

  return searchAndSortedPost;
};

import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function OldMaleM(props) {
  const { nodes, materials, animations } = useGLTF('patient/oldmale_mezomorph_Patient.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions['lying.001'].play()
  }, [actions, names, mixer])



  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.93, 0]} rotation={[0.11, 0, 0]}>
        <group position={[0, 0.13, 0]} rotation={[0.01, 0, 0]}>
          <group position={[0, 0.13, 0]} rotation={[-0.2, 0, 0]}>
            <group position={[0, 0.16, 0]} rotation={[0.12, 0, 0]}>
              <group position={[0, 0.18, 0]} rotation={[0.33, 0, 0]}>
                <group position={[0, 0.03, 0]} rotation={[-0.11, 0, 0]}>
                  <group position={[0, 0.04, 0]} rotation={[-0.26, 0, 0]}>
                    <group rotation={[0.03, 0, 0]}>
                      <group position={[0, 0.13, 0.11]} rotation={[2.65, 0, 0]} />
                      <group position={[0.07, 0.09, 0.02]} rotation={[-0.24, -0.17, -0.23]}>
                        <group position={[0, 0.04, -0.03]} rotation={[-2.96, 0.4, -0.18]} />
                      </group>
                      <group position={[-0.07, 0.09, 0.02]} rotation={[-0.24, 0.17, 0.23]}>
                        <group position={[0, 0.04, -0.03]} rotation={[-2.96, -0.4, 0.18]} />
                      </group>
                      <group position={[0.06, 0.13, 0.09]} rotation={[-1.03, 0.96, 1.99]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.54, -0.02, 0.07]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.23, -0.01, 0.04]} />
                        </group>
                      </group>
                      <group position={[-0.06, 0.13, 0.09]} rotation={[-1.03, -0.96, -1.99]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.54, 0.02, -0.07]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.23, 0.01, -0.04]} />
                        </group>
                      </group>
                      <group position={[0.03, 0.04, 0.1]} rotation={[-1.04, -0.37, -0.76]} />
                      <group position={[0.07, 0.14, 0.07]} rotation={[1.41, -0.35, 0.59]} />
                      <group position={[-0.03, 0.04, 0.1]} rotation={[-1.04, 0.37, 0.76]} />
                      <group position={[-0.07, 0.14, 0.07]} rotation={[1.41, 0.35, -0.59]} />
                      <group position={[0.03, 0.12, 0.08]} rotation={[-1.6, 0, -Math.PI]}>
                        <group position={[-0.02, 0.01, 0]} rotation={[-0.61, -1.11, -1.3]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.49, 0.77, 0.04]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.34, 0.36, -0.48]} />
                          </group>
                        </group>
                        <group position={[0.02, 0.02, 0]} rotation={[0.19, -0.53, 1.3]}>
                          <group position={[0, 0.01, 0]} rotation={[0.35, 0.25, 0.45]}>
                            <group position={[0, 0.01, 0]} rotation={[0.3, 0.17, 0.19]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.03, 0.12, 0.08]} rotation={[-1.6, 0, -Math.PI]}>
                        <group position={[0.02, 0.01, 0]} rotation={[-0.61, 1.11, 1.3]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.49, -0.77, -0.04]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.34, -0.36, 0.48]} />
                          </group>
                        </group>
                        <group position={[-0.02, 0.02, 0]} rotation={[0.19, 0.53, -1.3]}>
                          <group position={[0, 0.01, 0]} rotation={[0.35, -0.25, -0.45]}>
                            <group position={[0, 0.01, 0]} rotation={[0.3, -0.17, -0.19]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0.07, 0.11, 0.06]} rotation={[2.97, -1.05, 0.91]} />
                      <group position={[-0.07, 0.11, 0.06]} rotation={[2.97, 1.05, -0.91]} />
                      <group position={[0, 0.09, 0.03]} rotation={[2.52, 0, 0]}>
                        <group position={[0, 0.08, -0.05]} rotation={[2.16, 0.13, -1.35]} />
                        <group position={[0, 0.09, -0.04]} rotation={[2.29, -0.03, -1.25]} />
                        <group position={[0, 0.11, 0]} rotation={[-1.73, 0, 0]} />
                        <group position={[0, 0.08, -0.05]} rotation={[2.16, -0.13, 1.35]} />
                        <group position={[0, 0.09, -0.04]} rotation={[2.29, 0.03, 1.25]} />
                        <group position={[0.07, 0, 0]} rotation={[0.48, -0.05, 0.2]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.61, -0.1, 0.3]} />
                        </group>
                        <group position={[-0.07, 0, 0]} rotation={[0.48, 0.05, -0.2]}>
                          <group position={[0, 0.04, 0]} rotation={[-0.61, 0.1, -0.3]} />
                        </group>
                        <group position={[0, 0.08, -0.02]} rotation={[2.54, 0, 0]}>
                          <group position={[0, 0.02, 0]} rotation={[-0.4, 0, 0]} />
                        </group>
                      </group>
                      <group position={[0, 0.07, 0.11]} rotation={[-1.6, 0, -Math.PI]}>
                        <group position={[0, 0.03, 0.01]} rotation={[1.07, 0, Math.PI]} />
                      </group>
                      <group position={[0.01, 0.13, 0.11]} rotation={[0.82, -1.25, -2.66]} />
                      <group position={[-0.01, 0.13, 0.11]} rotation={[0.82, 1.25, 2.66]} />
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.03, 0.18, 0.03]} rotation={[-1.65, 0.14, -1.29]}>
                <group position={[-0.01, 0.13, 0]} rotation={[1.51, 1.33, -2.24]}>
                  <group position={[0, 0.31, 0]} rotation={[0.83, 0.15, 0.04]}>
                    <group position={[0, 0.27, 0]} rotation={[-0.01, -0.01, -0.22]}>
                      <group position={[-0.01, 0.03, 0.02]} rotation={[2.87, 1.46, -2.76]}>
                        <group position={[0, 0.07, 0]} rotation={[0.06, -0.22, 0.07]}>
                          <group position={[0, 0.03, 0]} rotation={[0.22, -0.16, -0.02]} />
                        </group>
                        <group position={[-0.01, -0.01, 0.02]} rotation={[-1.32, 0.85, 1.52]}>
                          <group position={[0, 0.03, 0]} rotation={[0.26, -0.1, 0.08]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, 0.01]} rotation={[2.35, 1.5, -2.42]}>
                        <group position={[0, 0.07, 0]} rotation={[0.13, -0.24, 0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.15, -0.28, 0.01]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, -0.01]} rotation={[0.69, 1.5, -0.87]}>
                        <group position={[0, 0.06, 0]} rotation={[0.1, -0.33, -0.05]}>
                          <group position={[0, 0.03, 0]} rotation={[0.17, -0.13, -0.01]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.03, -0.02]} rotation={[0.39, 1.43, -0.66]}>
                        <group position={[0, 0.05, 0]} rotation={[0.1, -0.51, -0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, -0.06, -0.09]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.03, 0.18, 0.03]} rotation={[-1.65, -0.14, 1.29]}>
                <group position={[0.01, 0.13, 0]} rotation={[1.51, -1.33, 2.24]}>
                  <group position={[0, 0.31, 0]} rotation={[0.83, -0.15, -0.04]}>
                    <group position={[0, 0.27, 0]} rotation={[-0.01, 0.01, 0.22]}>
                      <group position={[0.01, 0.03, 0.02]} rotation={[2.87, -1.46, 2.76]}>
                        <group position={[0, 0.07, 0]} rotation={[0.06, 0.22, -0.07]}>
                          <group position={[0, 0.03, 0]} rotation={[0.22, 0.16, 0.02]} />
                        </group>
                        <group position={[0.01, -0.01, 0.02]} rotation={[-1.32, -0.85, -1.52]}>
                          <group position={[0, 0.03, 0]} rotation={[0.26, 0.1, -0.08]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, 0.01]} rotation={[2.35, -1.5, 2.42]}>
                        <group position={[0, 0.07, 0]} rotation={[0.13, 0.24, -0.02]}>
                          <group position={[0, 0.03, 0]} rotation={[0.15, 0.28, -0.01]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, -0.01]} rotation={[0.69, -1.5, 0.87]}>
                        <group position={[0, 0.06, 0]} rotation={[0.1, 0.33, 0.05]}>
                          <group position={[0, 0.03, 0]} rotation={[0.17, 0.13, 0.01]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.03, -0.02]} rotation={[0.39, -1.43, 0.66]}>
                        <group position={[0, 0.05, 0]} rotation={[0.1, 0.51, 0.06]}>
                          <group position={[0, 0.03, 0]} rotation={[0.18, 0.06, 0.09]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.12, -0.02, 0]} rotation={[2.92, 0, -0.1]}>
          <group position={[0, 0.4, 0]} rotation={[0.23, 0.02, 0.02]}>
            <group position={[0, 0.39, 0]} rotation={[-1.02, -0.07, 0.05]} />
          </group>
        </group>
        <group position={[-0.12, -0.02, 0]} rotation={[2.92, 0, 0.1]}>
          <group position={[0, 0.4, 0]} rotation={[0.23, -0.02, -0.02]}>
            <group position={[0, 0.39, 0]} rotation={[-1.02, 0.07, -0.05]} />
          </group>
        </group>
      </group>
      <primitive object={nodes.root} />
      <primitive object={nodes['MCH-torsoparent']} />
      <primitive object={nodes['MCH-hand_ikparentL']} />
      <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
      <primitive object={nodes['MCH-hand_ikparentR']} />
      <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
      <primitive object={nodes['MCH-eye_commonparent']} />
      <primitive object={nodes['MCH-foot_ikparentL']} />
      <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
      <primitive object={nodes['MCH-foot_ikparentR']} />
      <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
      <primitive object={nodes['MCH-lip_armBL001']} />
      <primitive object={nodes['MCH-lip_armBR001']} />
      <primitive object={nodes['MCH-lip_armTL001']} />
      <primitive object={nodes['MCH-lip_armTR001']} />
      <skinnedMesh
        geometry={nodes.old_male_forblend003.geometry}
        material={materials['Eyeblue.001']}
        skeleton={nodes.old_male_forblend003.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend003_1.geometry}
        material={materials['bodyMaterial.001']}
        skeleton={nodes.old_male_forblend003_1.skeleton}
      />
      {/* <skinnedMesh
        geometry={nodes.old_male_forblend003_2.geometry}
        material={materials.eyes_glass}
        skeleton={nodes.old_male_forblend003_2.skeleton}
      /> */}
      <skinnedMesh
        geometry={nodes.old_male_forblend004.geometry}
        material={materials.old_caucasian_male}
        skeleton={nodes.old_male_forblend004.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend004_1.geometry}
        material={materials.FABRIC_1_FRONT_164566}
        skeleton={nodes.old_male_forblend004_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_hairs.geometry}
        material={materials.mhair01black}
        skeleton={nodes.old_male_hairs.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend002_1.geometry}
        material={materials['teethMaterial.001']}
        skeleton={nodes.old_male_forblend002_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.old_male_forblend002_2.geometry}
        material={materials.tongue01Material}
        skeleton={nodes.old_male_forblend002_2.skeleton}
      />
      <group position={[0.38, 0, -0.26]} rotation={[0, Math.PI / 2, 0]}>
        <mesh geometry={nodes.Cube001.geometry} material={materials.couchLeg_black} />
        <mesh geometry={nodes.Cube001_1.geometry} material={materials.couchLeg_white} />
      </group>
    </group>
  )
}


import React from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'
import AuthNavigationPanel from '../../components/auth/AuthNavigationPanel'
import RegistrationForm from '../../components/auth/Forms/RregistrationForm'
import SocialButtonsPanel from '../../components/auth/SocialButtonsPanel'

export default function RegistrationPage() {
  return (
    <AuthLayout>
      <AuthNavigationPanel />
      <RegistrationForm />
      <SocialButtonsPanel />
    </AuthLayout>
  )
}

import React, { useState, useEffect } from 'react';
import GfgInput from './GfgInput';

interface Form14804Props {
  onInputChange: (id: number, value: string) => void;
  initialValues: { [key: number]: string };
  onCheckboxStateChange: (checkboxStates: { [key: number]: boolean }) => void;
  isShow: boolean;
  isReadOnly?: boolean;
  isShowWrongAnswerCheckbox: boolean;
  onCheckboxWrongAnswerStateChange: (checkboxStates: { [key: number]: boolean }) => void;

}

export default function Form14804({ onInputChange, initialValues, onCheckboxStateChange, isShow, isReadOnly, isShowWrongAnswerCheckbox, onCheckboxWrongAnswerStateChange }: Form14804Props) {
  const [stampCode1, setStampCode1] = useState(initialValues?.[1] || '');
  const [medicalOrg1, setMedicalOrg1] = useState(initialValues?.[2] || '');
  const [stampCode2, setStampCode2] = useState(initialValues?.[3] || '');
  const [medicalOrg2, setMedicalOrg2] = useState(initialValues?.[4] || '');
  const [citizenCategoryCode, setCitizenCategoryCode] = useState(initialValues?.[5] || '');
  const [diseaseCode, setDiseaseCode] = useState(initialValues?.[6] || '');
  const [date1, setDate1] = useState(initialValues?.[7] || '');
  const [date2, setDate2] = useState(initialValues?.[8] || '');
  const [patientBirthDay, setPatientBirthDay] = useState(initialValues?.[9] || '');
  const [patientBirthMonth, setPatientBirthMonth] = useState(initialValues?.[10] || '');
  const [patientBirthYear, setPatientBirthYear] = useState(initialValues?.[11] || '');
  const [snils, setSnils] = useState(initialValues?.[12] || '');
  const [insuranceNumber, setInsuranceNumber] = useState(initialValues?.[13] || '');
  const [insuranceCode, setInsuranceCode] = useState(initialValues?.[14] || '');
  const [seriesFirst, setSeriesFirst] = useState(initialValues?.[15] || '');
  const [seriesSecond, setSeriesSecond] = useState(initialValues?.[16] || '');
  const [fIODoctor, setFIODoctor] = useState(initialValues?.[20] || '');
  const [fIOPatient, setFIOPatient] = useState(initialValues?.[18] || '');
  const [cardNumber, setCardNumber] = useState(initialValues?.[19] || '');
  const [letGoDay, setLetGoDay] = useState(initialValues?.[25] || '');
  const [letGoMonth, setLetGoMonth] = useState(initialValues?.[26] || '');
  const [letGoYear, setLetGoYear] = useState(initialValues?.[27] || '');
  const [receipt, setReceipt] = useState(initialValues?.[24] || '');
  const [rp, setRp] = useState(initialValues?.[21] || '');
  const [dtd, setDtd] = useState(initialValues?.[22] || '');
  const [signa, setSigna] = useState(initialValues?.[23] || '');
  const [year, setYear] = useState(initialValues?.[17] || '');
  const [prepared, setPrepared] = useState(initialValues?.[28] || '');
  const [tradename, setTradename] = useState(initialValues?.[29] || '');
  const [quantity, setQuantity] = useState(initialValues?.[30] || '');
  const [checked, setChecked] = useState(initialValues?.[31] || '');
  const [letGo, setLetGo] = useState(initialValues?.[32] || '');
  const [spine, setSpine] = useState(initialValues?.[33] || '');
  const [nameOfTheDrug, setNameOfTheDrug] = useState(initialValues?.[34] || '');
  const [dosage, setDosage] = useState(initialValues?.[35] || '');
  const [directionsForUse, setDirectionsForUse] = useState(initialValues?.[36] || '');
  const [duration, setDuration] = useState(initialValues?.[37] || '');
  const [numberOfAppointmentsPerDay, setNumberOfAppointmentsPerDay] = useState(initialValues?.[38] || '');
  const [forOneAppointment, setForOneAppointment] = useState(initialValues?.[39] || '');

  const [checkboxStates, setCheckboxStates] = useState<{ [key: number]: boolean }>(() => {
    const initialCheckboxState = {};
    for (let i = 1; i <= 39; i++) {
      initialCheckboxState[i] = initialValues?.[i] === 'true';
    }
    return initialCheckboxState;
  });



  useEffect(() => {
    setStampCode1(initialValues?.[1] || '');
    setMedicalOrg1(initialValues?.[2] || '');
    setStampCode2(initialValues?.[3] || '');
    setMedicalOrg2(initialValues?.[4] || '');
    setCitizenCategoryCode(initialValues?.[5] || '');
    setDiseaseCode(initialValues?.[6] || '');
    setDate1(initialValues?.[7] || '');
    setDate2(initialValues?.[8] || '');
    setPatientBirthDay(initialValues?.[9] || '');
    setPatientBirthMonth(initialValues?.[10] || '');
    setPatientBirthYear(initialValues?.[11] || '');
    setSnils(initialValues?.[12] || '');
    setInsuranceNumber(initialValues?.[13] || '');
    setInsuranceCode(initialValues?.[14] || '');
    setSeriesFirst(initialValues?.[15] || '');
    setSeriesFirst(initialValues?.[15] || '');
    setSeriesSecond(initialValues?.[16] || '');
    setFIODoctor(initialValues?.[20] || '');
    setFIOPatient(initialValues?.[18] || '');
    setCardNumber(initialValues?.[19] || '');
    setLetGoDay(initialValues?.[25] || '');
    setLetGoMonth(initialValues?.[26] || '');
    setLetGoYear(initialValues?.[27] || '');
    setReceipt(initialValues?.[24] || '');
    setRp(initialValues?.[21] || '');
    setDtd(initialValues?.[22] || '');
    setSigna(initialValues?.[23] || '');
    setYear(initialValues?.[17] || '');
    setPrepared(initialValues?.[28] || '');
    setTradename(initialValues?.[29] || '');
    setQuantity(initialValues?.[30] || '');
    setChecked(initialValues?.[31] || '');
    setLetGo(initialValues?.[32] || '');
    setSpine(initialValues?.[33] || '');
    setNameOfTheDrug(initialValues?.[34] || '');
    setDosage(initialValues?.[35] || '');
    setDirectionsForUse(initialValues?.[36] || '');
    setDuration(initialValues?.[37] || '');
    setNumberOfAppointmentsPerDay(initialValues?.[38] || '');
    setForOneAppointment(initialValues?.[39] || '');
    setCheckboxStates(() => {
      const initialCheckboxState = {};
      for (let i = 1; i <= 39; i++) {
        initialCheckboxState[i] = initialValues?.[i] === 'true';
      }
      return initialCheckboxState;
    });
  }, [initialValues]);

  function handleInputChange(id: number, value: string) {
    onInputChange(id, value);
    switch (id) {
      case 1:
        setStampCode1(value);
        break;
      case 2:
        setMedicalOrg1(value);
        break;
      case 3:
        setStampCode2(value);
        break;
      case 4:
        setMedicalOrg2(value);
        break;
      case 5:
        setCitizenCategoryCode(value);
        break;
      case 6:
        setDiseaseCode(value);
        break;
      case 7:
        setDate1(value);
        break;
      case 8:
        setDate2(value);
        break;
      case 9:
        setPatientBirthDay(value);
        break;
      case 10:
        setPatientBirthMonth(value);
        break;
      case 11:
        setPatientBirthYear(value);
        break;
      case 12:
        setSnils(value);
        break;
      case 13:
        setInsuranceNumber(value);
        break;
      case 14:
        setInsuranceCode(value);
        break;
      case 15:
        setSeriesFirst(value);
        break;
      case 16:
        setSeriesSecond(value);
        break;
      case 17:
        setYear(value);
        break;
      case 18:
        setFIOPatient(value);
        break;
      case 19:
        setCardNumber(value);
        break;
      case 20:
        setFIODoctor(value);
        break;
      case 21:
        setRp(value);
        break;
      case 22:
        setDtd(value);
        break;
      case 23:
        setSigna(value);
        break;
      case 24:
        setReceipt(value);
        break;
      case 25:
        setLetGoDay(value);
        break;
      case 26:
        setLetGoMonth(value);
        break;
      case 27:
        setLetGoYear(value);
        break;
      case 28:
        setPrepared(value);
        break;
      case 29:
        setTradename(value);
        break;
      case 30:
        setQuantity(value);
        break;
      case 31:
        setChecked(value);
        break;
      case 32:
        setLetGo(value);
        break;
      case 33:
        setSpine(value);
        break;
      case 34:
        setNameOfTheDrug(value);
        break;
      case 35:
        setDosage(value);
        break;
      case 36:
        setDirectionsForUse(value);
        break;
      case 37:
        setDuration(value);
        break;
      case 38:
        setNumberOfAppointmentsPerDay(value);
        break;
      case 39:
        setForOneAppointment(value);
        break;
      default:
        break;
    }
  }

  const handleCheckboxChange = (id: number) => {
    setCheckboxStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };
      onCheckboxStateChange(newState);
      return newState;
    });
  };


  const [checkboxWrongAnswerStates, setCheckboxWrongAnswerStates] = useState<{ [key: number]: boolean }>(() => {
    const initialCheckboxState = {};
    for (let i = 1; i <= 9; i++) {
      initialCheckboxState[i] = false; // Начальное состояние для чекбоксов "неправильных ответов"
    }
    return initialCheckboxState;
  });


  const handleWrongAnswerCheckboxChange = (id: number) => {
    setCheckboxWrongAnswerStates((prev) => {
      const newState = { ...prev, [id]: !prev[id] };
      onCheckboxWrongAnswerStateChange(newState);
      return newState;
    });
  };


  return (
    <div style={{ width: '1200px', margin: '0 auto', paddingBottom: 50, paddingTop: 30 }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
        <span>ФОРМА РЕЦЕПТУРНОГО БЛАНКА N 148-1/У-04(Л)</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <div style={{ width: 250 }}>
          <span>Министерство здравоохранения Российской Федерации</span>
        </div>
        <div style={{ display: 'flex', gap: 20, width: 300 }}>
          <span>МЕСТО ДЛЯ ШТРИХКОДА *</span>
          <span>
            УТВЕРЖДЕНА приказом Министерства здравоохранения Российской Федерации от 24 ноября 2021 г. N 1094н
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>Штамп код</span>
        <GfgInput
          id={1}
          onChange={handleInputChange}
          inputCount={5}
          value={stampCode1}
          disabled={isReadOnly} />
        {isShow && <input
          style={{ width: 25, height: 25, marginLeft: 20 }}
          type="checkbox"
          id="checkbox-1"
          checked={checkboxStates[1] || false}
          onChange={() => handleCheckboxChange(1)}
        />}
        {isShowWrongAnswerCheckbox && (
          <input
            style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
            id="checkbox-1-1"
            type="checkbox"
            checked={checkboxWrongAnswerStates[1] || false}
            onChange={() => handleWrongAnswerCheckboxChange(1)}
          />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: 20 }}>
        <span>Медицинской организации</span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <GfgInput
            id={2}
            onChange={handleInputChange}
            inputCount={15}
            value={medicalOrg1}
            disabled={isReadOnly} />
          {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-2"
            checked={checkboxStates[2] || false}
            onChange={() => handleCheckboxChange(2)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[2] || false}
              onChange={() => handleWrongAnswerCheckboxChange(2)}
            />
          )}
        </div>
      </div>
      {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <span>Штамп код</span>
        <GfgInput
          id={3}
          onChange={handleInputChange}
          inputCount={5}
          value={stampCode2}
        />
        {isShow && <input
          style={{ width: 25, height: 25, marginLeft: 20 }}
          type="checkbox"
          id="checkbox-3"
          checked={checkboxStates[3] || false}
          onChange={() => handleCheckboxChange(3)}
        />}
        {isShowWrongAnswerCheckbox && (
          <input
            style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
            id="checkbox-1-1"
            type="checkbox"
            checked={checkboxWrongAnswerStates[3] || false}
            onChange={() => handleWrongAnswerCheckboxChange(3)}
          />
        )}
      </div> */}
      {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: 20 }}>
        <span>Медицинской организации</span>
        <GfgInput
          id={4}
          onChange={handleInputChange}
          inputCount={15}
          value={medicalOrg2} />
        {isShow && <input
          style={{ width: 25, height: 25, marginLeft: 20 }}
          type="checkbox"
          id="checkbox-4"
          checked={checkboxStates[4] || false}
          onChange={() => handleCheckboxChange(4)}
        />}
        {isShowWrongAnswerCheckbox && (
          <input
            style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
            id="checkbox-1-1"
            type="checkbox"
            checked={checkboxWrongAnswerStates[4] || false}
            onChange={() => handleWrongAnswerCheckboxChange(4)}
          />
        )}
      </div> */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <span>Код формы по ОКУД 3108805</span>
      </div>
      <span>Форма N 148-1/у-04(л)</span>
      <div style={{ display: 'flex', border: '1px solid black' }}>
        <div style={{ borderRight: '1px solid black', padding: 10 }}>
          <div>
            <span>Код категории граждан</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GfgInput
              id={5}
              onChange={handleInputChange}
              height={'26'}
              inputCount={3}
              value={citizenCategoryCode} 
              disabled={isReadOnly}/>
            {isShow &&
              <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-5"
                checked={checkboxStates[5] || false}
                onChange={() => handleCheckboxChange(5)}
              />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[5] || false}
                onChange={() => handleWrongAnswerCheckboxChange(5)}
              />
            )}
          </div>
        </div>
        <div style={{ borderRight: '1px solid black', padding: 10 }}>
          <div>
            <span>Код нозологической формы (по МКБ)</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <GfgInput
              id={6}
              onChange={handleInputChange}
              // height={'70'}
              inputCount={5}
              value={diseaseCode}
              disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-6"
              checked={checkboxStates[6] || false}
              onChange={() => handleCheckboxChange(6)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[6] || false}
                onChange={() => handleWrongAnswerCheckboxChange(6)}
              />
            )}
          </div>
        </div>
        <div style={{ borderRight: '1px solid black', padding: 10 }}>
          <div >
            <span>Код нозологической формы (по МКБ)</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>1. Федеральный бюджет</span>
            <span>2. Бюджет субъекта Российской Федерации</span>
            <span>3. Муниципальный бюджет</span>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }}>
          <span style={{ marginBottom: 20 }}>% оплаты: (подчеркнуть)</span>
          <span>1. Бесплатно</span>
          <span>2. 50%</span>
          <span>3. иной %</span>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '20px auto', gap: 20, justifyContent: 'column' }}>
        <span>РЕЦЕПТ </span>
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          <span>Серия</span>
          <input type="text" style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', width: 40 }} value={seriesFirst}
            onChange={(event) => {
              setSeriesFirst(event.target.value);
              handleInputChange(15, event.target.value);
            }}
          />
          {/* {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-7"
            checked={checkboxStates[7] || false}
            onChange={() => handleCheckboxChange(7)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[7] || false}
              onChange={() => handleWrongAnswerCheckboxChange(7)}
            />
          )} */}
          <span>N</span>
          <input type="text" style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none' }} value={seriesSecond}
            onChange={(event) => {
              setSeriesSecond(event.target.value);
              handleInputChange(16, event.target.value);
            }} 
            disabled={isReadOnly}/>
          {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-8"
            checked={checkboxStates[8] || false}
            onChange={() => handleCheckboxChange(8)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[8] || false}
              onChange={() => handleWrongAnswerCheckboxChange(8)}
            />
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span>Дата оформления:</span>
          <div style={{ marginRight: 20 }}>
            <GfgInput
              id={7}
              onChange={handleInputChange}
              inputCount={2}
              value={date1}
              disabled={isReadOnly} />
          </div>
          {/* {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-9"
            checked={checkboxStates[9] || false}
            onChange={() => handleCheckboxChange(9)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[9] || false}
              onChange={() => handleWrongAnswerCheckboxChange(9)}
            />
          )} */}
          <div>
            <GfgInput
              id={8}
              onChange={handleInputChange}
              inputCount={2}
              value={date2}
              disabled={isReadOnly}
            />
            {/* {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-10"
              checked={checkboxStates[10] || false}
              onChange={() => handleCheckboxChange(10)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[10] || false}
                onChange={() => handleWrongAnswerCheckboxChange(10)}
              />
            )} */}
          </div>
        </div>

        <span>20</span>
        <input style={{ borderLeft: 'none', borderRight: 'none', borderTop: 'none', width: 30 }} value={year}
          onChange={(event) => {
            setYear(event.target.value);
            handleInputChange(17, event.target.value);
          }}
          disabled={isReadOnly}/>
        <span>г.</span>
        {isShow && <input
          style={{ width: 25, height: 25, marginLeft: 20 }}
          type="checkbox"
          id="checkbox-11"
          checked={checkboxStates[11] || false}
          onChange={() => handleCheckboxChange(11)}
        />}
        {isShowWrongAnswerCheckbox && (
          <input
            style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
            id="checkbox-1-1"
            type="checkbox"
            checked={checkboxWrongAnswerStates[11] || false}
            onChange={() => handleWrongAnswerCheckboxChange(11)}
          />
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 25, marginBottom: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: 600, flexDirection: 'row' }}>
            <span>Фамилия, инициалы имени и отчества (последнее - при наличии) пациента
              <input style={{ width: 500, borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                type={'text'} value={fIOPatient}
                onChange={(event) => {
                  setFIOPatient(event.target.value);
                  handleInputChange(18, event.target.value);
                }}
                disabled={isReadOnly}
              />
              {isShow && <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-12"
                checked={checkboxStates[12] || false}
                onChange={() => handleCheckboxChange(12)}
              />}
              {isShowWrongAnswerCheckbox && (
                <input
                  style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                  id="checkbox-1-1"
                  type="checkbox"
                  checked={checkboxWrongAnswerStates[12] || false}
                  onChange={() => handleWrongAnswerCheckboxChange(12)}
                />
              )}
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', width: 600, flexDirection: 'row' }}>
            <span> Дата рождения</span>
            <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
              <GfgInput
                id={9}
                onChange={handleInputChange}
                inputCount={2}
                value={patientBirthDay} 
                disabled={isReadOnly}/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GfgInput
                id={10}
                onChange={handleInputChange}
                inputCount={2}
                value={patientBirthMonth} 
                disabled={isReadOnly}/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GfgInput
                id={11}
                onChange={handleInputChange}
                inputCount={4} value={patientBirthYear} 
                disabled={isReadOnly}/>
            </div>
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-15"
              checked={checkboxStates[15] || false}
              onChange={() => handleCheckboxChange(15)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[15] || false}
                onChange={() => handleWrongAnswerCheckboxChange(15)}
              />
            )}
          </div>
        </div>

      </div>
      <div style={{ display: 'flex', flexDirection: 'column', }}>
        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid black', padding: 10 }}>
          <span>СНИЛС</span>
          <GfgInput id={12} onChange={handleInputChange} inputCount={20} value={snils} disabled={isReadOnly}/>
          {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-16"
            checked={checkboxStates[16] || false}
            onChange={() => handleCheckboxChange(16)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[16] || false}
              onChange={() => handleWrongAnswerCheckboxChange(16)}
            />
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid black', padding: 10 }}>
          <span style={{ width: 200 }}>N полиса обязательного медицинского</span>
          <GfgInput id={13} onChange={handleInputChange} inputCount={25} value={insuranceNumber} disabled={isReadOnly}/>
          {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-17"
            checked={checkboxStates[17] || false}
            onChange={() => handleCheckboxChange(17)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[17] || false}
              onChange={() => handleWrongAnswerCheckboxChange(17)}
            />
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid black', padding: 10 }}>
          <span>страхования: </span>
          <GfgInput id={14} onChange={handleInputChange} inputCount={25} value={insuranceCode} disabled={isReadOnly} />
          {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-18"
            checked={checkboxStates[18] || false}
            onChange={() => handleCheckboxChange(18)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[18] || false}
              onChange={() => handleWrongAnswerCheckboxChange(18)}
            />
          )}
        </div>
      </div>
      <div style={{ padding: 20 }}>
        <span>Номер медицинской карты пациента, получающего медицинскую помощь в амбулаторных условиях
        </span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input style={{ width: '100%', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
            value={cardNumber} onChange={(event) => {
              setCardNumber(event.target.value);
              handleInputChange(19, event.target.value);
            }} type={'text'} disabled={isReadOnly} />
          {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-19"
            checked={checkboxStates[19] || false}
            onChange={() => handleCheckboxChange(19)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[19] || false}
              onChange={() => handleWrongAnswerCheckboxChange(19)}
            />
          )}
        </div>

      </div>
      <div style={{ padding: 20 }}>
        <span>Фамилия, инициалы имени и отчества (последнее - при наличии) лечащего врача (фельдшера, акушерки)</span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input style={{ width: '100%', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
            value={fIODoctor}
            onChange={(event) => {
              setFIODoctor(event.target.value);
              handleInputChange(20, event.target.value);
            }}
            type={'text'} disabled={isReadOnly} />
          {isShow && <input
            style={{ width: 25, height: 25, marginLeft: 20 }}
            type="checkbox"
            id="checkbox-20"
            checked={checkboxStates[20] || false}
            onChange={() => handleCheckboxChange(20)}
          />}
          {isShowWrongAnswerCheckbox && (
            <input
              style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[20] || false}
              onChange={() => handleWrongAnswerCheckboxChange(20)}
            />
          )}
        </div>
      </div>
      <div style={{ display: 'flex', gap: 45, padding: 20, width: '100%', marginBottom: 40 }}>
        <span>Руб.</span>
        <span>Коп.</span>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%' }}>
          <span>RP:
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: '1px dotted black', width: '90%' }}
              value={rp}
              onChange={(event) => {
                setRp(event.target.value);
                handleInputChange(21, event.target.value);
              }} disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-21"
              checked={checkboxStates[21] || false}
              onChange={() => handleCheckboxChange(21)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[21] || false}
                onChange={() => handleWrongAnswerCheckboxChange(21)}
              />
            )}
          </span>
          <span>D.t.d.
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: '1px dotted black', width: '90%' }}
              value={dtd}
              onChange={(event) => {
                setDtd(event.target.value);
                handleInputChange(22, event.target.value);
              }} disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-22"
              checked={checkboxStates[22] || false}
              onChange={() => handleCheckboxChange(22)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[22] || false}
                onChange={() => handleWrongAnswerCheckboxChange(22)}
              />
            )}
          </span>
          <span>Signa:
            <input
              style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: '1px dotted black', width: '90%' }}
              value={signa}
              onChange={(event) => {
                setSigna(event.target.value);
                handleInputChange(23, event.target.value);
              }} disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-23"
              checked={checkboxStates[23] || false}
              onChange={() => handleCheckboxChange(23)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[23] || false}
                onChange={() => handleWrongAnswerCheckboxChange(23)}
              />
            )}
          </span>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid black', marginBottom: 35 }}></div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 40 }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <span>Подпись и печать лечащего врача</span>
          <span> (подпись фельдшера, акушерки)</span>
        </div>
        <span style={{ width: '50%' }}>М.П.</span>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 40 }}>
        <span >Рецепт действителен в течение 15 дней, 30 дней, 90 дней (нужное подчеркнуть)</span>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 10 }}>
        <span >------------------- (Заполняется специалистом аптечной организации) -------------------</span>
      </div>

      <div style={{ display: 'flex', width: '80%', margin: '0 auto', border: '1px solid black' }}>
        <div style={{ borderRight: '1px solid black', width: '100%' }}>
          <div style={{ borderBottom: '1px solid black', padding: 10 }}>
            <span>Отпущено по рецепту:</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={receipt} onChange={(event) => {
                setReceipt(event.target.value);
                handleInputChange(24, event.target.value);
              }}
              type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-24"
              checked={checkboxStates[24] || false}
              onChange={() => handleCheckboxChange(24)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[24] || false}
                onChange={() => handleWrongAnswerCheckboxChange(24)}
              />
            )}
          </div>
          <div style={{ borderBottom: '1px solid black', padding: 10, display: 'flex', flexDirection: 'row' }}>
            <span>Дата отпуска:</span>
            <span>"</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={letGoDay} onChange={(event) => {
                setLetGoDay(event.target.value);
                handleInputChange(25, event.target.value);
              }} type="text"  disabled={isReadOnly} />
            {/* {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-25"
              checked={checkboxStates[25] || false}
              onChange={() => handleCheckboxChange(25)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[25] || false}
                onChange={() => handleWrongAnswerCheckboxChange(25)}
              />
            )} */}
            <span>"</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none', width: 100 }}
              value={letGoMonth} onChange={(event) => {
                setLetGoMonth(event.target.value);
                handleInputChange(26, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {/* {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-26"
              checked={checkboxStates[26] || false}
              onChange={() => handleCheckboxChange(26)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[26] || false}
                onChange={() => handleWrongAnswerCheckboxChange(26)}
              />
            )} */}
            <span>20</span>
            <span>г.</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none', width: 30 }}
              value={letGoYear} onChange={(event) => {
                setLetGoYear(event.target.value);
                handleInputChange(27, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-27"
              checked={checkboxStates[27] || false}
              onChange={() => handleCheckboxChange(27)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[27] || false}
                onChange={() => handleWrongAnswerCheckboxChange(27)}
              />
            )}
          </div>
          <div style={{ padding: 10 }}>
            <span>Подготовил: </span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={prepared} onChange={(event) => {
                setPrepared(event.target.value);
                handleInputChange(28, event.target.value);
              }} type="text"  disabled={isReadOnly}/>
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-28"
              checked={checkboxStates[28] || false}
              onChange={() => handleCheckboxChange(28)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[28] || false}
                onChange={() => handleWrongAnswerCheckboxChange(28)}
              />
            )}
          </div>
        </div>
        <div style={{ width: '100%', padding: 10 }}>
            <span>Торговое наименование и дозировка:</span>
          <div style={{ borderBottom: '1px solid black', padding: 10, display: 'flex', flexDirection: 'row' }}>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={tradename} onChange={(event) => {
                setTradename(event.target.value);
                handleInputChange(29, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-29"
              checked={checkboxStates[29] || false}
              onChange={() => handleCheckboxChange(29)}
            />} {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[29] || false}
                onChange={() => handleWrongAnswerCheckboxChange(29)}
              />
            )}
          </div>
          <div style={{ borderBottom: '1px solid black', padding: '10px 0'}}>
            <span>Количество:</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={quantity} onChange={(event) => {
                setQuantity(event.target.value);
                handleInputChange(30, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-30"
              checked={checkboxStates[30] || false}
              onChange={() => handleCheckboxChange(30)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[30] || false}
                onChange={() => handleWrongAnswerCheckboxChange(30)}
              />
            )}
          </div>
          <div>
            <span>Проверил:</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={checked} onChange={(event) => {
                setChecked(event.target.value);
                handleInputChange(31, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-31"
              checked={checkboxStates[31] || false}
              onChange={() => handleCheckboxChange(31)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[31] || false}
                onChange={() => handleWrongAnswerCheckboxChange(31)}
              />
            )}
          </div>
          <div>
            <span>Отпустил:</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={letGo} onChange={(event) => {
                setLetGo(event.target.value);
                handleInputChange(32, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-32"
              checked={checkboxStates[32] || false}
              onChange={() => handleCheckboxChange(32)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[32] || false}
                onChange={() => handleWrongAnswerCheckboxChange(32)}
              />
            )}
          </div>

        </div>
      </div>
      <span style={{ display: 'flex', justifyContent: 'center', margin: '25px auto', width: '100%' }}>--------------------------------------- (линия отрыва) ---------------------------------------</span>
      <div style={{ display: 'flex', width: '80%', margin: '0 auto', border: '1px solid black' }}>
        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid black', padding: 10, width: '100%' }}>
          <div>
            <span>Корешок рецептурного бланка:</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={spine} onChange={(event) => {
                setSpine(event.target.value);
                handleInputChange(33, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-33"
              checked={checkboxStates[33] || false}
              onChange={() => handleCheckboxChange(33)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[33] || false}
                onChange={() => handleWrongAnswerCheckboxChange(33)}
              />
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', }}>
            <span>Наименование лекарственного препарата</span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
                value={nameOfTheDrug} onChange={(event) => {
                  setNameOfTheDrug(event.target.value);
                  handleInputChange(34, event.target.value);
                }} type="text" disabled={isReadOnly} />
              {isShow && <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-34"
                checked={checkboxStates[34] || false}
                onChange={() => handleCheckboxChange(34)}
              />}
              {isShowWrongAnswerCheckbox && (
                <input
                  style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                  id="checkbox-1-1"
                  type="checkbox"
                  checked={checkboxWrongAnswerStates[34] || false}
                  onChange={() => handleWrongAnswerCheckboxChange(34)}
                />
              )}
            </div>
            <span>Дозировка:</span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
                value={dosage} onChange={(event) => {
                  setDosage(event.target.value);
                  handleInputChange(35, event.target.value);
                }} type="text" disabled={isReadOnly} />
              {isShow && <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-35"
                checked={checkboxStates[35] || false}
                onChange={() => handleCheckboxChange(35)}
              />}
              {isShowWrongAnswerCheckbox && (
                <input
                  style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                  id="checkbox-1-1"
                  type="checkbox"
                  checked={checkboxWrongAnswerStates[35] || false}
                  onChange={() => handleWrongAnswerCheckboxChange(35)}
                />
              )}
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', padding: 10, width: '100%' }}>
          <div>
            <span>Способ применения:</span>
            <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
              value={directionsForUse} onChange={(event) => {
                setDirectionsForUse(event.target.value);
                handleInputChange(36, event.target.value);
              }} type="text" disabled={isReadOnly} />
            {isShow && <input
              style={{ width: 25, height: 25, marginLeft: 20 }}
              type="checkbox"
              id="checkbox-36"
              checked={checkboxStates[36] || false}
              onChange={() => handleCheckboxChange(36)}
            />}
            {isShowWrongAnswerCheckbox && (
              <input
                style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                id="checkbox-1-1"
                type="checkbox"
                checked={checkboxWrongAnswerStates[36] || false}
                onChange={() => handleWrongAnswerCheckboxChange(36)}
              />
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', }}>
            <div>
              <span>Продолжительность:</span>
              <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
                value={duration} onChange={(event) => {
                  setDuration(event.target.value);
                  handleInputChange(37, event.target.value);
                }} type="text" disabled={isReadOnly} />
              <span>дней</span>
              {isShow && <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-37"
                checked={checkboxStates[37] || false}
                onChange={() => handleCheckboxChange(37)}
              />}
              {isShowWrongAnswerCheckbox && (
                <input
                  style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                  id="checkbox-1-1"
                  type="checkbox"
                  checked={checkboxWrongAnswerStates[37] || false}
                  onChange={() => handleWrongAnswerCheckboxChange(37)}
                />
              )}
            </div>
            <div>
              <span>Количество приемов в день:</span>
              <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
                value={numberOfAppointmentsPerDay} onChange={(event) => {
                  setNumberOfAppointmentsPerDay(event.target.value);
                  handleInputChange(38, event.target.value);
                }} type="text" disabled={isReadOnly} />
              <span>раз</span>
              {isShow && <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-38"
                checked={checkboxStates[38] || false}
                onChange={() => handleCheckboxChange(38)}
              />}
              {isShowWrongAnswerCheckbox && (
                <input
                  style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                  id="checkbox-1-1"
                  type="checkbox"
                  checked={checkboxWrongAnswerStates[38] || false}
                  onChange={() => handleWrongAnswerCheckboxChange(38)}
                />
              )}
            </div>
            <div>
              <span>На 1 прием:</span>
              <input style={{ borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}
                value={forOneAppointment} onChange={(event) => {
                  setForOneAppointment(event.target.value);
                  handleInputChange(39, event.target.value);
                }} type="text"  disabled={isReadOnly} />
              <span>ед</span>
              {isShow && <input
                style={{ width: 25, height: 25, marginLeft: 20 }}
                type="checkbox"
                id="checkbox-39"
                checked={checkboxStates[39] || false}
                onChange={() => handleCheckboxChange(39)}
              />}
              {isShowWrongAnswerCheckbox && (
                <input
                  style={{ marginLeft: 20, width: 25, height: 25, border: '1px solid blue', backgroundColor: 'red' }}
                  id="checkbox-1-1"
                  type="checkbox"
                  checked={checkboxWrongAnswerStates[39] || false}
                  onChange={() => handleWrongAnswerCheckboxChange(39)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function ManWhiteShirt(props) {
  const { nodes, materials, animations } = useGLTF('./patient/PACIENT_A_15102024_ANIM.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)

  useEffect(() => {
    props.setAnim(animations)
    actions['rigAction.001'].play()
  }, [actions, names, mixer])


  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.89, -0.03]} rotation={[0.28, 0, 0]}>
        <group position={[0, 0.12, 0]} rotation={[-0.15, 0, 0]}>
          <group position={[0, 0.12, 0]} rotation={[-0.16, 0, 0]}>
            <group position={[0, 0.15, 0]}>
              <group position={[0, 0.17, 0]} rotation={[0.41, 0, 0]}>
                <group position={[0, 0.06, 0]} rotation={[0.01, -0.01, 0]}>
                  <group position={[0, 0.04, 0]} rotation={[-0.41, 0.02, 0.01]}>
                    <group rotation={[-0.02, -0.01, -0.01]}>
                      <group position={[0, 0.1, 0.11]} rotation={[2.82, -0.02, 0]}>
                        <group position={[0, 0.03, 0]} rotation={[-0.15, 0.08, -0.01]}>
                          <group position={[0, 0.02, 0]} rotation={[1.7, 0.02, 0.09]}>
                            <group position={[0, 0.01, 0]} rotation={[0.33, -0.01, -0.02]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.11]} rotation={[-1.51, 0.13, -1.19]} />
                      <group position={[0, 0.02, 0.11]} rotation={[-1.45, 0.01, -1.15]} />
                      <group position={[0, -0.02, 0.05]} rotation={[1.46, 0, 0]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.76, 0, 0]} />
                      </group>
                      <group position={[0.07, 0.08, 0.02]} rotation={[-0.43, -0.01, -0.35]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.43, -0.04, -0.4]}>
                          <group position={[0, 0.01, 0]} rotation={[-1.11, -0.53, 0.05]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.7, -0.6, 0.21]} />
                          </group>
                        </group>
                      </group>
                      <group position={[-0.07, 0.08, 0.02]} rotation={[-0.43, 0.01, 0.34]}>
                        <group position={[0, 0.03, 0]} rotation={[-1.43, 0.04, 0.4]}>
                          <group position={[0, 0.01, 0]} rotation={[-1.11, 0.53, -0.05]}>
                            <group position={[0, 0.03, 0]} rotation={[-0.7, 0.6, -0.21]} />
                          </group>
                        </group>
                      </group>
                      <group position={[0, 0.03, 0.11]} rotation={[-1.51, -0.13, 1.19]} />
                      <group position={[0, 0.02, 0.11]} rotation={[-1.45, -0.01, 1.15]} />
                      <group position={[0.06, 0.11, 0.09]} rotation={[-0.99, 0.92, 2.06]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.64, 0.71, 0.21]}>
                          <group position={[0, 0.01, 0]} rotation={[0.03, 0.43, -0.43]} />
                        </group>
                      </group>
                      <group position={[0.05, 0.09, 0.09]} rotation={[-0.84, 1.06, 1.88]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.35, 0.84, -0.2]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.34, 0.28, -0.32]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.15, 0.6, -0.64]}>
                              <group position={[0, 0.01, 0]} rotation={[1, 0.14, -2.4]}>
                                <group position={[0, 0.01, 0]} rotation={[0.46, 0.17, 0.05]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.36, 0.17, 0.42]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.06, 0.11, 0.09]} rotation={[-0.99, -0.92, -2.07]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.64, -0.71, -0.21]}>
                          <group position={[0, 0.01, 0]} rotation={[0.03, -0.43, 0.43]} />
                        </group>
                      </group>
                      <group position={[-0.05, 0.09, 0.09]} rotation={[-0.85, -1.06, -1.89]}>
                        <group position={[0, 0.01, 0]} rotation={[-0.35, -0.84, 0.2]}>
                          <group position={[0, 0.01, 0]} rotation={[-0.34, -0.28, 0.32]}>
                            <group position={[0, 0.01, 0]} rotation={[-0.15, -0.6, 0.64]}>
                              <group position={[0, 0.01, 0]} rotation={[1, -0.14, 2.4]}>
                                <group position={[0, 0.01, 0]} rotation={[0.46, -0.17, -0.05]}>
                                  <group position={[0, 0.01, 0]} rotation={[0.36, -0.17, -0.42]} />
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.01, 0.16, 0.1]} rotation={[-2.07, 1.46, -1.31]}>
                        <group position={[-0.01, 0, 0.03]} rotation={[0, -0.27, 0]}>
                          <group position={[-0.02, 0, 0.02]} rotation={[0.3, -0.42, 0.27]}>
                            <group position={[-0.02, 0.03, 0.02]} rotation={[0.04, -0.78, 0.16]}>
                              <group position={[0, 0.05, 0]} rotation={[-0.02, -0.27, 0.26]}>
                                <group position={[0, 0.05, 0]} rotation={[-0.19, -0.62, 0.62]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.85, -0.14, 1.27]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.59, -0.16, -0.4]}>
                                      <group position={[0, 0.05, 0]} rotation={[0.77, 0.5, 0.09]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.4, 0.32, 0.22]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.64, 0.55, 2.55]}>
                                            <group position={[0, 0.04, 0]} rotation={[0.22, 0.82, -0.87]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[-0.01, 0.16, 0.1]} rotation={[-2.11, -1.45, 1.27]}>
                        <group position={[0.01, 0, 0.03]} rotation={[0, 0.27, 0]}>
                          <group position={[0.02, 0, 0.02]} rotation={[0.3, 0.42, -0.27]}>
                            <group position={[0.02, 0.03, 0.02]} rotation={[0.04, 0.78, -0.16]}>
                              <group position={[0, 0.05, 0]} rotation={[-0.02, 0.27, -0.26]}>
                                <group position={[0, 0.05, 0]} rotation={[-0.19, 0.62, -0.62]}>
                                  <group position={[0, 0.07, 0]} rotation={[-1.85, 0.14, -1.27]}>
                                    <group position={[0, 0.04, 0]} rotation={[-1.59, 0.16, 0.4]}>
                                      <group position={[0, 0.05, 0]} rotation={[0.77, -0.5, -0.09]}>
                                        <group position={[0, 0.05, 0]} rotation={[0.4, -0.32, -0.22]}>
                                          <group position={[0, 0.03, 0]} rotation={[-1.64, -0.55, -2.55]}>
                                            <group position={[0, 0.04, 0]} rotation={[0.22, -0.82, 0.87]} />
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group position={[0.06, 0.09, 0.06]} rotation={[3.06, -1.02, 0.91]}>
                        <group position={[0, 0.05, 0]} rotation={[2.95, -0.58, 1.65]}>
                          <group position={[0, 0.04, 0]} rotation={[2.57, 0.79, 1.34]} />
                        </group>
                      </group>
                      <group position={[-0.06, 0.09, 0.06]} rotation={[3.06, 1.03, -0.91]}>
                        <group position={[0, 0.05, 0]} rotation={[2.95, 0.58, -1.65]}>
                          <group position={[0, 0.04, 0]} rotation={[2.57, -0.79, -1.34]} />
                        </group>
                      </group>
                      <group position={[0, 0.02, 0.08]} rotation={[-1.31, 0, 0]}>
                        <group position={[0, 0.02, 0]} rotation={[-0.37, 0, 0]} />
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[0.06, 0.18, 0.04]} rotation={[-1.62, 0.15, -1.3]}>
                <group position={[0, 0.15, -0.02]} rotation={[1.62, 1.38, -2.42]}>
                  <group position={[0, 0.25, 0]} rotation={[0.83, 0.22, 0.11]}>
                    <group position={[0, 0.27, 0]} rotation={[0.03, -0.05, -0.16]}>
                      <group position={[0, 0.04, 0.03]} rotation={[1.74, 1.46, -1.66]}>
                        <group position={[0, 0.06, 0]} rotation={[0.1, -0.18, 0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.19, -0.13, 0.04]} />
                        </group>
                        <group position={[-0.01, -0.01, 0.02]} rotation={[-0.81, 1.05, 1.35]}>
                          <group position={[0, 0.04, 0]} rotation={[0.15, 0.2, -0.08]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, 0.01]} rotation={[1.68, 1.44, -1.74]}>
                        <group position={[0, 0.06, 0]} rotation={[0.13, -0.3, -0.02]}>
                          <group position={[0, 0.04, 0]} rotation={[0.09, -0.24, -0.07]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, -0.01]} rotation={[1.6, 1.38, -1.69]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.01, -0.51, -0.14]}>
                          <group position={[0, 0.04, 0]} rotation={[0.13, -0.13, -0.09]} />
                        </group>
                      </group>
                      <group position={[-0.01, 0.04, -0.03]} rotation={[1.46, 1.25, -1.71]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.11, -0.79, -0.1]}>
                          <group position={[0, 0.03, 0]} rotation={[0.17, -0.03, -0.15]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
              <group position={[-0.06, 0.18, 0.04]} rotation={[-1.62, -0.15, 1.3]}>
                <group position={[0, 0.15, -0.02]} rotation={[1.62, -1.38, 2.42]}>
                  <group position={[0, 0.25, 0]} rotation={[0.82, -0.24, -0.12]}>
                    <group position={[0, 0.27, 0]} rotation={[0.04, 0.07, 0.17]}>
                      <group position={[0, 0.04, 0.03]} rotation={[1.74, -1.46, 1.66]}>
                        <group position={[0, 0.06, 0]} rotation={[0.1, 0.18, -0.03]}>
                          <group position={[0, 0.03, 0]} rotation={[0.19, 0.13, -0.04]} />
                        </group>
                        <group position={[0.01, -0.01, 0.02]} rotation={[-0.81, -1.05, -1.35]}>
                          <group position={[0, 0.04, 0]} rotation={[0.15, -0.2, 0.08]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, 0.01]} rotation={[1.68, -1.44, 1.74]}>
                        <group position={[0, 0.06, 0]} rotation={[0.13, 0.3, 0.02]}>
                          <group position={[0, 0.04, 0]} rotation={[0.09, 0.24, 0.07]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, -0.01]} rotation={[1.6, -1.38, 1.69]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.01, 0.51, 0.14]}>
                          <group position={[0, 0.04, 0]} rotation={[0.13, 0.13, 0.09]} />
                        </group>
                      </group>
                      <group position={[0.01, 0.04, -0.03]} rotation={[1.46, -1.25, 1.71]}>
                        <group position={[0, 0.05, 0]} rotation={[-0.11, 0.79, 0.1]}>
                          <group position={[0, 0.03, 0]} rotation={[0.17, 0.03, 0.15]} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
        <group position={[0.12, -0.01, 0.04]} rotation={[2.78, 0, -0.12]}>
          <group position={[0, 0.4, 0]} rotation={[0.19, 0.02, 0.02]}>
            <group position={[0, 0.38, 0]} rotation={[-1.1, -0.08, 0.04]} />
          </group>
        </group>
        <group position={[-0.12, -0.01, 0.04]} rotation={[2.77, 0.01, 0.11]}>
          <group position={[0, 0.4, 0]} rotation={[0.19, -0.03, -0.01]}>
            <group position={[0, 0.38, 0]} rotation={[-1.1, 0.08, -0.04]} />
          </group>
        </group>
      </group>
      <group name="rig">
        <primitive object={nodes.root} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
        <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
        <primitive object={nodes['MCH-torsoparent']} />
        <primitive object={nodes['MCH-hand_ikparentL']} />
        <primitive object={nodes['MCH-hand_ikparentR']} />
        <primitive object={nodes['MCH-foot_ikparentL']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
        <primitive object={nodes['MCH-foot_ikparentR']} />
        <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
        <skinnedMesh
          geometry={nodes.human.geometry}
          material={materials['bodyMaterial.001']}
          skeleton={nodes.human.skeleton}
        />
        <skinnedMesh
          geometry={nodes.PACIENT_A_1.geometry}
          material={materials.Eye_brown}
          skeleton={nodes.PACIENT_A_1.skeleton}
        />
        {/* <skinnedMesh
          geometry={nodes.PACIENT_A_2.geometry}
          material={materials.eyes_glass}
          skeleton={nodes.PACIENT_A_2.skeleton}
        /> */}
        <skinnedMesh
          geometry={nodes.PACIENT_A001.geometry}
          material={materials.middleage_cauasian_male}
          skeleton={nodes.PACIENT_A001.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pacient_A_1cloth001.geometry}
          material={materials.PACIENT_A_CLOTH}
          skeleton={nodes.pacient_A_1cloth001.skeleton}
        />
      </group>
    </group>
  )
}

// useGLTF.preload('/PACIENT_A_15102024_ANIM.glb')

import {
  Checkbox,
  Dialog,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import ArrowDownwardIcon from '@material-ui/icons/Close'
import React from 'react'
import { TooltipState } from '../../hooks/useTooltipState'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      border: `solid 1px ${theme.palette.divider}`
    },
    upline: {
      textAlign: 'right'
    },
    title: {
      textTransform: 'uppercase',
      fontSize: 20,
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    content: {
      fontSize: 14,
      padding: theme.spacing(10),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2)
      },
      color: theme.palette.text.secondary,
      '& span': {
        color: theme.palette.primary.main
      }
      // '& ul': {
      //   fontSize: 14,
      //   listStyle: 'square outside',
      // },
    },

    checkbox: {
      textAlign: 'center',
      color: theme.palette.text.hint,
      paddingBottom: theme.spacing(7)
    },
    checkboxIcon: {
      fontSize: 14,
      color: theme.palette.text.hint
    },
    checkboxIconChecked: { fontSize: 14 },
    label: {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.text.hint
    },
    margin: {
      margin: 0
    }
  })
)

export default function TooltipDialog({
  open,
  onChangeSkip,
  onClose,
  valueSkip,
  children
}: TooltipState) {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.card}>
        <div className={classes.upline}>
          <IconButton
            aria-label='delete'
            className={classes.margin}
            onClick={onClose}
          >
            <ArrowDownwardIcon fontSize='inherit' />
          </IconButton>
        </div>

        <DialogContentText className={classes.content}>
          {children}
        </DialogContentText>

        <div className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={valueSkip}
                onChange={(_, checked) => {
                  onChangeSkip?.(checked)
                }}
                icon={
                  <CheckBoxOutlineBlankIcon className={classes.checkboxIcon} />
                }
                checkedIcon={
                  <CheckBoxIcon className={classes.checkboxIconChecked} />
                }
                name='checkedI'
              />
            }
            label={
              <Typography className={classes.label}>
                Больше не показывать
              </Typography>
            }
          />
        </div>
      </div>
    </Dialog>
  )
}

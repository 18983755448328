import React, { useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function FatMan(props) {

  const { nodes, materials, animations } = useGLTF('./patient/oldmale_endomorph_travmatologya2.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)


  useEffect(() => {
    props.setAnim(animations)
    actions['1_stand'].play()
  }, [actions, names, mixer])
  

  return (
    <group ref={ref} {...props} dispose={null}>
      <primitive object={nodes.root} />
      <primitive object={nodes['MCH-torsoparent']} />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend003.geometry}
        material={materials['Eye_brown.001']}
        skeleton={nodes.man_endomorph_forblend003.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend003_1.geometry}
        material={materials['bodyMaterial.002']}
        skeleton={nodes.man_endomorph_forblend003_1.skeleton}
      />
      {/* <skinnedMesh
        geometry={nodes.man_endomorph_forblend003_2.geometry}
        material={materials.eyes_glass}
        skeleton={nodes.man_endomorph_forblend003_2.skeleton}
      /> */}
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend004.geometry}
        material={materials.old_asian_female}
        skeleton={nodes.man_endomorph_forblend004.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend004_1.geometry}
        material={materials.FABRIC_1_FRONT_164566}
        skeleton={nodes.man_endomorph_forblend004_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend.geometry}
        material={materials['teethMaterial.002']}
        skeleton={nodes.man_endomorph_forblend.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_forblend_1.geometry}
        material={materials.tongue}
        skeleton={nodes.man_endomorph_forblend_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.man_endomorph_hairs.geometry}
        material={materials.short02}
        skeleton={nodes.man_endomorph_hairs.skeleton}
      />
    </group>
  )
}


import React, { useEffect, useState } from 'react';
import chatApi from '../../../api/chatApi';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { TrashCan, Edit, Add } from '@carbon/icons-react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  mainWrapper: {
    width: '50%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0',
  },
  btnIcon: {
    width: 20,
    height: 20,
    marginLeft: 15,
    cursor: 'pointer'
  },
  cellInput: {
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    resize: 'none',
  },
}));

const ListRole = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [roles, setRoles] = useState([]);
  const [description, setDescription] = useState('');
  const [id, setId] = useState(null);
  const [role, setRole] = useState('');
  const [add, setAdd] = useState(true);


  const handleChange = role => (event, isExpanded) => {
    setExpanded(isExpanded ? role : false);
  };


  const getRoles = async () => {
    await chatApi
      .listRoles()
      .then(res => setRoles(res.result))
      .catch(err => console.log(err));
  };

  const getDescription = async (role) => {
    await chatApi.descriptionRole(role)
      .then(res => {
        setDescription(res.result.description)
        setId(res.result.id)
        setAdd(true)
      })
      .catch(err => {
        setAdd(false)
        setDescription('')
      }
      )
    // .finally(() => setLoading(false))
  }


  const addDescription = async () => {
    await chatApi.addDescription(role, description)
      .then(res => {
        getDescription(role)
      })
      .catch(err => console.log(err))
  }

  const updateDes = async () => {
    await chatApi.updateDescription(id, role, description)
      .then(res => {
        getDescription(role)
      })
      .catch(err => console.log(err))
  }

  const deleteDes = async () => {
    await chatApi.deleteDescription(id)
      .then(res => {
        getDescription(role)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    setAdd(false)
    setDescription('')
    if (role.length > 1) {
      getDescription(role)
    }
  }, [role]);

  return (
    <div className={classes.root}>
      <div className={classes.mainWrapper}>
        <div className={classes.title}>Список ролей</div>
        <div style={{ marginTop: 50 }}>
          {roles.map(el =>
            <Accordion onClick={() => setRole(el.name)} style={{ margin: 20 }} expanded={expanded === el.name} onChange={handleChange(el.name)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography className={classes.heading}>{el.name}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ justifyContent: 'space-between' }}>
                <div style={{ width: '100%' }}>
                  <textarea
                    // ref={refText}
                    type="text"
                    className={classes.cellInput}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  // onBlur={() => saveData()}
                  />
                </div>

                <div style={{ display: 'flex' }}>
                  {!add &&
                    <Add onClick={() => addDescription()} className={classes.btnIcon} color="primary" />
                  }
                  {add &&
                    <>
                      <Edit
                        onClick={() => updateDes()} className={classes.btnIcon} />
                      <TrashCan
                        onClick={() => deleteDes()} className={classes.btnIcon} />
                    </>
                  }
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </div>

    </div>
  );
};

export default ListRole;

import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@material-ui/core'
import { deepOrange } from '@material-ui/core/colors'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import BuildIcon from '@material-ui/icons/Build'
import React, { ReactElement } from 'react'
import useLogout from '../../../hooks/useLogout'
import { useHistory } from 'react-router'
import { RoutePath } from '../../../types'
import chatApi from '../../../api/chatApi'
import { useQuery } from 'react-query'
import jwt from 'jsonwebtoken'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      //   background: 'yellow',
      minWidth: '100%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      color: '#000',
      // top: theme.spacing(6),
      // right: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderTopWidth: 0,
      zIndex: 99
    },

    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[400]
    },

    listItem: {
      cursor: 'pointer'
    },

    userAvatar: {
      cursor: 'default'
    }
  })
)

// interface Props {}

export default function UserBar(): ReactElement {
  const classes = useStyles()
  const history = useHistory()

  const roleDecode = jwt.decode(localStorage.getItem('role'))
  const role = roleDecode.auth.split(',')

  const handleClickConstructors = () => {
    history.push(`${RoutePath.constructors}`)
  }

  const handleClickAdmin = () => {
    history.push(`${RoutePath.admin}`)
  }

  const handleClickRating = () => {
    history.push(`${RoutePath.rating}`)
  }

  const handleClickCognitive = () => {
    history.push(`${RoutePath.Cognitive}`)
  }

  const logout = useLogout()

  const userInfo = useQuery('userInfo', () => chatApi.getUserInfo())

  if (userInfo.isLoading) {
    return (
      <div className={classes.root}>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary='Загрузка...' />
          </ListItem>
        </List>
      </div>
    )
  } else {
    !userInfo.data?.result
      ? logout()
      : console.log('userInfo', userInfo.data?.result)
  }

  if (userInfo.status === 'error') {
    logout()
  }

  return (
    <div className={classes.root}>
      <List>
        {/* onClick={handleClickRating} */}
        <ListItem className={classes.userAvatar}>
          <ListItemAvatar>
            {userInfo.data?.result?.photo ? (
              <Avatar
                alt={
                  userInfo.data?.result.name +
                  ' ' +
                  userInfo.data?.result?.surname
                }
                src={userInfo.data?.result.photo}
              />
            ) : (
              <Avatar className={classes.orange}>
                {userInfo.data?.result?.surname?.charAt(0)}
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              userInfo.isLoading
                ? ''
                : `${userInfo.data?.result?.surname} ${userInfo.data?.result?.name}`
            }
            // secondary="Студент, 3й курс"
          />
        </ListItem>
      </List>
      <List component='nav'>
        <Divider />
        {/* <ListItem button>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary="Конструкторы" onClick={handleClickConstructors} />
        </ListItem> */}
        <ListItem button>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary='Выйти' onClick={logout} />
        </ListItem>
      </List>
    </div>
  )
}

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import chatApi from '../../api/chatApi'

const CognitiveHistoryTable = () => {
  moment.locale('ru')

  const history = useQuery(
    'history',
    async () => {
      const data = await chatApi.cognitiveHistory()
      return data.result
    },
    { refetchOnMount: true }
  )

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Баллы</TableCell>
            </TableRow>
          </TableHead>
          {history?.data?.map((el, i) => (
            <TableRow>
              <TableCell>{moment(el.testComplete).format('LLL')}</TableCell>
              <TableCell>{el.totalCorrect}</TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </div>
  )
}

export default CognitiveHistoryTable

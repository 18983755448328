import React from 'react'
import MainHeader from "../../components/MainHeader/MainHeader";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MainPageComponent from './components/MainPageComponent';
import jwt from 'jsonwebtoken';
import { RoutePath } from '../../types';
import MainFooter from '../../components/MainFooter/MainFooter';
import ArrowRightTop from '../../components/arrowIcons/ArrowRightTop';
import mainPageImg from '../../assets/img/mainPage/mainPageHeader.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    mainPage: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100%',

      // width: '1443px',
      // maxWidth: 1443,
      background: '#FFFFFF',
      color: '#000000',
      margin: '0 auto'
    },
    link: {
      background: '#000000',
      textDecoration: 'none',
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      marginBottom: 24,
      [theme.breakpoints.down('xs')]: { fontSize: 14, margin: 14 }
    },
    img: {
      width: 1308,
      height: 1118,
      position: 'absolute',
      top: '-180px',
      right: 0,
      [theme.breakpoints.down('xs')]: { fontSize: 14, marginRight: 15 }
    },
    text: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 32,
      [theme.breakpoints.down('xs')]: { fontSize: 25 }
    },
    mainTitleBlock: {
      display: 'flex',
      // flexDirection: 'column',
      width: '100%',
      maxWidth: 1443,
      height: '100%',
      color: '#000000'
    },
    mainTitle: {
      fontSize: '70px',
      fontWeight: 500,
      fontFamily: 'Helvetica Neue',
      fontStyle: 'medium',
      width: 550,
      marginBottom: '32px',
      lineHeight: '73px',
      color: '#FFFFFF',
      '@media (max-width: 800px)': {
        fontSize: 42,
        width: '400px',
      },
    },
    mainDescription: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'Helvetica Neue',
      color: '#000000',
      width: '458px',
      marginBottom: 32,
      '@media (max-width: 800px)': {
        fontSize: 15,
        width: '350px',
      },
    },
    mainPagePictureContainer: {

    },
    mainPageElement: {
      backgroundImage: `url(${mainPageImg})`,
      backgroundRepeat: 'no-repeat',
      height: 'auto',
      width: '100%',
      '@media (max-width: 860px)': {
        display: 'none'
      }
    },
    firstBlock: {
      display: 'flex',
      width: '100%',
      '@media (max-width: 800px)': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      },
      '&:nth-child(0)': {
        background: 'blue'
      }
    },

    headerLinksElement: {
      // width: '50%',
      width: '100%',
      cursor: 'pointer',
      '&:first-child': {
        background: '#001D6C',
        color: '#FFFFFF',
      },
      '&:nth-child(2)': {
        background: '#0043CE',
        color: '#FFFFFF',
      },
    },
    horizontalElementTextBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '35px 30px',
      width: 300,
      height: '100%',
    },
    horizontalElementDescription: {
      fontSize: 14,
      fontWeight: 400,
      color: '#FFFFFF',
      fontFamily: 'Helvetica Neue',
      lineHeight: '16px',
      letterSpacing: '0.05em'
    },
    horizontalElementTitle: {
      fontSize: 32,
      fontWeight: 400,
      color: '#FFFFFF',
      fontFamily: 'Helvetica Neue',
      lineHeight: '40px'
    },
  })
)

export default function MainPage() {
  const style = useStyles()

  const routeToAdministration = () => {
    window.history.pushState({}, ``, RoutePath.testRoot);
    return window.location.replace(RoutePath.admin)
  }
  const routeToConstructor = () => {
    window.history.pushState({}, ``, RoutePath.testRoot);
    return window.location.replace(RoutePath.constructors)
  }

  const routeToTrainers = () => {
    window.history.pushState({}, ``, RoutePath.testRoot);
    return window.location.replace(RoutePath.newChat)
  }
  const routeToAccreditation = () => {
    window.history.pushState({}, ``, RoutePath.testRoot);
    return window.location.replace(RoutePath.accreditationPage)
  }

  const roleDecode = jwt.decode(localStorage.getItem('role'))
  const role = roleDecode.auth.split(',')
  console.log(role)

  const routeRating = () => {
    window.history.pushState({}, ``, RoutePath.testRoot);
    return window.location.replace(RoutePath.rating)
  }

  const routeChamp = () => {
    window.history.pushState({}, ``, RoutePath.testRoot);
    return window.location.replace(RoutePath.championship)
  }

  const routeMetodical = () => {
    window.history.pushState({}, ``, RoutePath.testRoot);
    return window.location.replace(RoutePath.methodological)
  }

  return (
    <>
      <div>
        <div className={style.mainPage}>
          <MainHeader />
          <div className={style.mainTitleBlock}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 25px', background: '#0F62FE', color: '#FFFFFF', height: '529px' }}>
                <p className={style.mainTitle}>Мы учим ставить точный диагноз и общаться с пациентами</p>
              </div>
              <div className={style.firstBlock} >
                <div className={style.headerLinksElement} onClick={routeToTrainers}>
                  <div className={style.horizontalElementTextBlock}>
                    <div style={{ marginBottom: '24px' }}>
                      {/* <p className={style.horizontalElementDescription}>Самостоятельная работа</p> */}
                      <p className={style.horizontalElementTitle} style={{ marginBottom: 24 }}>Тренажер</p>
                      <p className={style.horizontalElementDescription}>Выбирай область медицины и тренируйся в постановке диагноза</p>
                    </div>

                    <div>
                      <ArrowRightTop color={'white'} />
                    </div>
                  </div>
                </div>

                <div className={style.headerLinksElement} onClick={routeChamp}>
                  <div className={style.horizontalElementTextBlock}>
                    <div style={{ marginBottom: '24px' }}>
                      {/* <p className={style.horizontalElementDescription}>Коммьюнити</p> */}
                      <p className={style.horizontalElementTitle} style={{ marginBottom: 24 }}>Чемпионаты</p>
                      <p className={style.horizontalElementDescription}>Соревнуйся с другими студентами и получай призы</p>
                    </div>
                    <div>
                      <ArrowRightTop color={'white'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.mainPageElement}></div>
          </div>
          <MainPageComponent
            role={role}
            routeToAdministration={routeToAdministration}
            routeToConstructor={routeToConstructor}
            routeToTrainers={routeToTrainers}
            routeMetodical={routeMetodical}
            routeRating={routeRating}
            routeChamp={routeChamp}
            routeToAccreditation={routeToAccreditation}
          />
          <MainFooter />
        </div>

      </div>
    </>
  )
}


import 'date-fns';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { checkRole } from '../../common/checkRole';
import ruLocale from 'date-fns/locale/ru';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import SearchBar from 'material-ui-search-bar';
import moment from 'moment';
import 'moment/locale/ru';
import chatApi from '../../api/chatApi';
import { TablePaginationActions } from './CreateRole/options/TablePagination';
import { useDebounce } from 'react-use';

const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'center',
    fontWeight: 600,
  },
  item: {
    display: 'flex',
  },
  cellText: {
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0',
  },
  buttonSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellInput: {
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
});

export default function TableAdmin() {
  const classes = useRowStyles();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [searched, setSearched] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [text, setText] = useState('')

  const [data, setData] = useState([]);


  const datesTable = async (from, to) => {
    chatApi
      .dataTable(from, to)
      .then(res => setData(res.result))
      .catch(e => console.log(e));
  };
  useEffect(() => {
    datesTable(selectedDate, selectedDate1);
  }, [selectedDate, selectedDate1]);


  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleDateChange1 = date => {
    setSelectedDate1(date);
    return date
  };

  useEffect(() => {
    checkRole();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useDebounce(
    async () => {
      const keys = ['whoName', 'whoSurname', 'whoPatronymic']

      if (!text) {
        return datesTable(selectedDate, selectedDate1);
      }
      await chatApi
        .dataTable(selectedDate, selectedDate1)
        .then(res => {
          const sort = res.result.map(el => ({ ...el, toggle: el.action === 1 ? true : false }));
          const newFilter = sort.filter((row) => keys.some(key => {
            if (row[key] !== null) {
              return row[key].toLowerCase().includes(text.toLowerCase())
            }
          }));
          setData(newFilter);
        })
        .catch(err => console.log(err));
    },
    700,
    [text]
  )


  return (
    <div style={{ marginBottom: 450 }}>
      <div style={{ margin: '0' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: 20,
          fontWeight: 500,
          margin: '20px 0 10px'
        }}>
          Просмотр истории действий пользователей
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <Grid style={{ marginTop: 10 }} container justifyContent="center" alignItems="baseline">
            <div style={{ margin: '0 10px' }}>С</div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              placeholder="01/01/2023"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <div style={{ margin: '0 10px' }}>по</div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              placeholder="01/01/2023"
              value={selectedDate1}
              onChange={handleDateChange1}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ alignSelf: 'flex-end', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <SearchBar value={searched} placeholder='Поиск' onChange={searchVal => setText(searchVal)} style={{ width: 300 }} />
            </div>
            {data &&
              <TableContainer component={Paper} style={{ marginTop: 10 }}>
                <Table aria-label="collapsible table">
                  <TableHead style={{ background: '#E7F0FE' }}>
                    <TableRow>
                      <TableCell className={classes.titleTable}>Дата</TableCell>
                      <TableCell className={classes.titleTable} align="right">
                        Действие
                      </TableCell>
                      <TableCell className={classes.titleTable} align="right">
                        Пользователь изменил
                      </TableCell>
                      <TableCell className={classes.titleTable}>Пользователя изменили</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map(
                      (row, index) => (
                        <TableRow className={classes.bodyTable} key={index}>
                          <TableCell align="center" component="th" scope="row">
                            {moment(row.whenMake).format('LLL')}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.whatMake === 'create' ? 'Создал' : null}
                            {row.whatMake === 'delete' ? 'Удалил' : null}
                            {row.whatMake === 'update' ? 'Изменил' : null}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {`${row.whoSurname ? row.whoSurname : ''} ${row.whoName ? row.whoName : ''} ${row.whoPatronymic ? row.whoPatronymic : ''}`}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {`${row.whomSurname ? row.whomSurname : ''} ${row.whomName ? row.whomName : ''} ${row.whomPatronymic ? row.whomPatronymic : ''}`}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                  <TableFooter>
                    <TablePagination
                      style={{ background: '#E7F0FE' }}
                      rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableFooter>
                </Table>
              </TableContainer>
            }
          </div>
        </>
        {/* <TableMy from={selectedDate} to={selectedDate1} /> */}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import chatApi from "../../api/chatApi";
import { TrashCan, Search } from '@carbon/icons-react';
//@ts-ignore
import { ComboBox, Button, ContainedList, ContainedListItem } from '@carbon/react';
import { useHistory } from 'react-router-dom';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    title1: {
      fontFamily: 'Helvetica Neue',
      fontWeight: 400,
      fontSize: 20,
      color: '#000000',
      margin: '24px 0',
    },
    wrapper: {
      width: '49%',
      padding: 20,
      boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 0px',
      minHeight: '20em',
      marginBottom: 20,
      '@media (max-width: 1525px)': {
        width: '98%',
      }
    },
    list: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    link: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    selectBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    comboBox: {
      width: '60%',
    },
    addBtn: {
      marginLeft: 20,
      borderRadius: 4
    }
  })
)

interface Teacher {
  id: number
  fio: string
}

const Action = ({ name,
  data,
  title,
  id,
  myTrn,
  myOrg,
  mySp,
  myTeacher,
  switch1,
  error, success }) => {
  const classes = useRowStyles()

  const [dataOrg, setDataOrg] = useState<any>(null)
  const [dataTrn, setDataTrn] = useState<any>(null)
  const [dataSpec, setDataSpec] = useState<any>(null)
  const [dataTeacher, setDataTeacher] = useState<any>(null)

  function changeUserHandler1(value) {
    setDataSpec(value)
  }

  function changeUserHandler2(value) {
    setDataOrg(value)
  }

  function changeUserHandler3(value) {
    setDataTrn(value)
  }

  function changeUserHandler4(value) {
    setDataTeacher(value)
  }

  const clickOrg = () => {
    switch1(true)
    success(false)
    error(false)
    chatApi.requestOrg(id, dataOrg.id, 1)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }

  const clickTrn = () => {
    switch1(true)
    success(false)
    error(false)
    chatApi.requestTrn(id, dataTrn.id, 1)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }


  const clickSpec = () => {
    switch1(true)
    success(false)
    error(false)
    chatApi.createSpeciality(dataSpec.id, id)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }

  const clickTeacher = () => {
    switch1(true)
    success(false)
    error(false)
    chatApi.createTeacher(dataTeacher.id, dataTeacher.userId)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }

  /////////////////////////Удаление

  const deleteOrg = async (id: number, orgId: number) => {
    switch1(true)
    await chatApi.requestOrg(id, orgId, -1)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }

  const deleteTrn = async (id: number, trnId: number) => {
    switch1(true)
    await chatApi.requestTrn(id, trnId, -1)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }

  const deleteTeacher = async (id: number) => {
    switch1(true)
    await chatApi.deleteTeacher(id)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }

  const deleteSp = async (id: number) => {
    switch1(true)
    await chatApi.deleteSpeciality(id)
      .then((res) => {
        success(true)
        error(false)
        switch1(false)
      })
      .catch((err) => {
        error(true)
        success(false)
        switch1(false)
        console.log(err)
      })
  }

  const history = useHistory()
  const [teacherAll, setTeacherAll] = useState([])

  useEffect(() => {
    if (name === 'teacher' && data) {
      const datas = data?.map(el => ({
        ...el,
        fio: el?.fio?.replaceAll('null', '')
      }))
      setTeacherAll(datas)
    }
  }, [name, data]);


  return (
    <div className={classes.wrapper}>
      <div className={classes.title1}>{title}</div>
      <div>
        {name === 'spec' &&
          <div>
            <div className={classes.selectBtn}>
              <ComboBox
                className={classes.comboBox}
                placeholder={'Выберите специализацию'}
                items={data ? data : []}
                itemToString={(item) => item ? item.value : ''}
                onChange={({ selectedItem }) => changeUserHandler1(selectedItem)}
              />
              <Button
                disabled={dataSpec !== null ? false : true}
                onClick={clickSpec}
                className={classes.addBtn}>
                Добавить
              </Button>
            </div>
            {name === 'spec' &&
              mySp &&
              mySp.length > 0 &&
              <div style={{ marginTop: 15 }} >
                <ContainedList style={{ marginTop: 15 }} label="Ваши специализации" kind="on-page" action={''}>
                  {mySp.map((el, index) => {
                    return (
                      <div key={index} className={classes.list}>
                        <ContainedListItem>{index + 1}.{el.value}</ContainedListItem>
                        <TrashCan style={{ cursor: 'pointer' }} onClick={(e) => deleteSp(el.id)} />
                      </div>
                    )
                  })}
                </ContainedList>
              </div>
            }
          </div>
        }
        {name === 'trn' &&
          <div>
            <div className={classes.selectBtn} >
              <ComboBox
                className={classes.comboBox}
                placeholder={'Выберите тренажер'}
                items={data ? data : []}
                itemToString={(item) => item ? item.name : ''}
                onChange={({ selectedItem }) => changeUserHandler3(selectedItem)}
              />
              <Button
                disabled={dataTrn !== null ? false : true}
                onClick={clickTrn}
                className={classes.addBtn}>
                Добавить
              </Button>
            </div>
            {name === 'trn' &&
              myTrn &&
              myTrn.length > 0 &&
              <div style={{ marginTop: 15 }} >
                <ContainedList style={{ marginTop: 15 }} label="Ваши тренажеры" kind="on-page" action={''}>
                  {myTrn.map((el, index) => {
                    return (
                      <div key={index} className={classes.list}>
                        <ContainedListItem>{index + 1}.{el.trainer}</ContainedListItem>
                        <TrashCan style={{ cursor: 'pointer' }} onClick={(e) => deleteTrn(el.userId, el.trainerId)} />
                      </div>
                    )
                  })}
                </ContainedList>
              </div>
            }
          </div>
        }
        {name === 'org' &&
          <div className={classes.selectBtn}>
            <ComboBox
              className={classes.comboBox}
              placeholder={'Выберите организацию'}
              items={data ? data : []}
              itemToString={(item) => item ? item.name : ''}
              onChange={({ selectedItem }) => changeUserHandler2(selectedItem)}
            />
            <Button
              disabled={dataOrg !== null ? false : true}
              onClick={clickOrg}
              className={classes.addBtn}
            >
              Добавить
            </Button>
            <div className={classes.link}>
              <Button
                kind='ghost'
                disabled={dataOrg ? false : true}
                renderIcon={Search}
                iconDescription="Подробнее"
                hasIconOnly
                onClick={() => history.push(`/orgDescriptionPage?id=${dataOrg.id}`)}
              />
            </div>
          </div>
        }
        {name === 'teacher' &&
          <>
            <div className={classes.selectBtn}>
              <ComboBox
                className={classes.comboBox}
                placeholder={'Выберите преподавателя'}
                items={teacherAll ? teacherAll : []}
                itemToString={(item) => item ? item.fio : ''}
                onChange={({ selectedItem }) => changeUserHandler4(selectedItem)}
              />
              <Button
                disabled={dataTeacher !== null ? false : true}
                onClick={clickTeacher}
                className={classes.addBtn}>
                Добавить
              </Button>
              <div className={classes.link}>
                <Button
                  disabled={dataTeacher ? false : true}
                  kind='ghost'
                  renderIcon={Search}
                  iconDescription="Подробнее"
                  hasIconOnly
                  onClick={() => history.push(`/orgTeachersPage?id=${dataTeacher.organizationId}`)}
                />
              </div>
            </div>
            {name === 'teacher' &&
              teacherAll &&
              teacherAll.length > 0 &&
              <div style={{ marginTop: 15 }} >
                <ContainedList style={{ marginTop: 15 }} label="Ваши преподаватели" kind="on-page" action={''}>
                  {teacherAll.map((el: Teacher, index) => {
                    return (
                      <div key={index} className={classes.list}>

                        <ContainedListItem>{index + 1}.{el.fio}</ContainedListItem>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            kind='ghost'
                            renderIcon={Search}
                            iconDescription="Подробнее"
                            hasIconOnly
                            onClick={() => history.push(`/orgTeachersPage?id=${el.id}`)}
                          />
                          <TrashCan style={{ cursor: 'pointer' }} onClick={(e) => deleteTeacher(el.id)} />
                        </div>
                      </div>
                    )
                  })}
                </ContainedList>
              </div>
            }
          </>
        }
        <div>
          {name === 'org' &&
            myOrg &&
            myOrg.length > 0 &&
            <div style={{ marginTop: 15 }} >
              <ContainedList label="Ваши организации" kind="on-page" action={''}>
                {myOrg.map((el, index: number) => {
                  return (
                    <div key={index} className={classes.list}>
                      <ContainedListItem>{index + 1}.{el.organization}</ContainedListItem>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          kind='ghost'
                          renderIcon={Search}
                          iconDescription="Подробнее"
                          hasIconOnly
                          onClick={() => history.push(`/orgDescriptionPage?id=${el.organizationId}`)}
                        />
                        <TrashCan style={{ cursor: 'pointer' }} onClick={(e) => deleteOrg(el.userId, el.organizationId)} />
                      </div>
                    </div>
                  )
                })}
              </ContainedList>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Action;
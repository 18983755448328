import { createStyles, Link, makeStyles, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import React, { ReactElement } from 'react'
import { iResItem } from '../../../pages/demo/demo-diagnosis/DiagnosisSuccessPage'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center'
      // paddingTop: theme.spacing(0.5),
      // paddingBottom: theme.spacing(0.5),
    },
    iconContainer: {
      minWidth: 20,
      paddingTop: 5
    },
    title: {
      flex: 1,
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2)
    },
    correct: { color: theme.palette.success.main },
    wrong: { color: theme.palette.error.main },
    points: { fontWeight: 400 }
  })
)

interface Props {
  item: iResItem
}

export default function ResultItem({ item }: Props): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>
        {item.success ? <CorrectIconComp /> : <WrongIconComp />}
      </div>
      <div className={classes.title}>
        <Typography variant='h5'>
          {item.title}
          {item.quantity !== undefined && <span>, {item.quantity} шт. </span>}
          {item.quantity !== undefined && item.quantity > 0 && item.link && (
            <Link href='#' onClick={item.link}>
              Исправить
            </Link>
          )}
        </Typography>
      </div>
      <div className={item.success ? classes.correct : classes.wrong}>
        <Typography variant='h5' className={classes.points}>
          {item.points}
        </Typography>
      </div>
    </div>
  )
}

export function CorrectIconComp(): ReactElement {
  const classes = useStyles()
  return <CheckIcon fontSize='default' className={classes.correct} />
}
export function WrongIconComp(): ReactElement {
  const classes = useStyles()
  return <CloseIcon fontSize='default' className={classes.wrong} />
}

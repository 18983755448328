import jwt from 'jsonwebtoken';

export const checkRole = () => {
  const roleDecode = jwt.decode(localStorage.getItem('role'));
  const role = roleDecode.auth.split(',');
  console.log(role);
  if (role.indexOf('ROLE_ADMIN') === -1 && role.indexOf('ROLE_ADMIN_TRAINER') === -1 && role.indexOf('ROLE_ADMIN_ORG') === -1) {
    window.location.href = '/newChat';
  }
};

import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/Layout/MainLayout';
import NewChatWizardTopMenu from '../startChatWizard/NewChatWizardTopMenu';
import chatApi from '../../api/chatApi';
import SlotContainer from '../../components/common/Slots/SlotContainer';
import TableSelectResearch from '../../components/PageBlocks/Research/Lab/LabDialog/TableSelectResearch';
import { getTestingstageTaskOnTask } from '../../api/chatApi/chatApi';
import Slot from '../../components/common/Slots/Slot';
import ButtonWithProgress from '../../components/common/ButtonWithProgress';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoutePath } from '../../types'


export default function LastAnalysesPage() {
  const [analyses, setAnalyses] = useState<[]>();
  const [specialist, setSpecialist] = useState<[]>();
  const dispatch = useDispatch();
  const [stages, setStages] = useState([]);
  const [spec, setSpec] = useState<any>([])

  const location = useLocation();
  const history = useHistory();

  const getSpec = async () => {
    try {
      const res = await chatApi.getAllSpecialists()
      setSpec(res.result);
    } catch (error) {
      console.log('Ошибка getAllSpecialists', error)
    }
  }

  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
    getSpec();
  }, []);

  async function getTaskProceedPastResearch() {
    try {
      const result = await chatApi.getTaskProceedPastResearch()
      setAnalyses(result.result)
    } catch (error) {
      console.log('Ошибка createRheumatologyProceedState', error)
    }
  }


  async function getTaskProceedPastSpecialist() {
    try {
      const result = await chatApi.getTaskProceedPastSpecialist()
      setSpecialist(result.result)

    } catch (error) {
      console.log('Ошибка createRheumatologyProceedState', error)
    }
  }

  useEffect(() => {
    getTaskProceedPastResearch()
    getTaskProceedPastSpecialist()
  }, [])

  // Функция для перехода на следующий этап
  async function goToTheNextStage() {
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);
    if (!currentStageObj) return;

    setHistoryStage(currentStageObj.activeStage)
    const currentActiveStage = currentStageObj.activeStage;

    const nextStageObj = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage)[0];

    if (nextStageObj && nextStageObj.legend_id) {
      history.push(RoutePath.legend);
    } else if (nextStageObj) {
      history.push(nextStageObj.routePath);
    }
  }

  async function setHistoryStage(testingStage) {
    try {
      await chatApi.setTestingStageHistory(testingStage)
    } catch (error) {
      console.log('Ошибка setHistoryStage', error)
    }
  }

  console.log('analyses', analyses)
  console.log('specialist', specialist)
  return (
    <MainLayout
      desktopHeader={<NewChatWizardTopMenu title={'Прошлые исследования и консультации специалистов'} />}
      title="Прошлые исследования и консультации специалистов"
    >
      <SlotContainer>
        {analyses && analyses.length > 0 ? (
          (() => {
            const groupedAnalyses = analyses.reduce((acc: any, current: any) => {
              const groupName = current.groupName || 'Без группы';
              if (!acc[groupName]) {
                acc[groupName] = [];
              }
              acc[groupName].push(current);
              return acc;
            }, {});

            return Object.entries(groupedAnalyses).map(([groupName, analyses]: [string, any[]]) => {
              const processedAnalyses = analyses.map((it: any) => {
                let json: any | null = null;

                if (it.response.value.indexOf('}') !== -1) {
                  const replacedText = it.response.value.replace(/\\\\n/g, '\\n').replace(/<\/?br>/g, '\\n').replace(/«/g, '"');
                  try {
                    json = JSON.parse(replacedText, (key, value) => {
                      if (typeof value === 'string') {
                        return value.trim();
                      }
                      return value;
                    });
                  } catch (error) {
                    console.error(`Ошибка при парсинге JSON: ${error.message}`);
                  }
                }

                if (json === null) {
                  return {
                    name: it.typeName || '',
                    value: it.response.value || '',
                    unit: '',
                    reference: '',
                  };
                }

                return {
                  name: json.name || '',
                  value: json.value || '',
                  unit: json.unit || '',
                  reference: json.reference || '',
                };
              });

              return (
                <Slot key={groupName} title={groupName} color='primary'>
                  <TableSelectResearch rows={processedAnalyses} />
                </Slot>
              );
            });
          })()
        ) : (
          <div>Прошлых анализов и специалистов не найдено</div>
        )}
        {specialist && specialist.length > 0 ? (
          <Slot title='Прошлые специалисты' color='primary'>
            {specialist.map((el, itemIndex) => {
              const specialistName = spec && spec.length > 0
                ? spec.find(specName => specName.id === el.anamnesisId)?.name
                : '';

              return (
                <div key={itemIndex} style={{ padding: '10px 31px' }}>
                  <div style={{ fontSize: '0.875rem', fontWeight: 600, fontFamily: 'inherit' }}>
                    {specialistName}
                    <div style={{ fontSize: '0.875rem', fontWeight: 400, fontFamily: 'inherit' }}>
                      {el.value ? el.value.split('/n').map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          <br />
                        </React.Fragment>
                      )) : 'Нет описания'}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slot>
        ) : null}

      </SlotContainer>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0px 0px 20px 0px',
          paddingBottom: "30px"
        }}
      >
        <ButtonWithProgress
          style={{}}
          variant='contained'
          color='primary'
          onClick={() => (goToTheNextStage())}
        >
          Следующий этап
        </ButtonWithProgress>
      </div>
    </MainLayout>
  )

}
import React, { ReactElement } from 'react'
import IndicatorCircular from './IndicatorCircular'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.spacing(8.5),
    top: theme.spacing(10),
    right: theme.spacing(2),
    zIndex: 100
  }
}))

interface Props {
  progress: number
  errors: number
}

export default function MobileIndicators({
  progress,
  errors
}: Props): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <IndicatorCircular value={progress} type='progress' />
      <IndicatorCircular value={errors} type='errors' />
    </div>
  )
}

import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Tooltip
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { FunctionComponent, useEffect, useState, ChangeEvent } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import chatApi from '../../../api/chatApi'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme =>
  createStyles({
    mkbField: {
      '& .MuiOutlinedInput-multiline': {
        padding: '10px 14px'
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 12px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)'
      }
    }
  })
)

enum DiagnosisFieldPart {
  diagnosis = 1,
  diagnosisCode = 2
}
export interface DiagnosisFieldData {
  diagnosis?: string
  diagnosisCode?: string
  code?: string
  name?: string
  key: number
  id?: any[]
}

type Props = {
  maxLines?: number
  diagnosis: Array<DiagnosisFieldData>
  readOnly?: boolean
  onChange?: (fields: Array<DiagnosisFieldData>) => void
  onClickInput: (id: number | undefined) => void
  idInput: number | undefined
  currentInputId: number | undefined,
  setDiagnosis: any,
  items: any,
  level: number | null,
}

const CompetingDiagnosis: FunctionComponent<Props> = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState<Array<DiagnosisFieldData>>([
    ...props.diagnosis,
    ...(props?.readOnly ? [] : [{ key: new Date().getTime() }])
  ])
  const [inputValue, setInputValue] = React.useState('')
  const [diagnosisList, setDiagnosisList] = React.useState<any[]>([])
  const [total, setTotal] = React.useState(0)
  const [maxPage, setMaxPage] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [searchText, setSearchText] = React.useState('')
  const [searchCode, setSearchCode] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const size = 50
  const [currTaskState, setCurrTaskState] = useState<number>()
  const [inputDiagnosisArr, setInputDiagnosisArr] = useState<Array<{ diagnosis: string; mkbValue: string }>>([]);
  const [inputDiagnosisArrStrings, setInputDiagnosisArrStrings] = useState<string[]>([]);

  const [pendingDiagnosis, setPendingDiagnosis] = useState<DiagnosisFieldData | null>(null);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [diagnosisCodeValue, setDiagnosisCodeValue] = useState('');

  const handleDiagnosisCodeChange = (value: string) => {
    setDiagnosisCodeValue(value);
    setIsDisabledBtn(prevState => !prevState);
  };


  const easyLevel = async () => {
    try {
      const req = await chatApi.getDiagnosisOptionsVariantes(
        'IL5',
        '',
        '',
        currentPage,
        size
      )
      const sort = req?.result.results.sort(function (a: any, b: any) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setDiagnosisList(sort)
    } catch {
      console.error();
    }
  }

  useEffect(() => {
    if (props.level === 2) {
      easyLevel()
    }
  }, [props.level])


  const openModal = () => {
    if (props.level === 2) {
      setOpen(!open)
    }
    if (props.level === 3) {
      setOpen(false)
    }
  }

  const onChange = (node: any, index: number, type: DiagnosisFieldPart) => {
    const text = node?.target?.value
    if (text.length && props.level === 1) {
      const func = async () => {
        setSearchText(text)
        const req = await chatApi.getDiagnosisOptionsVariantes(
          'IL5',
          String(text),
          String(searchCode),
          currentPage,
          size
        )
        setDiagnosisList(req?.result.results || [])
        setTotal(req?.result.total)
        setMaxPage(req?.result.maxPages)
        if (props.level === 3) {
          props.onChange && props.onChange(fields);
        }
      }
      func()
    }

    if (props?.readOnly === true) return
    let newFields = [...fields]

    const originalItem = fields[index]
    const newItem = Object.assign(
      originalItem,
      type === DiagnosisFieldPart.diagnosis
        ? { diagnosis: text }
        : { diagnosisCode: text }
    )

    newFields.splice(index, 1, newItem)

    if (newFields.length > 1) {
      newFields = newFields.filter(it => !!it.diagnosis || !!it.diagnosisCode)
    }
    props.onChange &&
      props.onChange(newFields.filter(it => it.diagnosis && it.diagnosisCode))
    setFields(newFields)
    if (props.level !== 3) {
      setOpen(true)
    }
  }

  const onChangeMkb = (node: any, index: number, type: DiagnosisFieldPart) => {
    const searchCode = node?.target?.value
    if (searchCode && searchCode.length && props.level === 1) {
      const func = async () => {
        setSearchCode(searchCode)
        const req = await chatApi.getDiagnosisOptionsVariantes(
          'IL4',
          '',
          String(searchCode),
          currentPage,
          size
        )

        setDiagnosisList(req?.result.results || [])
        setTotal(req?.result.total)
        setMaxPage(req?.result.maxPages)

        if (props.level === 3) {
          props.onChange && props.onChange(fields);
        }
      }
      func()
    }

    if (props?.readOnly === true) return
    let newFields = [...fields]
    const originalItem = fields[index]

    const newItem = Object.assign(
      originalItem,
      type === DiagnosisFieldPart.diagnosisCode
        ? { diagnosis: searchCode }
        : { diagnosisCode: searchCode }
    )

    newFields.splice(index, 1, newItem)

    if (newFields.length > 1) {
      newFields = newFields.filter(it => !!it.diagnosis || !!it.diagnosisCode)
    }
    const allLinesFilled = !newFields.some(it => it.diagnosis == undefined || it.diagnosisCode == undefined);

    props.onChange &&
      props.onChange(newFields.filter(it => it.diagnosis && it.diagnosisCode))
    setFields(newFields)
    if (props.level !== 3) {
      setOpen(true)
    }
  }

  const setDiagnosis = (
    newValue: string | undefined,
    index: number,
    type: DiagnosisFieldPart,
    item?: any
  ) => {
    if (item) {
      let newItemObj = { ...item };
      const extraProps = ['description', 'typeOfInformationLevel', 'typeOfMedicalDictionaryId'];
      for (const property of extraProps) {
        delete newItemObj[property];
      }
      newItemObj = { ...newItemObj, input: props.currentInputId };

      setPendingDiagnosis(newItemObj);
    }

    if (props.level !== 3 || item) {


      setInputValue(newValue || '');
      let newFields = [...fields];
      newFields[index].diagnosis = newValue;
      setFields(newFields);

      if (!newValue) fields[index].diagnosisCode = '';
      if (newValue && newValue.length > 2 && diagnosisList.length > 0) {
        let arr = diagnosisList.filter(el => el.name === newValue);

        if (arr.length) {
          fields[index].diagnosisCode = arr[index].code;
        }
      }

      props.onChange &&
        props.onChange(newFields.filter(it => it.diagnosis && it.diagnosisCode));

      const newArr = newFields.filter(it => it.diagnosis && it.diagnosisCode);
      const diagnosisCode = newArr.length > 0 ? newArr[0].diagnosisCode || '' : '';

      setDiagnosisCodeValue(diagnosisCode);

      setIsDisabledBtn(false);
      setOpen(false);
    } else {
      let newItemObj = { ...item };
      newItemObj = {
        ...newItemObj,
        input: props.currentInputId,
        name: newValue,
        code: fields[index].diagnosisCode
      };
      setPendingDiagnosis(newItemObj);
      setInputValue(newValue || '');
      let newFields = [...fields];
      newFields[index].diagnosis = newValue;
      setFields(newFields);
      props.onChange &&
        props.onChange(fields.filter(it => it.diagnosis && it.diagnosisCode));

      const newArr = fields.filter(it => it.diagnosis && it.diagnosisCode);
      const diagnosisCode = newArr.length > 0 ? newArr[0].diagnosisCode || '' : '';
      setDiagnosisCodeValue(diagnosisCode);

      setIsDisabledBtn(false);
      setOpen(false);
    }
  };


  const setPage = () => {
    let page = currentPage + 1
    setCurrentPage(page)
  }

  const fetchNext = async () => {
    if (maxPage !== currentPage) {
      try {
        setPage()
        const req = await chatApi.getDiagnosisOptionsVariantes(
          'IL5',
          searchText,
          currentPage,
          size
        )
        let arr = diagnosisList
        req?.result.results.forEach((el: any) => arr.push(el))
        setDiagnosisList(arr || [])
        setTotal(req?.result.total)
        setMaxPage(req?.result.maxPages)

      } catch (error) {
        console.log(error)
      }

    }
  }
  const [diagnosisTitle, setDiagnosisTitle] = useState<any[]>([])

  const saveDiagnosis = () => {
    console.log('saveDiagnosis Competing')
    if (!inputValue.trim()) {
      enqueueSnackbar("Поле 'Заболевание' не может быть пустым", { variant: 'error' })
      return;
    }
    if (inputDiagnosisArrStrings.includes(inputValue)) {
      enqueueSnackbar("Это заболевание уже добавлено", { variant: 'error' })
      return
    }
    if (props.items && pendingDiagnosis) {
      let newItemArr = [...props.items, pendingDiagnosis];
      props.setDiagnosis(newItemArr);
      const itemForRender = newItemArr.filter(obj => obj.input === 5)
      setDiagnosisTitle(itemForRender)
      setPendingDiagnosis(null);
    }
    setInputDiagnosisArrStrings(prevArr => [...prevArr, inputValue]);


    setInputValue('')
    fields.forEach((field, index) => {
      fields[index].diagnosisCode = '';
    });
    setIsDisabledBtn(true)
  }


  const saveMaxLevelDiagnosis = () => {
    if (!inputValue.trim()) {
      enqueueSnackbar("Поле 'Заболевание' не может быть пустым", { variant: 'error' });
      return;
    }
    if (inputDiagnosisArr.some(item => item.diagnosis === inputValue)) {
      enqueueSnackbar("Это заболевание уже добавлено", { variant: 'error' });
      return;
    }
    if (props.items && pendingDiagnosis) {
      let newItemArr = [...props.items, pendingDiagnosis];
      props.setDiagnosis(newItemArr);
      setPendingDiagnosis(null);
    }
    setInputDiagnosisArr(prevArr => [...prevArr, { diagnosis: inputValue, mkbValue }]);
    setInputValue('');
    handleClearMkb();
    setIsDisabledBtn(true);
  };


  const [mkbValue, setMkbValue] = useState('');
  const handleMkbChange = (event: { target: { value: React.SetStateAction<string> } }, inx: number) => {
    setMkbValue(event.target.value);
    onChange(event, inx, DiagnosisFieldPart.diagnosisCode);
  };
  const handleClearMkb = () => {
    setMkbValue('');
  };


  return (
    <div>
      {fields.map((it, inx) => {
        return (
          <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }} key={it.key} >
            <Autocomplete
              style={{ display: 'inline-block', width: '65%' }}
              value={it?.diagnosis || ''}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setDiagnosis(newInputValue, inx, DiagnosisFieldPart.diagnosis)
              }}
              id='controllable-states-demo'
              options={[]?.map((it: any) => it.name)}
              noOptionsText=''
              onMouseDownCapture={e => e.stopPropagation()}
              openOnFocus={false}
              PaperComponent={(param: any) => (
                <InfiniteScroll
                  height={props.level === 3 ? 10 : 200}
                  dataLength={total}
                  style={{
                    backgroundColor: '#fff'
                  }}
                  next={() => {
                    fetchNext()
                  }}
                  hasMore={diagnosisList.length < total}
                  loader={
                    <p
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#f9dc01'
                      }}
                    >
                      <b>Загрузка...</b>
                    </p>
                  }
                  endMessage={
                    <p>
                      <b></b>
                    </p>
                  }
                >
                  <List dense={true}>

                    {diagnosisList?.map((it: any, i) => (
                      <ListItem
                        onClick={() => {
                          setOpen(false)
                          setDiagnosis(
                            it.name,
                            inx,
                            DiagnosisFieldPart.diagnosis,
                            it
                          )
                        }
                        }
                        key={i}
                      >
                        <Tooltip
                          title={
                            <div style={{ padding: 10 }}>
                              <div style={{ fontSize: 14, marginBottom: 10 }}>Код:  {it.code}</div>
                              <div style={{ fontSize: 14 }}>Описание:  {it.description}</div>
                            </div>
                          }
                          //@ts-ignore
                          placement="right-center">
                          <ListItemText
                            style={{
                              padding: 5,
                              cursor: 'pointer'
                            }}
                            primary={it.name}
                          />
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </InfiniteScroll>
              )}
              renderInput={params => {
                return (
                  <TextField
                    onChange={node =>
                      onChange(node, inx, DiagnosisFieldPart.diagnosis)
                    }
                    {...params}
                    variant='outlined'
                    label='Заболевание'
                    size='small'
                    onClick={openModal}
                    onFocus={() => props.onClickInput(props.idInput)}
                  />
                )
              }}
              open={open}
              disableCloseOnSelect
            />
            {props.level === 3 ? (
              <>
                <TextField
                  type='text'
                  multiline
                  onFocus={() => setOpen(false)}
                  onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleMkbChange(event, inx)}

                  value={mkbValue}
                  label='Код МКБ'
                  style={{ width: '28%', marginLeft: '2%' }}
                  variant='outlined'
                  className={classes.mkbField} />
                <Button onClick={saveMaxLevelDiagnosis} disabled={mkbValue === ''}>
                  Ок
                </Button>


              </>
            ) : (
              <>
                <TextField
                  onChange={node =>
                    onChangeMkb(node, inx, DiagnosisFieldPart.diagnosis)
                  }
                  type='text'
                  multiline
                  // onFocus={() => setOpen(false)}
                  value={it?.diagnosisCode || ''}
                  label='Код МКБ'
                  style={{ width: '28%', marginLeft: '2%' }}
                  variant='outlined'
                  className={classes.mkbField}
                />
                <Button onClick={saveDiagnosis} disabled={isDisabledBtn} >Ок</Button>
              </>
            )}
          </div>
        )
      })}
      {props.level === 3 ? (
        <div style={{
          marginTop: '12px',
          marginLeft: '12px',
          fontSize: '16px'
        }}>
          {inputDiagnosisArr.map((value, index) => (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative'
            }}
              key={index}>
              <div style={{
                maxWidth: '60%',
                wordWrap: 'break-word',
                letterSpacing: '0.02em',
                fontWeight: 400
              }}
              >{value.diagnosis}</div>
              <div style={{
                position: 'absolute',
                marginLeft: '66%',
                letterSpacing: '0.02em',
                fontWeight: 400
              }}>
                {value.mkbValue}
              </div>
            </div>
          ))}
        </div>) : (
        <div style={{
          marginTop: '12px',
          marginLeft: '12px',
          fontSize: '16px'
        }}>
          {diagnosisTitle.map((value, index) => (
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative'
            }}
              key={index}>
              <div style={{
                maxWidth: '60%',
                wordWrap: 'break-word',
                letterSpacing: '0.02em',
                fontWeight: 400
              }}>
                {value.name}
              </div>
              <div style={{
                position: 'absolute',
                marginLeft: '66%',
                letterSpacing: '0.02em',
                fontWeight: 400
              }}>
                {value.code}
              </div>
            </div>
          ))}
        </div>
      )}


    </div>
  )
}

export default CompetingDiagnosis

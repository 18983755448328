import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Form148 from '../../pages/constructors/taskDescription/components/pharmacy/form148';
import Form14804 from '../../pages/constructors/taskDescription/components/pharmacy/Form148-04';
import Form14804Back from '../../pages/constructors/taskDescription/components/pharmacy/Form148-04Back';
import Form148Back from '../../pages/constructors/taskDescription/components/pharmacy/form148Back';
import { ReactComponent as Doctor } from '../../assets/img/pharmacy/DOCTOR.svg';
import { ReactComponent as MedicalCommission } from '../../assets/img/pharmacy/medicalCommission.svg';
import { ReactComponent as Recipe } from '../../assets/img/pharmacy/recipe.svg';
import { ReactComponent as Stamp } from '../../assets/img/pharmacy/stamp.svg';
import MainLayout from '../../components/Layout/MainLayout';
import NewChatWizardTopMenu from '../startChatWizard/NewChatWizardTopMenu';
import ButtonWithProgress from '../../components/common/ButtonWithProgress';
import { useHistory, useLocation } from 'react-router-dom';
import chatApi from '../../api/chatApi';
import { getTestingstageTaskOnTask } from '../../api/chatApi/chatApi';
import { useQuery } from 'react-query';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: 0,
    marginTop: 50,
    height: '90vh'
  },
  dropArea: {
    position: 'relative',
    width: '100%',
    minHeight: '600px',
    border: '1px dashed #ccc',
    marginBottom: '20px',
  },
  droppedSvg: {
    position: 'absolute',
    cursor: 'move',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'all',
  },
}));

interface IPharmacyPageProps {
  formData: {
    formId: number;
    inputs: { [key: number]: string };
    droppedItems: { type: string; x: number; y: number; isWrongAnswer?: boolean }[];
  }[];
}

const PharmacyPage = ({ formData }: IPharmacyPageProps) => {
  const c = useStyles();
  const [currentFormData, setCurrentFormData] = useState(formData);
  const [checkboxStatesByForm, setCheckboxStatesByForm] = useState<{ [formId: number]: { [key: number]: boolean } }>({});
  const location = useLocation();
  const [pharmacyList, setPharmacyList] = useState<any[]>([]);
  const [filteredPharmacyList, setFilteredPharmacyList] = useState<any[]>([]);
  const [inputsData, setInputsData] = useState<any[]>([]);
  const [isPharmacyListLoaded, setIsPharmacyListLoaded] = useState(false);
  const [isRecipeTaskLoaded, setIsRecipeTaskLoaded] = useState(false);
  const history = useHistory();
  const [stages, setStages] = useState([]);
  const [task, setTask] = useState(0);

  async function unfinishedTaskHandler() {
    try {
      const response = await chatApi.unfinishedTask()
      setTask(response.result.taskId)
    } catch (err) {
      console.log('при выполнении unfinishedTaskHandler', err);
    }
  }

  // Приходят данные для инпутов. Нужно фильтровать по recipeTaskId
  async function getRecipeByProceedTask() {
    try {
      const res = await chatApi.getRecipeTaskDetailByProceedTask()
      setInputsData(res.result)
    } catch (error) {
      console.log(error)
    }
  }

  async function getRecipeTaskDetailByTask() {
    try {
      if (task !== 0) {
        const res = await chatApi.getRecipeTaskDetailByTask(task)
        console.log('first  21', res)
      }
    } catch (error) {
      console.log(error)

    }
  }

  useEffect(() => {
    unfinishedTaskHandler()
  }, [])


  useEffect(() => {
    getRecipeByProceedTask()
    getRecipeTaskDetailByTask()
  }, [task]);

  useEffect(() => {
    async function fetchData() {
      await getPharmacyHandler();
      await getRecipeTaskById();
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (filteredPharmacyList.length > 0) {
      getRecipeTaskDetails();
    }
  }, [filteredPharmacyList]);

  async function getRecipeTaskDetails() {
    try {
      if (task !== 0 && filteredPharmacyList.length > 0) {
        const res = await chatApi.getRecipeTaskDetailByTask(task);
        const formInputs = {};
        const formDroppedItems = {};
  
        res.result.forEach((detail) => {
          const { recipeTaskId, cell, value, x, y } = detail;
          if (x !== 0 && y !== 0) {
            if (!formDroppedItems[recipeTaskId]) {
              formDroppedItems[recipeTaskId] = [];
            }
            formDroppedItems[recipeTaskId].push({
              type: 'Stamp',
              x,
              y,
              isWrongAnswer: detail.right === null ? false : !detail.right,
            });
          } else {
            if (!formInputs[recipeTaskId]) {
              formInputs[recipeTaskId] = {};
            }
            formInputs[recipeTaskId][cell] = value;
          }
        });
  
 
        setCurrentFormData(
          filteredPharmacyList.map((form) => {
            const formId = form.id;
            return {
              formId: formId,
              idRecipe: form.idRecipe, // Используем idRecipe для связи
              inputs: formInputs[formId] || {},
              droppedItems: formDroppedItems[formId] || [],
            };
          })
        );
      }
    } catch (error) {
      console.log('Error fetching task details:', error);
    }
  }
  
  async function getPharmacyHandler() {
    try {
      const response = await chatApi.getAllRecipe();
      setPharmacyList(response.result);
      setIsPharmacyListLoaded(true); // Отметить, что загрузка завершена
    } catch (err) {
      console.log('при получении списка фармации произошла ошибка', err);
    }
  }

  async function getRecipeTaskById() {
    try {
      const result = await chatApi.getRecipeTask();
      console.log('result', result)

      // Отфильтровать pharmacyList на основе idRecipe из результата getRecipeTask
      const filteredList = pharmacyList.filter((form) =>
        result.result.some((task) => task.idRecipe === form.id)
      );

    

      // Добавить названия форм из pharmacyList к результатам getRecipeTask
      const enrichedList = filteredList.map((form) => {
        const task = result.result.find((task) => task.idRecipe === form.id);
        return {
          ...task,
          name: form.name,
        };
      });

      console.log('enrichedList', enrichedList)

      setFilteredPharmacyList(enrichedList);
      setIsRecipeTaskLoaded(true); 
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPharmacyHandler();
    getRecipeTaskById();
  }, []);

  useEffect(() => {
    // Фильтровать список форм только после завершения загрузки обоих наборов данных
    if (isPharmacyListLoaded && isRecipeTaskLoaded) {
      getRecipeTaskById(); // Выполнить фильтрацию
    }
  }, [isPharmacyListLoaded, isRecipeTaskLoaded]);

  async function setHistoryStage(testingStage) {
    try {
      await chatApi.setTestingStageHistory(testingStage);
    } catch (error) {
      console.log(error);
    }
  }

  async function goToNextStage() {
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);
    if (!currentStageObj) return;

    setHistoryStage(currentStageObj.activeStage);
    const currentActiveStage = currentStageObj.activeStage;

    const nextStageObj = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage)[0];

    if (nextStageObj) history.push(nextStageObj.routePath);
  }

  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  useEffect(() => {
    fetchAllStages();
  }, []);

  const handleCheckboxStateChange = useCallback((formId: number, newCheckboxStates: { [key: number]: boolean }) => {
    setCheckboxStatesByForm((prevStates) => ({
      ...prevStates,
      [formId]: newCheckboxStates,
    }));
  }, []);

  const handleInputChange = useCallback((formId: number, inputId: number, value: string) => {
    setCurrentFormData((prevData) =>
      prevData.map((form) =>
        form.formId === formId
          ? { ...form, inputs: { ...form.inputs, [inputId]: value } }
          : form
      )
    );
  }, []);

  const handleSvgCheckboxChange = useCallback((formId: number, index: number) => {
    setCurrentFormData((prevData) =>
      prevData.map((form) =>
        form.formId === formId
          ? {
              ...form,
              droppedItems: form.droppedItems.map((item, i) =>
                i === index ? { ...item, isWrongAnswer: !item.isWrongAnswer } : item
              ),
            }
          : form
      )
    );
  }, []);

  const handleSave = () => {
    const checkboxResults = currentFormData.map((form) => ({
      formId: form.formId,
      inputs: checkboxStatesByForm[form.formId] || {},
      droppedItems: form.droppedItems,
    }));
    console.log('Сохраненные состояния чекбоксов формы:', checkboxResults);
    goToNextStage();
  };

  console.log('currentFormData', currentFormData)


  const sampleFormData = [
    {
      formId: 7,
      inputs: {
        1: "1111",
        2: "22222",
        3: "28",
        4: "09",
        5: "24",
        6: "Дата рождения 111",
        7: "Адрес места жительства или N медицинской карты",
        8: "Фамилия, инициалы имени и отчества (последнее",
        9: "111",
        10: "222",
        11: "333",
        12: "444",
      },
      droppedItems: [
        {
          type: 'Stamp',
          x: 100,
          y: 100,
          isWrongAnswer: false,
        },
      ],
    },
    {
      formId: 4,
      inputs: {
        1: "1111",
        2: "222222222222222",
        3: "3333",
        4: "4444444444444",
        15: "РЕЦЕПТ",
        16: "а оформл",
        18: "Фамилия, иниц",
        19: "Номер медицинской карты пациента, получающего медицинскую помощь в амбулаторных условиях",
        20: "Фамилия, инициалы имени и отчес",
      },
      droppedItems: [
        {
          type: 'Doctor',
          x: 200,
          y: 200,
          isWrongAnswer: true,
        },
      ],
    },
  ];



  return (
    <MainLayout desktopHeader={<NewChatWizardTopMenu title={'Фармация'} />} title="Фармация">
      <Box className={c.root}>
        {filteredPharmacyList.map((form) => {
          let ComponentToRender;
          switch (form.idRecipe) {
            case 4:
              ComponentToRender = Form14804;
              break;
            case 5:
              ComponentToRender = Form14804Back;
              break;
            case 6:
              ComponentToRender = Form148Back;
              break;
            case 7:
              ComponentToRender = Form148;
              break;
            default:
              ComponentToRender = null;
          }

          return ComponentToRender ? (
            <Accordion key={form.idRecipe}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{form.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={c.dropArea}>
                  <ComponentToRender
                    onInputChange={(inputId: number, value: string) => handleInputChange(form.idRecipe, inputId, value)}
                    initialValues={currentFormData?.find(el => el.idRecipe === form.idRecipe)?.inputs || {}}
                    isReadOnly={true}
                    isShow={true}
                    onCheckboxStateChange={(newCheckboxStates) => handleCheckboxStateChange(form.idRecipe, newCheckboxStates)}
                  />
                  <Divider />
                  {form.droppedItems?.map((item, index) => {
                    let SvgComponent;

                    switch (item.type) {
                      case 'Doctor':
                        SvgComponent = Doctor;
                        break;
                      case 'MedicalCommission':
                        SvgComponent = MedicalCommission;
                        break;
                      case 'Recipe':
                        SvgComponent = Recipe;
                        break;
                      case 'Stamp':
                        SvgComponent = Stamp;
                        break;
                      default:
                        SvgComponent = null;
                    }

                    return SvgComponent ? (
                      <div
                        key={index}
                        className={c.droppedSvg}
                        style={{
                          left: `${item.x}px`,
                          top: `${item.y}px`,
                        }}
                      >
                        <SvgComponent width="150px" height="150px" />
                        <input
                          type="checkbox"
                          checked={item.isWrongAnswer || false}
                          onChange={() => handleSvgCheckboxChange(form.idRecipe, index)}
                          style={{ position: 'absolute', left: '10px', top: '10px' }}
                        />
                      </div>
                    ) : null;
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null;
        })}

        <div style={{ alignSelf: `center`, margin: `10px`, marginTop: 50 }}>
          <ButtonWithProgress variant='contained' color='primary' onClick={() => handleSave()}>
            Следующий этап
          </ButtonWithProgress>
        </div>
      </Box>
    </MainLayout>
  );
};


export default PharmacyPage;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import chatApi from '../../api/chatApi';
import { TestingStage } from '../../api/chatApi/chatApi';
import { ChatActionType } from '../../store/chat';
import { RoutePath } from '../../types';

type Type = (routeToRedirect?: string, idStage?: any, targetStage?: TestingStage) => [boolean | undefined, () => void, () => void];

/**
 * возвращает статус загрузки и функцию при вызове которой инкрементится шаг текущей задачи
 * @param routeToRedirect - url route для перехода после успешного инкремента шага
 * @param idStage - id этапа
 * @returns [loadingState, startIncreaseFunction]
 */
const useIncreaseTaskStage: Type = (routeToRedirect, idStage,) => {
  const [asyncState, increase] = useAsyncFn(() => chatApi.increaseTaskStage(), []);
  const [asyncTaskStage, setAsyncTaskStage] = useAsyncFn(() => chatApi.increaseSetTaskStage(idStage), [])

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!asyncState.loading && !!asyncState.value && !asyncState.error) {
      dispatch({ type: ChatActionType.INCREMENT_TESTING_STATE });
      if (routeToRedirect) history.push(routeToRedirect);
    }
  }, [asyncState, history, dispatch, routeToRedirect]);

  return [asyncState?.loading, increase, setAsyncTaskStage];
};

export default useIncreaseTaskStage;

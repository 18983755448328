import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import './style.css';
import { makeStyles, createStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import 'moment/locale/ru';
import chatApi from '../../../api/chatApi';
import { RoutePath } from '../../../types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ChatActionType } from '../../../store/chat';
import { SagaActionType } from '../../../store/sagas/sagaActions';
// import ChampionshipListUsers from './ChampionshipListUsers';
import { usePointProcentStore } from '../../chat/store';
import ChampionshipTaskStage from './ChampionshipTaskStage';

const useStyles = makeStyles(() =>
  createStyles({
    block: {
      display: 'flex',
      marginBottom: 50,
    },
    lorem: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      color: '#000000',
      marginBottom: 20,
      paddingLeft: 10
    },
    lorem1: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      color: '#000000',
      marginBottom: 20,
      backgroundColor: '#E0E0E0',
      borderRadius: 4,
      paddingLeft: 10,
      height: 48,
      paddingTop: 10,
      width: '100%'
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      // width: '100%',
      // height: '100%',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 'bold',
      padding: 24,
    },
    btnGroup: {
      display: 'flex'
    },
    openTaskBtn: {
      marginRight: 5
    },
    stages: {
      fontFamily: 'Helvetica Neue',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 23,
      color: '#000000',
      marginRight: 24,
      marginTop: 32,
      marginBottom: 20
    },
    champInfoContainer: {
      paddingLeft: 20,
      paddingRight: 20,
    }
  })
)

const ChampionshipsStages = ({ stages, status }) => {
  const style = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [newArr, setNewArr] = useState([]);
  const idChamp = localStorage.getItem('idChamp')
  const deleteDatas = usePointProcentStore(state => state.deleteData)

  async function unfinishedTask() {
    try {
      await chatApi
        .finishTask()
        .then(() => {
          deleteDatas()
          dispatch({ type: SagaActionType.FINISH_TASK });
        })
        .catch((err) => {
        })
      const task = await chatApi.unfinishedTask()
    } catch (err) {
      console.log(`при получении статуса задачи произошла ошибка: ${err}`)
    }

  }

  const sortStages = () => {
    const arr = [...stages].map(el => {
      const dateStart = el.championship_stage_date_start.split('.')
      dateStart[1] = dateStart.splice(0, 1, dateStart[1])[0]
      const dateStart1 = dateStart.join(".")

      const dateEnd = el.championship_stage_date_end.split('.')
      dateEnd[1] = dateEnd.splice(0, 1, dateEnd[1])[0]
      const dateEnd1 = dateEnd.join(".")
      el["data_start"] = new Date(dateStart1);
      el["data_end"] = new Date(dateEnd1);
      return el;
    });
    setNewArr(arr)
  }

  useEffect(() => {
    sortStages()
    registredUsers()
    listPoint()
  }, []);

  const registredUsers = async () => {
    await chatApi.registeredUsers(idChamp)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  const listPoint = async () => {
    await chatApi.userListPoint(idChamp)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  const onClickItem = async (id) => {
    await unfinishedTask()
    await chatApi
      .startTask1(id, idChamp)
      .then(res => {
        dispatch({
          type: ChatActionType.SET_CHAT_HISTORY,
          data: {
            value: { messages: [], points: 0, penalty: 0 },
            loading: false,
            error: undefined
          }
        })
        dispatch({ type: 'START_LOAD_TASK' })
        history.replace(RoutePath.testModel)
      })
      .catch(e => {
        if (!e?.data?.success) history.replace(RoutePath.testModel)
      })

  }

  function createMarkup(text) {
    return { __html: text };
  }


  return (
    <div>
      <div className={style.stages}>Этапы чемпионата</div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ maxWidth: 800 }}>
          {newArr.map((el, index) => {
            return (
              <TableContainer style={{ marginBottom: 20 }} component={Paper}>
                <div className={style.block} key={index}>
                  <div style={{ width: '100%' }}>
                    <div className={style.lorem1}>{el.name}</div>
                    <div className={style.champInfoContainer}>
                      <div className={style.lorem} style={{ width: '100%' }} dangerouslySetInnerHTML={createMarkup(el.description)} />
                      <div className={style.lorem}>Дата проведения: {el.championship_stage_date_start} - {el.championship_stage_date_end}</div>
                      <div className={style.lorem}>Максимум баллов: {el.max_point}</div>
                      <ChampionshipTaskStage next={onClickItem} max={el.max_user} task={el.task_stage} status={status} />
                    </div>
                  </div>
                </div>
              </TableContainer>
            )
          })}
        </Box>
      </div>
    </div>

  );
};

export default ChampionshipsStages;

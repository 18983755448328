import { InputAdornment, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import React, { FunctionComponent, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: theme.spacing(1)
      }
    },
    search: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingTop: 0
    },
    inputColor: {
      '& label': {
        color: theme.palette.text.primary
      }
    },
    closeIcon: {
      cursor: 'pointer',
    },
  })
)

interface Props {
  placeholder?: string
  label?: string
  onChange?: (newText: string) => any
}

const SearchInput: FunctionComponent<Props> = ({
  label,
  placeholder,
  ...props
}) => {
  const classes = useStyles()

  const [query, setQuery] = useState('')

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <TextField
          type='text'
          label={label}
          placeholder={placeholder}
          value={query}
          onChange={event => {
            setQuery(event.target.value)
            props.onChange?.(event.target.value)
          }}
          className={classes.inputColor}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
                {props.onChange && query ?
                  <CloseIcon onClick={() => {
                    props.onChange?.('')
                    setQuery('')
                  }}
                    className={classes.closeIcon} /> : null}
              </InputAdornment>
            )
          }}
        />
      </div>
    </div>
  )
}

export default SearchInput

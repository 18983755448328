import React from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import CustomButton from '../../../components/common/CustomButton'
import CustomHeader1 from '../../../components/common/CustomHeader1'
import SlotContainerMasonry from '../../../components/common/Slots/SlotContainerMasonry'
// import SlotPlaceholder from '../../components/common/Slots/SlotPlaceholder';
import MainLayout from '../../../components/Layout/MainLayout'
import AdditionalDiagnosisSlot from '../../../components/PageBlocks/PreDiagnosis/AdditionalDiagnosisSlot'
import MainDiagnosisSlot from '../../../components/PageBlocks/PreDiagnosis/MainDiagnosisSlot'
import PatientConditionSlot from '../../../components/PageBlocks/PreDiagnosis/PatientConditionSlot'
import CompeteDiagnosisSlot from '../../../components/PageBlocks/PreDiagnosis/CompeteDiagnosisSlot'
import ComplicationDiagnosisSlot from '../../../components/PageBlocks/PreDiagnosis/ComplicationDiagnosisSlot'

import { CHAT_MENU_ITEMS } from '../../../constants/default'

export default function SetDiagnosisPage() {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <SlotContainerMasonry>
        <MainDiagnosisSlot />
        <PatientConditionSlot onChange={e => {}} />
        <CompeteDiagnosisSlot />
        <ComplicationDiagnosisSlot />
        <AdditionalDiagnosisSlot />
        {/* <SlotPlaceholder title="Добавить еще диагноз" onClick={() => alert('action!')} /> */}
      </SlotContainerMasonry>
      <CustomButton>Вперед!</CustomButton>
    </MainLayout>
  )
}

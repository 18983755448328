import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Sasha(props) {
  const { nodes, materials, animations } = useGLTF('patient/SASHA_1_RIG.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)
  const [index, setIndex] = useState(4)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="metarig">
          <group name="spine" position={[0, 0.86, 0.01]} rotation={[0.25, 0, 0]}>
            <group name="spine001" position={[0, 0.14, 0]} rotation={[-0.37, 0, 0]}>
              <group name="spine002" position={[0, 0.2, 0]} rotation={[-0.29, 0, 0]}>
                <group name="spine003" position={[0, 0.14, 0]} rotation={[0.41, 0, 0]}>
                  <group name="spine004" position={[0, 0.12, 0]} rotation={[0.21, 0, 0]}>
                    <group name="spine005" position={[0, 0.06, 0]} rotation={[0.32, 0, 0]}>
                      <group name="spine006" position={[0, 0.11, 0]} rotation={[-0.57, 0, 0]}>
                        <group name="face" rotation={[0.06, 0, 0]}>
                          <group name="nose_1" position={[0, 0.17, 0.2]} rotation={[2.51, 0, 0]}>
                            <group name="nose001_1" position={[0, 0.06, 0]} rotation={[0.04, 0, 0]}>
                              <group name="nose002_1" position={[0, 0.02, 0]} rotation={[1.15, 0, 0]}>
                                <group name="nose003_1" position={[0, 0.01, 0]} rotation={[0.49, 0, 0]}>
                                  <group name="nose004_1" position={[0, 0.02, 0]} rotation={[-1.01, 0, 0]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTL" position={[0, 0.05, 0.23]} rotation={[-1.56, -0.02, -1.3]}>
                            <group name="lipTL001_1" position={[0, 0.03, 0]} rotation={[-0.25, -0.27, 0.51]} />
                          </group>
                          <group name="lipBL" position={[0, 0.02, 0.22]} rotation={[-1.42, -0.19, -1.13]}>
                            <group name="lipBL001_1" position={[0, 0.03, 0]} rotation={[-0.02, 0.15, 0.08]} />
                          </group>
                          <group name="jaw_1" position={[0, -0.03, 0.11]} rotation={[1.63, 0, 0]}>
                            <group name="chin_1" position={[0, 0.09, 0]} rotation={[-1.29, 0, 0]}>
                              <group name="chin001_1" position={[0, 0.05, 0]} rotation={[-0.53, 0, 0]} />
                            </group>
                          </group>
                          <group name="earL_1" position={[0.16, 0.12, 0.01]} rotation={[0.03, -0.22, -0.19]}>
                            <group name="earL001" position={[0, 0.05, 0]} rotation={[-1.27, -0.2, -0.2]}>
                              <group name="earL002_1" position={[0, 0.04, 0]} rotation={[-1.98, -0.47, -0.24]}>
                                <group name="earL003_1" position={[0, 0.05, 0]} rotation={[-0.12, -0.58, 0.7]}>
                                  <group name="earL004_1" position={[0, 0.08, 0]} rotation={[-3.04, 0.01, 0.64]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="earR_1" position={[-0.16, 0.12, 0.01]} rotation={[0.03, 0.22, 0.19]}>
                            <group name="earR001" position={[0, 0.05, 0]} rotation={[-1.27, 0.2, 0.2]}>
                              <group name="earR002_1" position={[0, 0.04, 0]} rotation={[-1.98, 0.47, 0.24]}>
                                <group name="earR003_1" position={[0, 0.05, 0]} rotation={[-0.12, 0.58, -0.7]}>
                                  <group name="earR004_1" position={[0, 0.08, 0]} rotation={[-3.04, -0.01, -0.64]} />
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="lipTR" position={[0, 0.05, 0.23]} rotation={[-1.56, 0.02, 1.3]}>
                            <group name="lipTR001_1" position={[0, 0.03, 0]} rotation={[-0.25, 0.27, -0.51]} />
                          </group>
                          <group name="lipBR" position={[0, 0.02, 0.22]} rotation={[-1.42, 0.19, 1.13]}>
                            <group name="lipBR001_1" position={[0, 0.03, 0]} rotation={[-0.02, -0.15, -0.08]} />
                          </group>
                          <group name="browBL_1" position={[0.11, 0.18, 0.15]} rotation={[-0.97, 0.91, 1.96]}>
                            <group name="browBL001_1" position={[0, 0.02, 0]} rotation={[-0.48, 0.74, 0.04]}>
                              <group name="browBL002_1" position={[0, 0.02, 0]} rotation={[-0.09, 0.4, -0.6]}>
                                <group name="browBL003_1" position={[0, 0.03, 0]} rotation={[-0.37, 0.54, -0.19]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTL_1" position={[0.11, 0.16, 0.15]} rotation={[-0.87, 0.96, 2]}>
                            <group name="lidTL001_1" position={[0, 0.03, 0]} rotation={[-0.28, 0.88, -0.18]}>
                              <group name="lidTL002_1" position={[0, 0.02, 0]} rotation={[-0.32, 0.42, -0.59]}>
                                <group name="lidTL003_1" position={[0, 0.02, 0]} rotation={[-0.24, 0.56, -0.03]}>
                                  <group name="lidBL_1" position={[0, 0.03, 0]} rotation={[0.83, -0.29, -2.62]}>
                                    <group name="lidBL001_1" position={[0, 0.02, 0]} rotation={[0.15, 0.18, 0.06]}>
                                      <group name="lidBL002_1" position={[0, 0.02, 0]} rotation={[0.4, 0.29, 0.38]}>
                                        <group name="lidBL003_1" position={[0, 0.02, 0]} rotation={[-0.09, 0.14, 0.59]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="browBR_1" position={[-0.11, 0.18, 0.15]} rotation={[-0.97, -0.91, -1.96]}>
                            <group name="browBR001_1" position={[0, 0.02, 0]} rotation={[-0.48, -0.74, -0.04]}>
                              <group name="browBR002_1" position={[0, 0.02, 0]} rotation={[-0.09, -0.4, 0.6]}>
                                <group name="browBR003_1" position={[0, 0.03, 0]} rotation={[-0.37, -0.54, 0.19]} />
                              </group>
                            </group>
                          </group>
                          <group name="lidTR_1" position={[-0.11, 0.16, 0.15]} rotation={[-0.87, -0.96, -2]}>
                            <group name="lidTR001_1" position={[0, 0.03, 0]} rotation={[-0.28, -0.88, 0.18]}>
                              <group name="lidTR002_1" position={[0, 0.02, 0]} rotation={[-0.32, -0.42, 0.59]}>
                                <group name="lidTR003_1" position={[0, 0.02, 0]} rotation={[-0.24, -0.56, 0.03]}>
                                  <group name="lidBR_1" position={[0, 0.03, 0]} rotation={[0.83, 0.29, 2.62]}>
                                    <group name="lidBR001_1" position={[0, 0.02, 0]} rotation={[0.15, -0.18, -0.06]}>
                                      <group name="lidBR002_1" position={[0, 0.02, 0]} rotation={[0.4, -0.29, -0.38]}>
                                        <group name="lidBR003_1" position={[0, 0.02, 0]} rotation={[-0.09, -0.14, -0.59]} />
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadL" position={[0.03, 0.3, 0.21]} rotation={[-2.21, 1.47, -1.05]}>
                            <group name="foreheadL001" position={[-0.02, -0.01, 0.05]} rotation={[0.11, -0.3, -0.07]}>
                              <group name="foreheadL002" position={[-0.02, 0.01, 0.04]} rotation={[-0.04, -0.56, -0.03]}>
                                <group name="templeL" position={[-0.05, 0.01, 0.09]} rotation={[0.19, -0.67, 0.16]}>
                                  <group name="jawL_1" position={[0, 0.14, 0]} rotation={[0.06, -0.25, 0.44]}>
                                    <group name="jawL001_1" position={[0, 0.13, 0]} rotation={[-0.3, -0.65, 0.79]}>
                                      <group name="chinL_1" position={[0, 0.12, 0]} rotation={[-1.96, 0.12, 1.38]}>
                                        <group name="cheekBL" position={[0, 0.06, 0]} rotation={[-1.64, -0.09, -0.76]}>
                                          <group name="cheekBL001_1" position={[0, 0.08, 0]} rotation={[0.56, 0.59, 0.44]}>
                                            <group name="browTL_1" position={[0, 0.1, 0]} rotation={[1.22, 0.49, -0.01]}>
                                              <group name="browTL001_1" position={[0, 0.08, 0]} rotation={[-2.11, 0.64, 1.94]}>
                                                <group name="browTL002_1" position={[0, 0.05, 0]} rotation={[-0.46, 0.85, -0.25]}>
                                                  <group name="browTL003_1" position={[0, 0.06, 0]} rotation={[-0.08, 0.32, -0.88]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="foreheadR" position={[-0.03, 0.3, 0.21]} rotation={[-2.21, -1.47, 1.05]}>
                            <group name="foreheadR001" position={[0.02, -0.01, 0.05]} rotation={[0.11, 0.3, 0.07]}>
                              <group name="foreheadR002" position={[0.02, 0.01, 0.04]} rotation={[-0.04, 0.56, 0.03]}>
                                <group name="templeR" position={[0.05, 0.01, 0.09]} rotation={[0.19, 0.67, -0.16]}>
                                  <group name="jawR_1" position={[0, 0.14, 0]} rotation={[0.06, 0.25, -0.44]}>
                                    <group name="jawR001_1" position={[0, 0.13, 0]} rotation={[-0.3, 0.65, -0.79]}>
                                      <group name="chinR_1" position={[0, 0.12, 0]} rotation={[-1.96, -0.12, -1.38]}>
                                        <group name="cheekBR" position={[0, 0.06, 0]} rotation={[-1.64, 0.09, 0.76]}>
                                          <group name="cheekBR001_1" position={[0, 0.08, 0]} rotation={[0.56, -0.59, -0.44]}>
                                            <group name="browTR_1" position={[0, 0.1, 0]} rotation={[1.22, -0.49, 0.01]}>
                                              <group name="browTR001_1" position={[0, 0.08, 0]} rotation={[-2.11, -0.64, -1.94]}>
                                                <group name="browTR002_1" position={[0, 0.05, 0]} rotation={[-0.46, -0.85, 0.25]}>
                                                  <group name="browTR003_1" position={[0, 0.06, 0]} rotation={[-0.08, -0.32, 0.88]} />
                                                </group>
                                              </group>
                                            </group>
                                          </group>
                                        </group>
                                      </group>
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                          <group name="eyeL_1" position={[0.07, 0.16, 0.15]} rotation={[-1.59, 0, Math.PI]} />
                          <group name="eyeR_1" position={[-0.07, 0.16, 0.15]} rotation={[-1.59, 0, Math.PI]} />
                          <group name="cheekTL" position={[0.13, 0.16, 0.11]} rotation={[3.03, -1.02, 0.94]}>
                            <group name="cheekTL001_1" position={[0, 0.1, 0]} rotation={[2.88, -0.65, 1.44]}>
                              <group name="noseL_1" position={[0, 0.07, 0]} rotation={[2.7, 0.67, 1.25]}>
                                <group name="noseL001_1" position={[0, 0.06, 0]} rotation={[1.83, 0.22, 2.56]} />
                              </group>
                            </group>
                          </group>
                          <group name="cheekTR" position={[-0.13, 0.16, 0.11]} rotation={[3.03, 1.02, -0.94]}>
                            <group name="cheekTR001_1" position={[0, 0.1, 0]} rotation={[2.88, 0.65, -1.44]}>
                              <group name="noseR_1" position={[0, 0.07, 0]} rotation={[2.7, -0.67, -1.25]}>
                                <group name="noseR001_1" position={[0, 0.06, 0]} rotation={[1.83, -0.22, -2.56]} />
                              </group>
                            </group>
                          </group>
                          <group name="teethT_1" position={[0, 0.06, 0.21]} rotation={[-1.59, 0, 0]} />
                          <group name="teethB_1" position={[0, 0.04, 0.2]} rotation={[-1.59, 0, 0]} />
                          <group name="tongue_1" position={[0, 0.04, 0.17]} rotation={[-2.01, 0, 0]}>
                            <group name="tongue001_1" position={[0, 0.04, 0]} rotation={[0.74, 0, 0]}>
                              <group name="tongue002_1" position={[0, 0.05, 0]} rotation={[-1.21, 0, 0]} />
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderL_1" position={[0.02, 0.18, 0.09]} rotation={[-1.56, -0.03, -1.15]}>
                    <group name="upper_armL" position={[0, 0.2, -0.02]} rotation={[1.48, 1.14, -2.43]}>
                      <group name="forearmL" position={[0, 0.3, 0]} rotation={[0.81, 0.19, 0.11]}>
                        <group name="handL" position={[0, 0.35, 0]} rotation={[-0.04, -0.06, -0.26]}>
                          <group name="palm01L" position={[0, 0.03, 0.03]} rotation={[-1.78, 1.46, 1.91]}>
                            <group name="f_index01L_1" position={[0, 0.08, 0]} rotation={[0.23, -0.22, 0.12]}>
                              <group name="f_index02L_1" position={[0, 0.04, 0]} rotation={[0.06, 0.06, -0.05]}>
                                <group name="f_index03L_1" position={[0, 0.03, 0]} rotation={[0.13, -0.34, -0.05]} />
                              </group>
                            </group>
                            <group name="thumb01L_1" position={[-0.02, -0.02, 0.01]} rotation={[-0.75, 1.04, 1.23]}>
                              <group name="thumb02L_1" position={[0, 0.05, 0]} rotation={[0.05, 0.1, 0.08]}>
                                <group name="thumb03L_1" position={[0, 0.03, 0]} rotation={[0.24, 0.16, -0.13]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02L" position={[0, 0.03, 0]} rotation={[-1.68, 1.45, 1.67]}>
                            <group name="f_middle01L_1" position={[0, 0.08, 0]} rotation={[0.28, -0.26, -0.02]}>
                              <group name="f_middle02L_1" position={[0, 0.05, 0]} rotation={[0.08, -0.22, 0.04]}>
                                <group name="f_middle03L_1" position={[0, 0.04, 0]} rotation={[0.04, -0.08, -0.05]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03L" position={[0, 0.03, -0.02]} rotation={[-1.08, 1.51, 0.96]}>
                            <group name="f_ring01L_1" position={[0, 0.08, 0]} rotation={[0.24, -0.39, -0.14]}>
                              <group name="f_ring02L_1" position={[0, 0.04, 0]} rotation={[0.06, -0.09, 0.08]}>
                                <group name="f_ring03L_1" position={[0, 0.03, 0]} rotation={[0.02, -0.15, 0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04L" position={[0, 0.03, -0.03]} rotation={[0.43, 1.51, -0.73]}>
                            <group name="f_pinky01L_1" position={[0, 0.07, 0]} rotation={[0.15, -0.56, -0.08]}>
                              <group name="f_pinky02L_1" position={[0, 0.03, 0]} rotation={[0.13, -0.05, 0.04]}>
                                <group name="f_pinky03L_1" position={[0, 0.03, 0]} rotation={[0.1, -0.03, -0.05]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="shoulderR_1" position={[-0.02, 0.18, 0.09]} rotation={[-1.56, 0.03, 1.15]}>
                    <group name="upper_armR" position={[0, 0.2, -0.02]} rotation={[1.48, -1.14, 2.43]}>
                      <group name="forearmR" position={[0, 0.3, 0]} rotation={[0.81, -0.19, -0.11]}>
                        <group name="handR" position={[0, 0.35, 0]} rotation={[-0.04, 0.06, 0.26]}>
                          <group name="palm01R" position={[0, 0.03, 0.03]} rotation={[-1.78, -1.46, -1.91]}>
                            <group name="f_index01R_1" position={[0, 0.08, 0]} rotation={[0.23, 0.22, -0.12]}>
                              <group name="f_index02R_1" position={[0, 0.04, 0]} rotation={[0.06, -0.06, 0.05]}>
                                <group name="f_index03R_1" position={[0, 0.03, 0]} rotation={[0.13, 0.34, 0.05]} />
                              </group>
                            </group>
                            <group name="thumb01R_1" position={[0.02, -0.02, 0.01]} rotation={[-0.75, -1.04, -1.23]}>
                              <group name="thumb02R_1" position={[0, 0.05, 0]} rotation={[0.05, -0.1, -0.08]}>
                                <group name="thumb03R_1" position={[0, 0.03, 0]} rotation={[0.24, -0.16, 0.13]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm02R" position={[0, 0.03, 0]} rotation={[-1.68, -1.45, -1.67]}>
                            <group name="f_middle01R_1" position={[0, 0.08, 0]} rotation={[0.28, 0.26, 0.02]}>
                              <group name="f_middle02R_1" position={[0, 0.05, 0]} rotation={[0.08, 0.22, -0.04]}>
                                <group name="f_middle03R_1" position={[0, 0.04, 0]} rotation={[0.04, 0.08, 0.05]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm03R" position={[0, 0.03, -0.02]} rotation={[-1.08, -1.51, -0.96]}>
                            <group name="f_ring01R_1" position={[0, 0.08, 0]} rotation={[0.24, 0.39, 0.14]}>
                              <group name="f_ring02R_1" position={[0, 0.04, 0]} rotation={[0.06, 0.09, -0.08]}>
                                <group name="f_ring03R_1" position={[0, 0.03, 0]} rotation={[0.02, 0.15, -0.02]} />
                              </group>
                            </group>
                          </group>
                          <group name="palm04R" position={[0, 0.03, -0.03]} rotation={[0.43, -1.51, 0.73]}>
                            <group name="f_pinky01R_1" position={[0, 0.07, 0]} rotation={[0.15, 0.56, 0.08]}>
                              <group name="f_pinky02R_1" position={[0, 0.03, 0]} rotation={[0.13, 0.05, -0.04]}>
                                <group name="f_pinky03R_1" position={[0, 0.03, 0]} rotation={[0.1, 0.03, 0.05]} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group name="breastL_1" position={[0.11, 0.02, -0.01]} rotation={[-1.57, 0, -Math.PI]} />
                  <group name="breastR_1" position={[-0.11, 0.02, -0.01]} rotation={[-1.57, 0, -Math.PI]} />
                </group>
              </group>
            </group>
            <group name="pelvisL" rotation={[-1.9, -0.66, -2.04]} />
            <group name="pelvisR" rotation={[-1.9, 0.66, 2.04]} />
            <group name="thighL" position={[0.13, 0.07, 0.02]} rotation={[2.85, 0, -0.12]}>
              <group name="shinL" position={[0, 0.45, 0]} rotation={[0.23, 0.02, -0.02]}>
                <group name="footL" position={[0, 0.37, 0]} rotation={[-1.22, -0.12, 0.06]}>
                  <group name="toeL_1" position={[0, 0.18, 0]} rotation={[2.74, 0, -Math.PI]} />
                  <group name="heel02L" position={[-0.04, 0.03, 0.1]} rotation={[2.61, 0, -1.57]} />
                </group>
              </group>
            </group>
            <group name="thighR" position={[-0.13, 0.07, 0.02]} rotation={[2.85, 0, 0.12]}>
              <group name="shinR" position={[0, 0.45, 0]} rotation={[0.23, -0.02, 0.02]}>
                <group name="footR" position={[0, 0.37, 0]} rotation={[-1.22, 0.12, -0.06]}>
                  <group name="toeR_1" position={[0, 0.18, 0]} rotation={[2.74, 0, Math.PI]} />
                  <group name="heel02R" position={[0.04, 0.03, 0.1]} rotation={[2.61, 0, 1.57]} />
                </group>
              </group>
            </group>
          </group>
        </group>
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="SASHA_NEW_Model">
            <skinnedMesh name="SASHA_NEW_Model_1" geometry={nodes.SASHA_NEW_Model_1.geometry} material={materials['Material.003']} skeleton={nodes.SASHA_NEW_Model_1.skeleton} />
            <skinnedMesh name="SASHA_NEW_Model_2" geometry={nodes.SASHA_NEW_Model_2.geometry} material={materials['Material.005']} skeleton={nodes.SASHA_NEW_Model_2.skeleton} />
            <skinnedMesh name="SASHA_NEW_Model_3" geometry={nodes.SASHA_NEW_Model_3.geometry} material={materials['Material.006']} skeleton={nodes.SASHA_NEW_Model_3.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('/SASHA_1_RIG.glb')

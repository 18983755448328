import React, { useEffect, useState } from "react";
import { Modal, Loading } from '@carbon/react';
import { getMediaContent } from "../../api/chatApi/chatApi";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const ModalContent = ({ open, setOpen, item }) => {
  const [link, setLink] = useState('');

  const res = async (item) => {
    await getMediaContent(item.text)
      .then(res => {
        setLink(res.data.data_base64)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    res(item)
  }, [item]);

  const isPdf = link.startsWith('JVBERi0');
  const isXlsx = link.startsWith('UEsDBBQABgAIAAAAIQ')

  return (
    <Modal
      open={open}
      // modalHeading={item.name}
      passiveModal
      onRequestClose={() => setOpen(false)}>
      <div>
        {item.type === 2 ?
          <div>{item.text}</div>
          :
          link ?
            <div>
              {item.type === 3
                ?
                <div style={{ marginTop: 10, justifyContent: 'space-between', overflow: 'hidden' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
                    <div style={{ width: '80%' }}>{item.name}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <a target='_blank'
                        href={`data:${isPdf ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};base64,${link}`}
                        download={`${item.name}.${isPdf ? 'pdf' : 'xlsx'}`}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between',
                          marginLeft: 8,
                          height: 30,
                          textDecoration: 'none',
                        }} rel="noreferrer">
                        <p style={{ marginRight: 8, color: 'black' }}>
                          Скачать
                        </p>
                        <CloudDownloadIcon style={{ height: 30 }} />
                      </a>
                    </div>
                  </div>
                  {
                    isPdf ?
                      <iframe
                        title="PDF"
                        style={{ width: '100%', height: '500px', marginTop: 20 }}
                        src={`data:application/pdf;base64,${link}`}
                        frameBorder="0"
                      >
                        Ваш браузер не поддерживает превью PDF. Пожалуйста, скачайте файл для просмотра:
                        <a href={`data:application/pdf;base64,${link}`}>Скачать PDF</a>.
                      </iframe> :
                      isXlsx &&
                      <div>
                        <p>Файл Excel можно скачать по ссылке выше.</p>
                      </div>
                  }
                </div>
                :
                null}
              {item.type === 4
                ?
                <div style={{ marginTop: 4, justifyContent: 'space-between' }}>
                  {item.name}
                  <div style={{ display: 'flex', marginTop: 10 }}>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`data:image/jpeg;base64,${link}`} alt="фото" />
                  </div>

                </div>

                :
                null}
              {item.type === 5
                ?
                <div style={{ marginTop: 4, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                      <figure>
                        <audio
                          controls
                          style={{ width: '100%' }}
                          src={`data:audio/mpeg;base64,${link}`}>
                          <a href={item.text}>
                            Скачать аудио
                          </a>
                        </audio>
                      </figure>
                    </div>
                  </div>

                </div>

                :
                null}
              {item.type === 6
                ?
                <div style={{ marginTop: 4, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                      <video controls style={{ width: '100%' }}>
                        <source src={`data:video/webm;base64,${link}`} type="video/webm" />

                        <source src={`data:video/mp4;base64,${link}`} type="video/mp4" />

                        Скачать
                        <a href={`data:video/webm;base64,${link}`}>WEBM</a>
                        или
                        <a href={`data:video/mp4;base64,${link}`}>MP4</a>
                        видео
                      </video>
                    </div>
                  </div>

                </div>

                :
                null}
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'center', overflowY: 'hidden' }}>
              <Loading active={true} small={false} withOverlay={false} description="Loading..." />
            </div>
        }

      </div>
    </Modal>
  );
}

export default ModalContent;
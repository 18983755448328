import React, { ReactElement } from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import CustomButton from '../../../components/common/CustomButton'
import CustomHeader1 from '../../../components/common/CustomHeader1'
import SlotContainerCenter from '../../../components/common/Slots/SlotContainerCenter'
import MainLayout from '../../../components/Layout/MainLayout'
import FinalResultSlot from '../../../components/PageBlocks/Final/FinalResultSlot'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

export type iFinalResItem = {
  title: string
  points: number
}
export type iFinalResItems = {
  success: boolean
  points: number
  details: iFinalResItem[]
}

const FINAL_RESULTS: iFinalResItems = {
  success: false,
  points: 560,
  details: [
    {
      title: 'Опрос',
      points: 120
    },
    {
      title: 'Осмотр',
      points: -50
    },
    {
      title: 'Исследования',
      points: 150
    },
    {
      title: 'Диагноз',
      points: -24
    },
    {
      title: 'Лечения',
      points: -50
    }
  ]
}

export default function DemoResultPage(): ReactElement {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <CustomHeader1>Финальный результат</CustomHeader1>
      <SlotContainerCenter>
        <FinalResultSlot finalResults={FINAL_RESULTS} />
      </SlotContainerCenter>
      <CustomButton>Новый тест</CustomButton>
    </MainLayout>
  )
}

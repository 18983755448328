import {
  Box,
  BoxProps,
  Button,
  createStyles,
  makeStyles,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
// eslint-disable-next-line
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query'
import chatApi from '../../../api/chatApi'
import { PatientStatusCode } from '../../../api/chatApi/chatApi'
import Slot from '../../common/Slots/Slot'

// type Props = {
//   value?: PatientStatusCode;
//   onChange: (newValue: PatientStatusCode) => void;
// };
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: 24
    },
    autocomplete: {
      display: 'flex'
    },
    autocompleteField: {
      flexGrow: 1
    },
    autocompleteBtn: {
      border: '1px solid transparent'
    }
  })
)

const PathophysiologicalComponent: FunctionComponent = () => {
  const classes = useStyles({})

  const [value, setValue] = React.useState<string | null>()
  const [inputValue, setInputValue] = React.useState('')

  const pathophysiologicalModel: any = useQuery(
    ['pathophysiologicalModel', {}],
    async () => {
      const currTask: any = async () => await chatApi.unfinishedTask()
      const task = await currTask()
      return chatApi.getPathophysiologicalModel(task.result.taskId)
    }
  )

  const setSpecialist = (newValue: string | null) => {
    setValue(newValue)
  }

  const saveSpecialist = () => {
    const list = pathophysiologicalModel.data.result
    const element = list.filter((el: any) => el.value === value)[0]
    chatApi.setPathophysiologicalModel(element.id)
  }

  return (
    <div className={classes.root}>
      {!!pathophysiologicalModel?.data?.result.length && (
        <Slot title='Патsофизиологическая модель' color='primary'>
          <div className={classes.autocomplete}>
            <div className={classes.autocompleteField}>
              <Autocomplete
                value={value}
                onChange={(event: any, newValue: string | null) => {
                  setSpecialist(newValue)
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                id='controllable-states-demo'
                options={pathophysiologicalModel?.data?.result.map(
                  (it: any) => it.value
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Тест к задаче'
                    size='small'
                  />
                )}
              />
            </div>
            {/* <div className={classes.autocompleteBtn}>
              <Button variant="text" onClick={saveSpecialist}>
                Ок
              </Button>
            </div>*/}
          </div>
        </Slot>
      )}
    </div>
  )
}

export default PathophysiologicalComponent

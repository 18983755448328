import React, { useEffect, useState } from 'react'
import MainFooter from '../../components/MainFooter/MainFooter'
import MainHeader from '../../components/MainHeader/MainHeader'
import { makeStyles } from '@material-ui/core/styles';
import ImgChamp from '../../assets/img/social_link/ImgChamp.svg';
import chatApi from '../../api/chatApi';

const useStyles = makeStyles({
  wrapperInfo: {
    display: 'flex',
    width: '100%',
    height: '372px',
    flexFlow: 'row nowrap',
    marginTop: 48,
    marginBottom: 20,
  },
  wrapperTextTitle: {
    backgroundColor: 'black',
    flexGrow: 5,
  },
  wrapperText: {
    backgroundColor: 'black',
    flexGrow: 5,
  },
  wrapperImg: {
    flexGrow: 5
  },
  title: {
    color: 'white',
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 32,
    margin: '24px 0 87px 40px',
  },
  text: {
    color: 'black',
    width: 'auto',
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 16,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px 0 24px 40px',
    overflowY: 'auto',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  hidden: {
    display: 'none'
  }
});



const InfoUniversity = () => {
  const classes = useStyles();
  const [headerText, setHeaderText] = useState('');
  const [descriptionText, setdescriptionText] = useState('')
  const [titleText, setTitleText] = useState('');
  const [descriptionHide, setdescriptionHide] = useState('');
  const [keywords, setKeywords] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await chatApi.getDescription('infoUniversity');
      const headerText = response.result[0].h1;
      const descriptionText = response.result[0].descriptionText
      const titleText = response.result[0].title;
      const descriptionHide = response.result[0].descriptionHide
      const keywords = response.result[0].keywords

      setdescriptionText(descriptionText)
      setHeaderText(headerText);
      setTitleText(titleText)
      setdescriptionHide(descriptionHide)
      setKeywords(keywords)
    };
    fetchData();
  }, []);

  return (

    <>
      <MainHeader showComponentHelpFilled={false} />
      <head>
        <meta name="keywords" content={keywords} />
      </head>

      <div>
        <div className={classes.wrapperInfo}>
          <div className={classes.wrapperTextTitle}>
            <h1 className={classes.title}>{headerText}</h1>
          </div>
          <div className={classes.wrapperImg}>
            <img className={classes.img} src={ImgChamp} alt='img' />
          </div>
        </div>
        <span className={classes.text}>{descriptionText}</span>
      </div>
      <div className={classes.hidden}>{descriptionHide}</div>
      <MainFooter />
    </>
  )
}

export default InfoUniversity

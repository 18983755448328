import { FormControl, FormHelperText, Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import BtnEndIcon from '@material-ui/icons/KeyboardArrowRight'
import React, { FormEvent, FormEventHandler, useState } from 'react'
import { useStyles } from './_Styles'
import { useHistory } from 'react-router'
import chatApi from '../../../api/chatApi'
import { useAsyncFn } from 'react-use'
import ButtonWithProgress from '../../common/ButtonWithProgress'

export default function RegistrationForm() {
  const classes = useStyles()
  const hasError = false
  const [emailField, setEmailField] = useState<string>('')
  const [passField, setPassField] = useState<string>('')

  const history = useHistory()

  const [{ loading: isSending }, doRegistration] = useAsyncFn(() => {
    return chatApi
      .registration({
        email: emailField,
        password: passField,
        login: emailField
      })
      .then(res => {
        history.replace('/login')
      })
      .catch(err => console.log(err))
  }, [emailField, passField])

  const onSubmit: FormEventHandler = async (event: FormEvent) => {
    event.preventDefault()
    await doRegistration()
  }

  return (
    <div className={classes.paper}>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          value={emailField}
          onChange={e => setEmailField(e.target.value)}
          error={hasError}
          disabled={isSending}
          type='email'
          label='Email'
          className={classes.input}
          helperText={hasError ? 'Неверно введен email' : ''}
          fullWidth
        />
        <TextField
          value={passField}
          disabled={isSending}
          onChange={e => setPassField(e.target.value)}
          error={hasError}
          type='password'
          label='Пароль'
          className={classes.input}
          helperText={hasError ? 'Пароль должен быть длиннее 8 символов' : ''}
          fullWidth
        />
        <FormControl
          required
          error={hasError}
          disabled={isSending}
          fullWidth
          className={classes.checkbox}
        >
          <FormControlLabel
            control={
              <Checkbox
                value='agree'
                color='primary'
                aria-describedby='component-error-text'
              />
            }
            label={
              <Typography variant='caption'>
                Я согласен с <Link href='#'>правилами</Link>
              </Typography>
            }
          />
          {hasError && (
            <FormHelperText id='component-error-text'>
              Необходимо согласиться с правилами
            </FormHelperText>
          )}
        </FormControl>
        <div className={classes.submitWrapper}>
          <ButtonWithProgress
            variant='contained'
            color='primary'
            type='submit'
            disabled={isSending}
            loading={isSending}
            endIcon={<BtnEndIcon />}
          >
            РЕГИСТРАЦИЯ
          </ButtonWithProgress>
        </div>
      </form>
    </div>
  )
}

import React, { useEffect, useState, Suspense } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from 'material-ui-search-bar';
import { makeStyles } from '@material-ui/core/styles';
import { ratingProfile } from "../../api/chatApi/chatApi";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area
} from "recharts";
import chatApi from "../../api/chatApi";
import './index.css'

const useRowStyles = makeStyles({
  titleTable: {
    textAlign: 'center',
    fontWeight: 500,
    color: '#aeaeae',
  },
  bodyTable: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    textAlign: 'center',
  },
  item: {
    display: 'flex',
  },
  cellText: {
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  title: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '10px 0',
  },
  buttonSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellInput: {
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
  current: {
    width: '100%',
    padding: 20,
    boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 50,
    minHeight: 140
  },
  name: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 20,
    color: '#2196F3',
  },
  current_title: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 12,
    color: '#9A9A9A',
  },
  lorem: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 16,
    color: '#000000'
  },
  block: {
    borderRight: '1px solid #DADADA',
    padding: '0 10px'
  },
  datePickerContainer: {
    display: 'flex',
    marginBottom: 40,
    '& div.cds--date-picker': {
      display: 'flex',
      minWidth: 225,
    },
    '@media (max-width: 1330px)': {
      '& div.cds--date-picker': {
        flexDirection: 'row'
      },
      '& cds--date-picker-input__wrapper': {
        width: 160
      },
      '& .cds--date-picker__input': {
        minWidth: 150
      },
    },
    '& .cds--date-picker__input': {
      minWidth: 200
    },

    '& .cds--date-picker--range > .cds--date-picker-container': {
      width: '100%',
      marginRight: '15px',
    },
    '@media (max-width: 800px)': {
      '& .cds--date-picker__input': {
        minWidth: 310
      },
      '& div.cds--date-picker-input__wrapper': {
        minWidth: 150
      },
    },
    '@media (max-width: 1230px)': {
      '& div.cds--date-picker': {
      },
      '& .cds--date-picker__input': {
        minWidth: 130
      },
      '& div.cds--date-picker-input__wrapper': {
        minWidth: 120
      },
    },
    '@media (max-width: 670px)': {
      '& div.cds--date-picker': {
        flexDirection: 'column'
      },
      '& .cds--date-picker__input': {
        minWidth: 210
      },
      '& div.cds--date-picker-input__wrapper': {
        width: 200
      },
    }

  },
  wrapper: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    background: '#F4F4F4',
    padding: 40,
    marginBottom: 30
  },
  block2: {
    width: "100%",
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    '@media (max-width: 530px)': {
      flexDirection: 'column'
    }
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  periodTitle: {
    marginRight: "10%",
    alignItems: 'center'
  },
  datePickerInput: {
    width: '100%',
    '@media (max-width: 600px)': {
      width: '150px',
    },
  },
});
interface setBodyInterface {
  start_date?: string
  stop_date?: string
  xlsx?: boolean
}

const TableRating = ({ org, trn, option, id }) => {
  const classes = useRowStyles();
  const [body, setBody] = useState<setBodyInterface>()
  const [searched, setSearched] = useState('');
  const [data, setData] = useState<any>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [dates, setDates] = useState<any>({});
  const [diagrama, setDiagrama] = useState<any>(null);

  const diagram = async () => {
    if (startTime && endTime) {
      await chatApi.getRating(body?.stop_date, body?.start_date, id, org, trn, option)
        .then(res => {
          const delimiter = res.result.slice(-10)
          const newRes = delimiter.map((el) => ({
            ...el,
            date_: el.date_.split('-').reverse().join('.')
          }));
          setDiagrama(newRes)
        })
        .catch(err => console.log(err))
    }
  }


  const rating = async () => {
    if (startTime && endTime)
      await ratingProfile(body?.start_date, body?.stop_date, org, trn, option)
        .then(res => {
          setData(res.data.rating_profile)
        })
        .catch(err => console.log(err))
  }

  const requestSearch = async searchedVal => {
    setSearched(searchedVal)
    if (!searchedVal) {
      return rating();
    }
    await ratingProfile(dates.start, dates.end, org, trn, option)
      .then(res => {
        const result = res.data.rating_profile
        const filtered = result.filter(el => {
          if (el.fio !== null) {
            return el.fio.toLowerCase().includes(searchedVal.toLowerCase());
          }
        })
        setData(filtered);
      })
      .catch(err => console.log(err))
  };

  useEffect(() => {
    rating()
    diagram()
  }, [org, trn, option, startTime, endTime, id]);


  const handleStartDateChange = (date: Date) => {
    setStartTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      start_date: res,
    })
  };

  const handleEndDateChange = (date: Date) => {
    setEndTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      stop_date: res
    })
  };



  const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  };


  return (
    <div className={classes.mainContainer}>
      <div className={classes.title}>
        Рейтинг задач
      </div>
      <div className={classes.current}>
        <div className={classes.block2}>
          <div className={classes.periodTitle}>
            Выберите период
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              id="date-picker-inline"
              placeholder="с"
              value={startTime}
              onChange={handleStartDateChange}
            />
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              minDate={startTime}
              id="date-picker-inline"
              placeholder="по"
              value={endTime}
              onChange={handleEndDateChange}
              animateYearScrolling={true}
            />
          </MuiPickersUtilsProvider>
        </div>
        {data &&
          <>
            <SearchBar style={{ marginBottom: 20, width: '100%' }} value={searched} placeholder='Поиск по пользователям' onChange={searchVal => requestSearch(searchVal)} />
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.titleTable}>Место</TableCell>
                    <TableCell className={classes.titleTable} align="right">
                      ФИО
                    </TableCell>
                    <TableCell className={classes.titleTable} align="right">
                      Выполненые задачи
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(
                    (data, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.bodyTable} align="center" component="th" scope="row">
                          {data.row_number}
                        </TableCell>
                        <TableCell style={{ color: '#0E61FE' }} className={classes.bodyTable} align="center" component="th" scope="row">
                          {data.fio}
                        </TableCell>
                        <TableCell className={classes.bodyTable} align="center" component="th" scope="row">
                          {data.usertaskproceed_id}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>

              </Table>
            </TableContainer>
          </>}
        {diagrama && diagrama.length > 0 &&
          <Suspense>
            <div style={{ marginTop: 20, justifyContent: 'center' }}>
              <AreaChart
                width={window.innerWidth > 1132 ? 550 : window.innerWidth > 780 ? 480 : window.innerWidth > 520 ? 370 : 350}
                height={200}
                data={diagrama}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date_" />
                <YAxis />
                <Tooltip />
                <LabelList content={<CustomizedLabel />} />
                <Area type="monotone" dataKey="place" stroke="#2196F3" fill="#95c2fc" />
              </AreaChart>
            </div>
          </Suspense>}
      </div>
    </div>
  );
}

export default TableRating;

// import { TextField } from '@material-ui/core'
import React, { ReactElement } from 'react'
import Slot from '../../common/Slots/Slot'
import TextFieldsExample from './TextFieldsExample'
import TextFieldsEmptyExample from './TextFieldsEmptyExample'

export default function MainDiagnosisSlot(): ReactElement {
  return (
    <Slot title='Основное заболевание' color='primary'>
      <TextFieldsExample />
      <TextFieldsExample />
      <TextFieldsEmptyExample />
    </Slot>
  )
}

import React, { useEffect, useState } from 'react';
import { Loading } from '@carbon/react';
import chatApi from '../../../api/chatApi';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchBar from 'material-ui-search-bar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveIcon from "@material-ui/icons/Save";
import Paper from '@material-ui/core/Paper';


const useRowStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0 10px',
  },
  cellInput: {
    width: '100%',
    background: 'transparent',
    border: 'none',
    outline: 'none',
  },
  bodyTable: {
    textAlign: 'left',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      background: '#e8e8e8',
    },
  },
  titleTable: {
    textAlign: 'center',
    fontWeight: 600,
  },
  btnIcon: {
    width: 25,
    height: 25,
    marginLeft: 10,
    color: '#1876D1',
    cursor: 'pointer',
    '&:hover': {
      color: '#0050e6'
    },
  },
})

const AdminRating = () => {
  const [data, setData] = useState([]);
  const [searched, setSearched] = useState('');
  const [loading, setLoading] = useState(true);

  const classes = useRowStyles();

  const getData = async () => {
    setLoading(true)
    chatApi.adminRating()
      .then(res => {
        setLoading(false)
        setData(res.result)
      })
      .catch(err => console.log(err))
  }

  const requestSearch = async searchedVal => {
    if (!searchedVal) {
      return getData();
    }
    await chatApi
      .adminRating()
      .then(res => {
        const filteredRows = res.result.filter(row => {
          return row.description.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setData(filteredRows);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  const activeToggle = (id, toggle) => {
    const newData = data.map(el => (el.id === id ? { ...el, active: !toggle } : el))
    setData(newData);
  }

  // const changeDescription = (id, e) => {
  //   const newData = data.map(el => (el.id === id ? { ...el, description: e } : el))
  //   setData(newData);
  // }

  const saveData = async (id, descr, activ) => {
    await chatApi.updataAdminRating(id, descr, activ)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  return (
    <div style={{ marginBottom: 500, margin: '0 0 50px 0' }}>
      <Loading active={loading} small={false} withOverlay={true} description="Загрузка..." />
      <div className={classes.title} style={{ marginTop: 30 }}>
        Категория влияния на рейтинг
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ alignSelf: 'flex-end', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <SearchBar value={searched} placeholder='Поиск' onChange={searchVal => requestSearch(searchVal)} style={{ width: 300 }} />
        </div>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table aria-label="collapsible table">
            <TableHead style={{ background: '#E7F0FE' }}>
              <TableRow>
                <TableCell className={classes.titleTable}>Id</TableCell>
                <TableCell className={classes.titleTable} align="right">
                  Описание
                </TableCell>
                <TableCell className={classes.titleTable} align="right">
                  Действие
                </TableCell>
                <TableCell className={classes.titleTable}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (row, index) => (
                  <TableRow className={classes.bodyTable} key={index}>
                    <TableCell align="center" component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {/* <input
                        type="text"
                        className={classes.cellInput}
                        value={row.description}
                        onChange={(e) => changeDescription(row.id, e.target.value)}
                      /> */}
                      {row.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        style={{ display: 'flex', justifyContent: 'center' }}
                        control={<Switch onClick={() => activeToggle(row.id, row.active)} checked={row.active} color="primary" />} />
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      <SaveIcon
                        className={classes.btnIcon}
                        onClick={() => saveData(row.id, row.description, row.active)} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>

  );
}

export default AdminRating;

import { createStyles, makeStyles, Typography } from '@material-ui/core'
import WrongIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import CorrectIcon from '@material-ui/icons/SentimentVerySatisfied'
// import WrongIcon from '@material-ui/icons/Close'
// import CorrectIcon from '@material-ui/icons/Check'
import React, { ReactElement } from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    status: { minWidth: 62 },
    diagnosisContainer: {
      flex: 1,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    subTitle: {
      color: theme.palette.primary.main,
      fontSize: 10,
      textTransform: 'uppercase'
    },
    title: {
      fontSize: 20,
      fontWeight: 300
    },
    iconContaner: {
      //   textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      fontSize: 10,
      fontWeight: 500,
      textTransform: 'uppercase',
      minHeight: 60
    },
    correct: { color: theme.palette.success.main },
    wrong: { color: theme.palette.error.main }
  })
)

interface Props {
  succsess: boolean
  subTitle: string
  title: string
}

export default function DiagnosisResultItem({
  succsess,
  subTitle,
  title
}: Props): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.status}>{succsess ? <Correct /> : <Wrong />}</div>
      <div className={classes.diagnosisContainer}>
        <div className={classes.subTitle}>{subTitle}</div>
        <Typography variant='h5'>{title}</Typography>
      </div>
    </div>
  )
}

export function Correct(): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.correct}>
      <div className={classes.iconContaner}>
        <CorrectIcon fontSize='large' />
        <div>Верно</div>
      </div>
    </div>
  )
}
export function Wrong(): ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.wrong}>
      <div className={classes.iconContaner}>
        <WrongIcon fontSize='large' />
        <div>Не Верно</div>
      </div>
    </div>
  )
}

import { useSelector } from 'react-redux';
import { TestingStage } from '../../api/chatApi/chatApi';
import { Task } from '../../api/DomainTypes';
import { AsyncState } from '../../common/Utils';
import { RootState } from '../../store';

type HookType = () => TestingStage;

/**
 * @returns шаг текущей задачи либо -1 для удобства (все опции доступные на том или ином шаге будут не доступны)
 */
const useTestingStage: HookType = () => {
  const task = useSelector<RootState, AsyncState<Task> | undefined>(state => {
    return state.chat.currentTaskState;
  });

  return task?.value?.testingStage || 0;
};

export default useTestingStage;

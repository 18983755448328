import React, { ReactElement } from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import CustomButton from '../../../components/common/CustomButton'
import CustomHeader1 from '../../../components/common/CustomHeader1'
import SlotContainerMasonry from '../../../components/common/Slots/SlotContainerMasonry'
import MainLayout from '../../../components/Layout/MainLayout'
import DiagnosisNecessaryResearch from '../../../components/PageBlocks/Diagnosis/DiagnosisNecessaryResearch'
import DiagnosisPenaltyList from '../../../components/PageBlocks/Diagnosis/DiagnosisPenaltyList'
import DiagnosisResultSlot from '../../../components/PageBlocks/Diagnosis/DiagnosisResultSlot'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

export default function DiagnosticFaliedPage(): ReactElement {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <CustomHeader1>Успех!</CustomHeader1>
      <SlotContainerMasonry>
        <DiagnosisResultSlot />
        1
        <DiagnosisPenaltyList />
        <div></div>
        <DiagnosisNecessaryResearch />
      </SlotContainerMasonry>
      <CustomButton>Вперед!</CustomButton>
    </MainLayout>
  )
}

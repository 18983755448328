import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Paper, TableContainer, createStyles, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../types';
//@ts-ignore
import {
  ComposedModal,
  ModalHeader,
  ModalFooter,
  Button
} from '@carbon/react';
import chatApi from '../../../api/chatApi';
import { usePointProcentStore } from '../../chat/store';


const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      fontFamily: 'Helvetica Neue',
      // margin: '16px 0',
      backgroundColor: '#f4f4f4',
    },
    nameChamp: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      color: '#333333'
    },
    wrapperInfoChamp: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    dataChamp: {
      display: 'flex',
      // alignItems: 'flex-end',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    dataChampItem: {
      display: 'flex',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      marginLeft: 24,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 15,
        fontSize: 12
      }
    },
    lorem: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      margin: '12px 0',
      fontFamily: 'Helvetica Neue',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    btn: {
      borderRadius: 4,
      color: 'white',
      margin: '0 24px 12px 0',
      [theme.breakpoints.down('xs')]: {
        fontSize: 7,
        width: 100
      }
    },
    text_gr: {
      backgroundColor: '#a7f0ba',
      padding: '3px 10px',
      borderRadius: 20,
      marginLeft: 10,
      color: '#3e8d55',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 2,
        marginTop: 5,
        fontSize: 10
      }
    },
    text_bl: {
      backgroundColor: '#d0e2ff',
      padding: '3px 10px',
      borderRadius: 20,
      marginLeft: 10,
      color: '#346bda',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 2,
        marginTop: 5,
        fontSize: 10
      }
    },
    text_close: {
      backgroundColor: '#e0e0e0',
      padding: '3px 10px',
      borderRadius: 20,
      marginLeft: 10,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 2,
        marginTop: 5,
        fontSize: 10
      }
    }
  })
)

const ChampionshipsItem = ({ championship, setToggle }) => {
  const history = useHistory();
  const style = useStyles()
  const [open, setOpen] = useState(false);
  const [champId, setIdChamp] = useState(0);
  const [date, setDate] = useState(0);

  const setChampionships = usePointProcentStore(state => state.getChampionshipsAll)

  const dataStart = moment(championship.date_start).format('L')
  const dataStop = moment(championship.date_stop).format('L')


  const idChamp = (id: number) => {
    setOpen(true)
    setIdChamp(id)
  }

  const newxItem = (id: number, reg: any) => {
    localStorage.setItem('idChamp', String(id));
    history.push({ pathname: RoutePath.championshipItem, state: { registration: reg, s: setToggle() } })
  }

  const registerChamp = async () => {
    await chatApi.getRegisterChamp(champId)
      .then(res => {
        setOpen(false)
        setToggle(true)
        setChampionships()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    setDate(new Date().getTime());
    const timer = setInterval(() => {
      setDate(new Date().getTime());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    }
  }, []);


  return (
    <TableContainer style={{ margin: '16px 0 20px 0' }} component={Paper}>
      <div className={style.wrapper}>
        <div style={{ padding: '44px 24px 0px 24px' }}>
          <div className={style.wrapperInfoChamp}>
            <div className={style.nameChamp}>{championship.championship_name}</div>
            <div className={style.dataChamp}>
              <div className={style.dataChampItem}>
                {dataStart} - {dataStop}
              </div>
              {new Date(championship.date_start).getTime() < date && new Date(championship.date_stop).getTime() < date
                ?
                <div className={style.text_close}>Завершен</div>
                :
                null
              }
              {new Date(championship.date_start).getTime() <= date && new Date(championship.date_stop).getTime() >= date
                ?
                <div className={style.text_bl}>Уже идет</div>
                :
                null
              }
              {new Date(championship.date_stop).getTime() > date && new Date(championship.date_start).getTime() > date && !championship.registration
                ?
                <div className={style.text_gr}>Регистрация открыта</div>
                :
                null
              }
              {championship.registration
                ?
                <div className={style.text_gr}>Зарегистрирован</div>
                :
                null
              }
            </div>
          </div>
          <div className={style.lorem}>
            {championship.championship_descriptoon}
          </div>
        </div>

        <div style={{ textAlign: 'end' }}>
          <Button
            size={window.innerWidth > 400 ? 'lg' : 'sm'}
            className={style.btn}
            variant='contained'
            onClick={() => newxItem(championship.id, championship.registration)}
          >
            Открыть
          </Button>
          {!championship.registration ?
            <Button
              size={window.innerWidth > 400 ? 'lg' : 'sm'}
              className={style.btn}
              variant='contained'
              disabled={date > new Date(championship.date_start).getTime() || championship.registration ? true : false}
              onClick={() => idChamp(championship.id)}
            >
              Зарегистрироваться
            </Button>
            :
            null
          }

        </div>
        <ComposedModal open={open} >
          <ModalHeader
            style={{ padding: '25px 15px' }}
            title="Вы хотите зарегистрироваться?"
          />
          <ModalFooter
          >
            <Button kind='secondary' onClick={() => setOpen(false)}>Нет</Button>
            <Button onClick={registerChamp}>Да</Button>
          </ ModalFooter>
        </ComposedModal>
      </div>
    </TableContainer>
  )
}

export default ChampionshipsItem

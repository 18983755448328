import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Grid, Slider, Typography, InputAdornment, Radio, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import MainLayout from '../../components/Layout/MainLayout';
import NewChatWizardTopMenu from '../startChatWizard/NewChatWizardTopMenu';
import Man from '../../assets/img/common/man.svg';
import Circle from '../../assets/img/common/circle.svg';
import Cross from '../../assets/img/common/cross.svg';
import { makeStyles } from '@material-ui/core';
import chatApi from '../../api/chatApi';
import CustomButton from '../../components/common/CustomButton';
import { useHistory, useLocation } from 'react-router-dom';
import { getTestingstageTaskOnTask } from '../../api/chatApi/chatApi';
import SlotCondition from '../../components/common/Slots/SlotCondition';

const useStyles = makeStyles(() => ({
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '50%',
    margin: '40px auto 0px auto',
    overflow: 'hidden',
    textAlign: 'center',
    color: 'black',
    fontSize: '1.1rem',
    fontWeight: 400,
  },
  manImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  markerImage: {
    position: 'absolute',
    height: 'auto',
  },
  largeMarker: {
    width: '30%',
  },
  smallMarker: {
    width: '20%',
  },
  radioGroup: {
    display: 'flex',
    color: 'black',
    fontSize: '1.1rem',
    fontWeight: 400,
  },
  radioLabel: {
    marginBottom: 0,
    height: '30px',
    whiteSpace: 'nowrap',
  },
  resultContainer: {
    flex: 1,
    border: '1px solid grey',
    padding: '10px',
    borderRadius: '4px',
    marginTop: '16px',
    marginLeft: '16px',
  },
  layoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  layoutContainerStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
  },
  slotContainer: {
    border: '2px solid rgba(0, 0, 0, 0.12)',
    background: '#fff',
    borderRadius: '20px',
    padding: '6px',
    flex: '1',
    minWidth: '200px',
  },
  gridItemFullWidth: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  '@media (max-width: 1024px)': {
    gridItemFullWidth: {
      flexDirection: 'column !important',
    },
    resultContainer: {
      marginLeft: '0 !important',
      marginTop: '16px !important',
    },
    slotContainer: {
      marginBottom: '16px !important',
    },
  },
}));

interface IStages {
  activeStage: number,
  code: string,
  routePath: string,
  routhPathCode: string,
  testingstage_descr: string
  testingstage_id: number
}

interface IUserJoint {
  id: number,
  jointId: number,
  jointType: number,
  userTaskProceedId: number,
}

interface IRheumatologyState {
  id: number,
  value: string,
}

export default function RheumatologyPage() {
  const c = useStyles();
  const [generalHealth, setGeneralHealth] = useState<number>(0);
  const [selectedRadio, setSelectedRadio] = useState<number | null>(null);
  const [rheumatologyState, setRheumatologyState] = useState<number | null>(null);
  const [rheumatologyStates, setRheumatologyStates] = useState<IRheumatologyState[]>([])
  const [stages, setStages] = useState<IStages[]>([]);
  const [userJoints, setUserJoints] = useState<IUserJoint[]>([]);
  const [painfulJoints, setPainfulJoints] = useState<number>(0);
  const [swollenJoints, setSwollenJoints] = useState<number>(0);
  const [soe, setSoe] = useState<number>(0);
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    fetchAllStages();
    getTaskProceedJoint();
    getRheumatologyStateAll();
    getRheumatologyProceedState()
  }, []);

  async function createRheumatologyProceedState() {
    try {
      if (selectedRadio) await chatApi.createRheumatologyUserTaskProceedState(selectedRadio)
    } catch (error) {
      console.log('Ошибка createRheumatologyProceedState', error)
    }

  }

  async function getRheumatologyProceedState() {
    try {
      const response = await chatApi.getRheumatologyUserTaskProceedState();
      const stateId = response.result.rheumatologyStateId;
      if (typeof stateId === 'number') {
        setRheumatologyState(stateId);
        setSelectedRadio(stateId);
      }
    } catch (error) {
      console.log('Ошибка при getRheumatologyUserTaskProceedState', error);
    }
  }


  async function getTaskProceedJoint() {
    try {
      const result = await chatApi.getUserTaskProceedJoint();
      setUserJoints(result.result);
    } catch (error) {
      console.log('Ошибка getTaskProceedJoint:', error)
    }
  }

  // Функция для получения этапов
  async function fetchAllStages() {
    try {
      const result = await getTestingstageTaskOnTask();
      setStages(result.data.testingstage_task_on_task);
    } catch (error) {
      console.error('Ошибка при загрузке этапов:', error);
    }
  }

  // Функция для перехода на следующий этап
  async function goToNextStage() {
    const currentPath = location.pathname;
    const currentStageObj = stages.find(stage => stage.routePath === currentPath);
    if (!currentStageObj) return;

    setHistoryStage(currentStageObj.activeStage)
    const currentActiveStage = currentStageObj.activeStage;

    // Найти следующий этап с наименьшим activeStage, который больше текущего activeStage
    const nextStageObj = stages
      .filter(stage => stage.activeStage > currentActiveStage)
      .sort((a, b) => a.activeStage - b.activeStage)[0];

      if (nextStageObj && nextStageObj.legend_id) {
        history.push(RoutePath.legend);
      } else if (nextStageObj) {
        history.push(nextStageObj.routePath);
      }
  }

  async function setHistoryStage(testingStage) {
    try {
      await chatApi.setTestingStageHistory(testingStage)
    } catch (error) {
      console.log('Ошибка в setHistoryStage', error)
    }
  }

  function nextStage() {
    createRheumatologyProceedState()
    if (stages) {
      goToNextStage();
      return;
    }
  }

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const getRheumatologyStateAll = async () => {
    try {
      const arrayOfStates = await chatApi.getAllRheumatologyState()
      setRheumatologyStates(arrayOfStates.result)
    } catch (error) {
      console.log('Ошибка getAllRheumatologyState', error)
    }
  }

  // Массивы с позициями для кружков и крестиков
  const swollenCircles = [
    //Левая часть тела
    { id: 1, top: '41%', left: '59.4%', isLarge: true }, // 'Левый большой 1'
    { id: 2, top: '49.5%', left: '63.4%' }, // 'Левый большой 2'
    { id: 3, top: '52.5%', left: '60%' }, // Левый указательный 1
    { id: 4, top: '59%', left: '61.3%' }, //Левый указательный 2
    { id: 5, top: '53.8%', left: '58.1%' }, //Левый средний 1
    { id: 6, top: '61.3%', left: '59.1%' }, //'Левый средний 2'
    { id: 7, top: '53.9%', left: '56.4%' }, // 'Левый безымянный 1'
    { id: 8, top: '61%', left: '56.3%' }, //'Левый безымянный 2'
    { id: 9, top: '54.1%', left: '54.7%' }, // 'Левый мизинец 1'
    { id: 10, top: '59.4%', left: '54.4%' }, //'Левый мизинец 2'
    { id: 11, top: '33.2%', left: '53.4%' }, // 'Левая кисть'
    { id: 12, top: '26%', left: '51.6%' }, //'Левый локоть'
    { id: 13, top: '13.9%', left: '50.7%' }, //'Левое плечо'
    { id: 14, top: '45.5%', left: '47%', isLarge: true }, //'Левое колено'
    // Правая часть тела
    { id: 15, top: '40.5%', left: '31.3%', isLarge: true }, //'Правый большой 1'
    { id: 16, top: '49.5%', left: '30%' }, //'Правый большой 2'
    { id: 17, top: '52.5%', left: '33.5%' }, //'Правый указательный 1'
    { id: 18, top: '59%', left: '32.2%' }, //'Правый указательный 2'
    { id: 19, top: '53.8%', left: '35.3%' }, //'Правый средний 1'
    { id: 20, top: '61.3%', left: '34.4%' }, //'Правый средний 2'
    { id: 21, top: '53.9%', left: '37%' }, //'Правый безымянный 1'
    { id: 22, top: '61%', left: '37%' }, // 'Правый безымянный 2'
    { id: 23, top: '54.1%', left: '38.8%' }, // 'Правый мизинец 1'
    { id: 24, top: '59.4%', left: '39%' }, //'Правый мизинец 2'
    { id: 25, top: '33.2%', left: '40.1%' }, //'Правая кисть'
    { id: 26, top: '26%', left: '41.8%' }, //'Правый локоть'
    { id: 27, top: '13.9%', left: '42.8%' }, //'Правое плечо'
    { id: 28, top: '45.5%', left: '43.4%', isLarge: true }, //'Правое колено'
  ];

  const painfulCircles = [
    //Левая часть тела
    { id: 1, top: '41%', left: '59.4%', isLarge: true }, // 'Левый большой 1'
    { id: 2, top: '49.5%', left: '63.4%' }, // 'Левый большой 2'
    { id: 3, top: '52.5%', left: '60%' }, // Левый указательный 1
    { id: 4, top: '59%', left: '61.3%' }, //Левый указательный 2
    { id: 5, top: '53.8%', left: '58.1%' }, //Левый средний 1
    { id: 6, top: '61.3%', left: '59.1%' }, //'Левый средний 2'
    { id: 7, top: '53.9%', left: '56.4%' }, // 'Левый безымянный 1'
    { id: 8, top: '61%', left: '56.3%' }, //'Левый безымянный 2'
    { id: 9, top: '54.1%', left: '54.7%' }, // 'Левый мизинец 1'
    { id: 10, top: '59.4%', left: '54.4%' }, //'Левый мизинец 2'
    { id: 11, top: '33.2%', left: '53.4%' }, // 'Левая кисть'
    { id: 12, top: '26%', left: '51.6%' }, //'Левый локоть'
    { id: 13, top: '13.9%', left: '50.7%' }, //'Левое плечо'
    { id: 14, top: '45.5%', left: '47%', isLarge: true }, //'Левое колено'

    // Правая часть тела
    { id: 15, top: '40.5%', left: '31.3%', isLarge: true }, //'Правый большой 1'
    { id: 16, top: '49.5%', left: '30%' }, //'Правый большой 2'
    { id: 17, top: '52.5%', left: '33.5%' }, //'Правый указательный 1'
    { id: 18, top: '59%', left: '32.2%' }, //'Правый указательный 2'
    { id: 19, top: '53.8%', left: '35.3%' }, //'Правый средний 1'
    { id: 20, top: '61.3%', left: '34.4%' }, //'Правый средний 2'
    { id: 21, top: '53.9%', left: '37%' }, //'Правый безымянный 1'
    { id: 22, top: '61%', left: '37%' }, // 'Правый безымянный 2'
    { id: 23, top: '54.1%', left: '38.8%' }, // 'Правый мизинец 1'
    { id: 24, top: '59.4%', left: '39%' }, //'Правый мизинец 2'
    { id: 25, top: '33.2%', left: '40.1%' }, //'Правая кисть'
    { id: 26, top: '26%', left: '41.8%' }, //'Правый локоть'
    { id: 27, top: '13.9%', left: '42.8%' }, //'Правое плечо'
    { id: 28, top: '45.5%', left: '43.4%', isLarge: true }, //'Правое колено'
  ];

  const filteredPainfulCrosses = useMemo(() => {
    const painfulCrosses = [
      //Левая часть тела
      { id: 1, top: '38.4%', left: '61%', isLarge: true }, // 'Левый большой 1'
      { id: 2, top: '42.5%', left: '63.4%' }, // 'Левый большой 2'
      { id: 3, top: '45.5%', left: '60%' }, // Левый указательный 1
      { id: 4, top: '52%', left: '61.3%' }, //Левый указательный 2
      { id: 5, top: '46.8%', left: '58.1%' }, //Левый средний 1
      { id: 6, top: '54.3%', left: '59.1%' }, //'Левый средний 2'
      { id: 7, top: '46.9%', left: '56.4%' }, // 'Левый безымянный 1'
      { id: 8, top: '54%', left: '56.3%' }, //'Левый безымянный 2'
      { id: 9, top: '47.1%', left: '54.7%' }, // 'Левый мизинец 1'
      { id: 10, top: '52.4%', left: '54.4%' }, //'Левый мизинец 2'
      { id: 11, top: '26.2%', left: '53.4%' }, // 'Левая кисть'
      { id: 12, top: '19%', left: '51.6%' }, //'Левый локоть'
      { id: 13, top: '6.9%', left: '50.7%' }, //'Левое плечо'
      { id: 14, top: '43%', left: '48.6%', isLarge: true }, //'Левое колено'
      // Правая часть тела
      { id: 15, top: '37.9%', left: '32.9%', isLarge: true }, //'Правый большой 1'
      { id: 16, top: '42.5%', left: '30%' }, //'Правый большой 2'
      { id: 17, top: '45.5%', left: '33.5%' }, //'Правый указательный 1'
      { id: 18, top: '52%', left: '32.2%' }, //'Правый указательный 2'
      { id: 19, top: '46.8%', left: '35.3%' }, //'Правый средний 1'
      { id: 20, top: '54.3%', left: '34.4%' }, //'Правый средний 2'
      { id: 21, top: '46.9%', left: '37%' }, //'Правый безымянный 1'
      { id: 22, top: '54%', left: '37%' }, // 'Правый безымянный 2'
      { id: 23, top: '47.1%', left: '38.8%' }, // 'Правый мизинец 1'
      { id: 24, top: '52.4%', left: '39%' }, //'Правый мизинец 2'
      { id: 25, top: '26.2%', left: '40.1%' }, //'Правая кисть'
      { id: 26, top: '19%', left: '41.8%' }, //'Правый локоть'
      { id: 27, top: '6.9%', left: '42.8%' }, //'Правое плечо'
      { id: 28, top: '43%', left: '44.9%', isLarge: true }, //'Правое колено'
    ];
    return painfulCrosses.filter(cross =>
      userJoints.some(joint => joint.jointId === cross.id && joint.jointType === 1)
    );
  }, [userJoints]);

  const filteredSwollenCrosses = useMemo(() => {
    const swollenCrosses = [
      //Левая часть тела
      { id: 1, top: '38.4%', left: '61%', isLarge: true }, // 'Левый большой 1'
      { id: 2, top: '42.5%', left: '63.4%' }, // 'Левый большой 2'
      { id: 3, top: '45.5%', left: '60%' }, // Левый указательный 1
      { id: 4, top: '52%', left: '61.3%' }, //Левый указательный 2
      { id: 5, top: '46.8%', left: '58.1%' }, //Левый средний 1
      { id: 6, top: '54.3%', left: '59.1%' }, //'Левый средний 2'
      { id: 7, top: '46.9%', left: '56.4%' }, // 'Левый безымянный 1'
      { id: 8, top: '54%', left: '56.3%' }, //'Левый безымянный 2'
      { id: 9, top: '47.1%', left: '54.7%' }, // 'Левый мизинец 1'
      { id: 10, top: '52.4%', left: '54.4%' }, //'Левый мизинец 2'
      { id: 11, top: '26.2%', left: '53.4%' }, // 'Левая кисть'
      { id: 12, top: '19%', left: '51.6%' }, //'Левый локоть'
      { id: 13, top: '6.9%', left: '50.7%' }, //'Левое плечо'
      { id: 14, top: '43%', left: '48.6%', isLarge: true }, //'Левое колено'
      // Правая часть тела
      { id: 15, top: '37.9%', left: '32.9%', isLarge: true }, //'Правый большой 1'
      { id: 16, top: '42.5%', left: '30%' }, //'Правый большой 2'
      { id: 17, top: '45.5%', left: '33.5%' }, //'Правый указательный 1'
      { id: 18, top: '52%', left: '32.2%' }, //'Правый указательный 2'
      { id: 19, top: '46.8%', left: '35.3%' }, //'Правый средний 1'
      { id: 20, top: '54.3%', left: '34.4%' }, //'Правый средний 2'
      { id: 21, top: '46.9%', left: '37%' }, //'Правый безымянный 1'
      { id: 22, top: '54%', left: '37%' }, // 'Правый безымянный 2'
      { id: 23, top: '47.1%', left: '38.8%' }, // 'Правый мизинец 1'
      { id: 24, top: '52.4%', left: '39%' }, //'Правый мизинец 2'
      { id: 25, top: '26.2%', left: '40.1%' }, //'Правая кисть'
      { id: 26, top: '19%', left: '41.8%' }, //'Правый локоть'
      { id: 27, top: '6.9%', left: '42.8%' }, //'Правое плечо'
      { id: 28, top: '43%', left: '44.9%', isLarge: true }, //'Правое колено'
    ];
    return swollenCrosses.filter(cross =>
      userJoints.some(joint => joint.jointId === cross.id && joint.jointType === 2)
    );
  }, [userJoints]);

  // Функция расчета результата по формуле
  const das28 = useMemo(() => {
    const sqrtPainfulJoints = Math.sqrt(painfulJoints);
    const sqrtSwollenJoints = Math.sqrt(swollenJoints);
    const inSoe = soe > 0 ? Math.log(soe) : 0;

    return (
      0.56 * sqrtPainfulJoints +
      0.28 * sqrtSwollenJoints +
      0.7 * inSoe +
      0.014 * generalHealth
    ).toFixed(2);
  }, [painfulJoints, swollenJoints, soe, generalHealth]);


  return (
    <MainLayout
      desktopHeader={<NewChatWizardTopMenu title={'Ревматический статус'} />}
      title="Ревматический статус"
    >
      <div style={{ padding: '20px', paddingTop: '50px' }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Число болезненных суставов"
              type="number"
              value={painfulJoints}
              onChange={handleChange(setPainfulJoints)}
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Число припухших суставов"
              type="number"
              value={swollenJoints}
              onChange={handleChange(setSwollenJoints)}
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="СОЭ"
              type="number"
              value={soe}
              onChange={handleChange(setSoe)}
              inputProps={{ min: 0 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">мм/ч</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography id="discrete-slider" gutterBottom>
              Общая оценка здоровья
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={generalHealth}
                  onChange={handleChange(setGeneralHealth)}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={100}
                />
              </Grid>
              <Grid item>
                <Typography>{generalHealth}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={c.gridItemFullWidth}>
            <div className={c.slotContainer}>
              <SlotCondition title="Ревматический статус" color="primary">
                <FormControl component="fieldset" className={c.radioGroup}>
                  <RadioGroup value={selectedRadio} onChange={handleChange(setSelectedRadio)}>
                    {rheumatologyStates.map((option) => (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={option.value}
                        className={c.radioLabel}
                        disabled={rheumatologyState !== null}
                      />
                    ))}
                  </RadioGroup>

                </FormControl>
              </SlotCondition>
            </div>
            <div className={c.resultContainer}>
              <Typography variant="h6">
                DAS-28 = 0,56 * √ЧБС + 0,28 * √ЧПС + 0,70 * СОЭ + 0,014 * ООЗД
              </Typography>
              <Typography variant="h6">Результат: {das28}</Typography>
            </div>
          </Grid>
          <div className={c.layoutContainer}>
            <div className={c.imageContainer}>Припухшие суставы
              <img alt="homuncolus" src={Man} className={c.manImage} />
              {swollenCircles.map((circle, index) => (
                <img
                  key={`circle-${index}`}
                  alt={`circle-${index}`}
                  src={Circle}
                  className={`${c.markerImage} ${circle.isLarge ? c.largeMarker : c.smallMarker}`}
                  style={{ top: circle.top, left: circle.left }}
                />
              ))}
              {filteredSwollenCrosses.map((cross, index) => (
                <img
                  key={`cross-${index}`}
                  alt={`cross-${index}`}
                  src={Cross}
                  className={`${c.markerImage} ${c.smallMarker}`}
                  style={{ top: cross.top, left: cross.left }}
                />
              ))}
            </div>
            <div className={c.imageContainer}>Болезненные суставы
              <img alt="homuncolus" src={Man} className={c.manImage} />
              {painfulCircles.map((circle, index) => (
                <img
                  key={`circle-${index}`}
                  alt={`circle-${index}`}
                  src={Circle}
                  className={`${c.markerImage} ${circle.isLarge ? c.largeMarker : c.smallMarker}`}
                  style={{ top: circle.top, left: circle.left }}
                />
              ))}
              {filteredPainfulCrosses.map((cross, index) => (
                <img
                  key={`painful-cross-${index}`}
                  alt={`painful-cross-${index}`}
                  src={Cross}
                  className={`${c.markerImage} ${c.smallMarker}`}
                  style={{ top: cross.top, left: cross.left }}
                />
              ))}
            </div>
          </div>
        </Grid>
        <CustomButton onClick={nextStage} disabled={!selectedRadio}>
          Следующий этап
        </CustomButton>
      </div>
    </MainLayout>
  );
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import chatApi from "../../api/chatApi";
import { getMediaContent } from '../../api/chatApi/chatApi';


const useRowStyles = makeStyles({
  current: {
    padding: 20,
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    boxShadow: '0px 0px 2px rgba(9, 36, 51, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: 50,
  },
  current_title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 500,
    margin: '20px 0'
  },
  current_title_honor: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Helvetica Neue',
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 10,
    color: '#9A9A9A',

  }
})

const Prize = ({ id }) => {
  const classes = useRowStyles();
  const [emptyMsg, setEmptyMsg] = useState<string>('')
  const [imgLinks, setImgLinks] = useState<string[]>()

  const getPrize = async () => {
    setImgLinks([])
    try {
      const res = await chatApi.getUsersMarks(id);
      if (res.result.length > 0) {
        const imageLinks = await Promise.all(res.result.map(img => getMediaContent(img)));
        setImgLinks(imageLinks.map(link => link.data.data_base64));
      } else {
        setEmptyMsg('Нет наград');
      }
    } catch (err) {
      console.log(err);
      setEmptyMsg('Произошла ошибка при загрузке наград');
    }
  }


  useEffect(() => {
    getPrize();
  }, [id]);

  return (
    <>
      <div className={classes.current_title}>Награды</div>
      <div className={classes.current}>
        {imgLinks && imgLinks?.length > 0 ? (
          imgLinks.map((imgLink, index) => (
            <div key={index} className={classes.current_title_honor}>
              <img style={{ width: 40, height: 40 }} src={`data:image/jpeg;base64,${imgLink}`} alt="Кубок" />
            </div>
          ))
        ) : (
          <div className={classes.current_title_honor}>{emptyMsg}</div>
        )}
      </div>
    </>
  );

}

export default Prize;
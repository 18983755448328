import { makeStyles, createStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MainHeader from '../../../components/MainHeader/MainHeader'
import FilterChampionships from './FilterChampionships'
import { useChampionships } from '../../../hooks/championships/useChampionships'
import ChampionshipsAll from './ChampionshipsAll'
import MainFooter from '../../../components/MainFooter/MainFooter'
import { getInfoChamp } from '../../../api/chatApi/chatApi'
import { usePointProcentStore } from '../../chat/store'

const useStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    wrapperList: {
      marginBottom: 100,
      width: '90%'
    },
    filter: {
      fontFamily: 'Helvetica Neue',
    },
    error: {
      fontFamily: 'Helvetica Neue',
      fontSize: 25,
      display: 'flex',
      justifyContent: 'center',
      marginTop: 140
    }
  })
)

const ChampionshipsList = () => {
  const style = useStyles()
  // const [championships, setChampionships] = useState([])
  const [filter, setFilter] = useState({ sort: '', query: '', data: '' })
  const [toggle, setToggle] = useState(false);

  const setChampionships = usePointProcentStore(state => state.getChampionshipsAll)
  const championships = usePointProcentStore(state => state.dataChamp)


  // const getChampionshipsAll = async () => {
  //   await getInfoChamp()
  //   .then(res => {
  //     setChampionships(res.data.all_championship)
  //     setToggle(false)
  //   })
  //   .catch(err => console.log(err))
  // }

  const searchAndSortedPost = useChampionships(
    championships,
    filter.sort,
    filter.query,
    filter.data
  )

  useEffect(() => {
    setChampionships()
    // getChampionshipsAll()
  }, [toggle])

  return (
    <>
      <MainHeader />
      <div className={style.mainContainer}>
        <div className={style.wrapperList}>
          <div className={style.filter}>
            {championships ?
              <>
                <FilterChampionships filter={filter} setFilter={setFilter} />
                <ChampionshipsAll championships={searchAndSortedPost} setToggle={setToggle} />
              </>
              :
              <div className={style.error}>Чемпионаты не найдены</div>
            }
          </div>
        </div>
      </div>

      <MainFooter />
    </>
  )
}

export default ChampionshipsList

import React from 'react'

export default function DiagnosisTooltip() {
  return (
    <div>
      На шаге <span>Осмотр пациента</span> рассмотрите особенности внешнего вида пациента

    </div>
  )
}

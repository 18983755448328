import React from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'
import AuthNavigationPanel from '../../components/auth/AuthNavigationPanel'
import SocialButtonsPanel from '../../components/auth/SocialButtonsPanel'
import LoginForm from '../../components/auth/Forms/LoginForm'

export default function Login() {
  return (
    <AuthLayout>
      <AuthNavigationPanel />
      <LoginForm />
      <SocialButtonsPanel />
    </AuthLayout>
  )
}

import { useLocation } from 'react-router-dom';
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles'
import PatientIcon from '@material-ui/icons/AirlineSeatFlatAngled'
import CategoryIcon from '@material-ui/icons/Apps'
import AcademicLevelIcon from '@material-ui/icons/School'
import ClinicLevelIcon from '@material-ui/icons/SignalCellularAlt'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 25
  },
  active: {
    // '& $line': {
    //   backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    // },
  },
  completed: {
    // '& $line': {
    //   backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    // },
  },
  line: {
    height: 2,
    border: 0,
    width: '90%',
    marginLeft: '5%',
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector)

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.text.hint,
    zIndex: 1,
    // color: theme.palette.primary.contrastText,
    color: 'white',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    // backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundColor: theme.palette.primary.main
    // backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }
}))

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles()
  const { active, completed, icon } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <AcademicLevelIcon />,
    2: <ClinicLevelIcon />,
    3: <CategoryIcon />,
    4: <PatientIcon />
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(icon)]}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      width: '100%',
      background: 'none',
      borderRadius: 12,
      textTransform: 'uppercase',
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      marginTop: '0',
      padding: '10px',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
        marginTop: '0'
      }
    },
    stepperLabel: {
      '& span': {
        fontWeight: 'normal',
        fontSize: '16px',
        textTransform: 'none',
        [theme.breakpoints.down('xs')]: {
          fontSize: '9px'
        }
      }
    }
  })
)

const academicSteps = ['Клинический уровень', 'Категория', 'Пациент'];


type Props = {
  activeStep?: 0 | 1 | 2 | 3
  noAcademLevel?: boolean
}



const ChooseTaskStepper: FunctionComponent<Props> = props => {
  const classes = useStyles()

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const universityLvl = searchParams.get('universityLevelId');
  const difficultLvl = searchParams.get('difficultId');
  const category = searchParams.get('categoryId');

  function getStepLabel(type: string, value: string | null) {
    const labels = {
      university: { '1': ': 3', '3': ': 5', '4': ': 6' },
      difficult: {
        '2': ': лёгкий',
        '1': ': средний',
        '3': ': сложный'
      },
      category: {
        '1': ': педиатрия',
        '6': ': терапия',
        '11': ': неврология',
        '5': ': инфекционные заболевания',
        '9': ': акушерство',
        '7': ': гинекология',
        '8': ': репродуктология',
        '15': ': частная патофизиология',
        '20': ': ревматология',
        '19': ': медико-профилактическое дело',
        '18': ': судебная медицина',
        '17': ': фармация',
        '16': ': стоматология',
        '14': ': общая патофизиология',
        '13': ': геронтология',
      }
    };
    return labels[type][value] || '';
  }

  const universityLvlStep = getStepLabel('university', universityLvl);
  const difficultLvlStep = getStepLabel('difficult', difficultLvl);
  const categorytLvlStep = getStepLabel('category', category);

  const levelSteps = [
    `Курс${universityLvlStep}`,
    `Уровень сложности${difficultLvlStep}`,
    `Категория${categorytLvlStep}`,
    'Пациент'
  ];

  return (
    <Stepper
      alternativeLabel
      activeStep={props.activeStep || 0}
      connector={<ColorlibConnector />}
      className={classes.stepper}
    >
      {(props.noAcademLevel ? academicSteps : levelSteps).map((label, index) => (
        <Step key={index}>
          <StepLabel
            className={classes.stepperLabel}
            StepIconComponent={ColorlibStepIcon}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default ChooseTaskStepper;

import React, { useState, useEffect } from 'react';
import './style.css'; // Импорт обновленного файла стилей

interface Form148BackProps {
  onInputChange?: (id: number, value: string) => void;
  initialValues?: { [key: number]: string };
  isReadOnly?: boolean;
  isShow?: boolean;
  onCheckboxStateChange?: (checkboxStates: { [key: number]: boolean }) => void;
  onCheckboxWrongAnswerStateChange?: (checkboxStates: { [key: number]: boolean }) => void;
  isShowWrongAnswerCheckbox?: boolean;
}

export default function Form148Back({
  onInputChange,
  initialValues,
  isReadOnly,
  isShow,
  onCheckboxStateChange,
  onCheckboxWrongAnswerStateChange,
  isShowWrongAnswerCheckbox
}: Form148BackProps) {
  const [purpose, setPurpose] = useState(initialValues?.[1] || '');

  const headers = ['Приготовил', 'Проверил', 'Отпустил'];

  const [checkboxStates, setCheckboxStates] = useState<{ [key: number]: boolean }>(() => {
    const initialCheckboxState: { [key: number]: boolean } = {};
    headers.forEach((_, index) => {
      initialCheckboxState[2 + index] = initialValues?.[2 + index] === 'true';
    });
    return initialCheckboxState;
  });

  const [checkboxWrongAnswerStates, setCheckboxWrongAnswerStates] = useState<{ [key: number]: boolean }>(() => {
    const initialCheckboxState: { [key: number]: boolean } = {};
    headers.forEach((_, index) => {
      initialCheckboxState[2 + index] = false; // Начальное состояние для чекбоксов "неправильных ответов"
    });
    return initialCheckboxState;
  });

  useEffect(() => {
    if (initialValues) {
      setPurpose(initialValues[1] || '');
      headers.forEach((_, index) => {
        const id = 2 + index;
        setCheckboxStates(prev => ({
          ...prev,
          [id]: initialValues[id] === 'true'
        }));
      });
    }
  }, [initialValues]);

  const handleInputChangeInternal = (id: number, value: string) => {
    if (onInputChange) {
      onInputChange(id, value);
    }
    switch (id) {
      case 1:
        setPurpose(value);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (id: number) => {
    setCheckboxStates(prev => {
      const newState = { ...prev, [id]: !prev[id] };
      onCheckboxStateChange?.(newState);
      return newState;
    });
  };

  const handleWrongAnswerCheckboxChange = (id: number) => {
    setCheckboxWrongAnswerStates(prev => {
      const newState = { ...prev, [id]: !prev[id] };
      onCheckboxWrongAnswerStateChange?.(newState);
      return newState;
    });
  };

  return (
    <div className={'form148BackContainer'}>
      <div className={'form148Back-title'}>
        <span>Обратная сторона</span>
      </div>

      <div className={'form148Back-content-block'}>
        <div className={'form148Back-block'}>
          <span>Отметка о назначении лекарственного препарата по решению врачебной комиссии</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <input
            id="purpose-input"
            className={'form148Back-input'}
            onChange={(event) => {
              setPurpose(event.target.value);
              handleInputChangeInternal(1, event.target.value);
            }}
            type="text"
            value={purpose}
            disabled={isReadOnly}
          />
          {isShow && (
            <input
              id="checkbox-1"
              type="checkbox"
              checked={checkboxStates[1] || false}
              onChange={() => handleCheckboxChange(1)}
            />
          )}
          {isShowWrongAnswerCheckbox && (
            <input
              className="checkbox-wrong-answer"
              id="checkbox-1-1"
              type="checkbox"
              checked={checkboxWrongAnswerStates[1] || false}
              onChange={() => handleWrongAnswerCheckboxChange(1)}
            />
          )}
        </div>
      </div>

      <div className={'form148Back-header-block'}>
        {headers.map((header, index) => {
          const id = 2 + index;
          return (
            <div key={id} className={'form148Back-header-item'}>
              <div className={'form148Back-header-title'}>
                <span>{header}</span>
              </div>
              <div className={'form148Back-header-input'}>
                <input
                  style={{ width: '98%', border: 'none' }}
                  onChange={event => handleInputChangeInternal(id, event.target.value)}
                  type="text"
                  value={initialValues?.[id] || ''}
                  disabled={isReadOnly}
                />
                {isShow && (
                  <input
                    id={`checkbox-${id}`}
                    type="checkbox"
                    checked={checkboxStates[id] || false}
                    onChange={() => handleCheckboxChange(id)}
                  />
                )}
                {isShowWrongAnswerCheckbox && (
                  <input
                    className="checkbox-wrong-answer"
                    id={`checkbox-1-${id}`}
                    type="checkbox"
                    checked={checkboxWrongAnswerStates[id] || false}
                    onChange={() => handleWrongAnswerCheckboxChange(id)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { ReactElement } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '15px 0',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      color: (p: Props) =>
        p.status === 'inactive'
          ? theme.palette.text.disabled
          : p.status === 'active'
            ? theme.palette.primary.main
            : theme.palette.text.secondary,
      cursor: (p: Props) => (p.status === 'complete' ? 'pointer' : 'cursor')
    },
    stepNumber: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      minWidth: 38,
      minHeight: 38,
      width: 38,
      height: 38,
      borderRadius: 19,
      fontWeight: 600,
      // backgroundImage: (p: Props) =>
      //   p.status === 'inactive'
      //     ? `url(${theme.custom.stepCircleDashedBorder})`
      //     : 'none',
      border: (p: Props) => (p.status === 'inactive' ? 'none' : 'solid 2px'),

      borderColor: (p: Props) =>
        p.status === 'inactive'
          ? theme.palette.text.disabled
          : p.status === 'active'
            ? theme.palette.primary.main
            : theme.palette.text.secondary
    },
    stepTitle: {
      marginLeft: 12,
      lineHeight: 1.2,
      fontWeight: (p: Props) => (p.status === 'active' ? 'bold' : 'normal'),
      letterSpacing: '0.05em'
    }
  })
)
interface Props {
  number?: number
  title?: string
  name?: string
  onClick?: () => void
  status?: 'inactive' | 'active' | 'complete'
}

export default function SidebarStep({
  number,
  title,
  status,
  name,
  onClick
}: Props): ReactElement {
  const classes = useStyles({ status } as Props)
  return (
    <div className={classes.root} onClick={status === 'complete' ? onClick : undefined}>
      <div className={classes.stepNumber}>
        <div>{number}</div>
      </div>
      {title ? <div className={classes.stepTitle}>{title}</div> : <div className={classes.stepTitle}>{name}</div>}
    </div>
  )
}

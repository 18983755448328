import React, { ReactElement } from 'react'
import ChatPagesMenu from '../../../components/Chat/ChatPagesMenu'
import CustomButton from '../../../components/common/CustomButton'
import CustomHeader1 from '../../../components/common/CustomHeader1'
import SlotContainerMasonry from '../../../components/common/Slots/SlotContainerMasonry'
import MainLayout from '../../../components/Layout/MainLayout'
import TreatmentResultSlot from '../../../components/PageBlocks/Treatment/TreatmentResultSlot'
import { CHAT_MENU_ITEMS } from '../../../constants/default'

export type iTreatResItem = {
  success: boolean
  title: string
  text: string
  points: number
  quantity?: number
  link?: any
}
export type iTreatResItems = iTreatResItem[]

const MED_RESULTS: iTreatResItems = [
  {
    success: false,
    title: 'Ацетилсалициловая кислота',
    text: 'таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 15 дней',
    points: -120
  },
  {
    success: true,
    title: 'Аспирин',
    text:
      'Аспирин, таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 71 день',
    points: 250
  }
]
const FOOD_RESULTS: iTreatResItems = [
  {
    success: true,
    title: 'Мед',
    text: 'таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 15 дней',
    points: 420
  },
  {
    success: true,
    title: 'Лимон',
    text: 'таблетки, 2 таблетки, перорально, 3000ME, 2 раза в 1 день, 71 день',
    points: 250
  }
]
const BEHAVIOR_RESULTS: iTreatResItems = [
  { success: false, title: 'Сон', text: '3 раза в день', points: -120 },
  { success: true, title: 'Свежий воздух', text: '2 раза в день', points: 250 }
]

export default function TreatmentResultPage(): ReactElement {
  return (
    <MainLayout desktopHeader={<ChatPagesMenu menuItems={CHAT_MENU_ITEMS} />}>
      <CustomHeader1>Результат Лечения</CustomHeader1>
      <SlotContainerMasonry>
        <TreatmentResultSlot
          title='Медикаментозное лечение'
          treatmentResults={MED_RESULTS}
        />
        <TreatmentResultSlot title='Питание' treatmentResults={FOOD_RESULTS} />
        <TreatmentResultSlot
          title='Режим'
          treatmentResults={BEHAVIOR_RESULTS}
        />
      </SlotContainerMasonry>
      <CustomButton>Вперед!</CustomButton>
    </MainLayout>
  )
}

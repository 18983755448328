import React, { useEffect, useState } from 'react'
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Theme,
  Pagination,
  ToastNotification,
  ComboBox,
  Loading
} from '@carbon/react';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import { allUsers, getAssessmentProfessionalStandard } from "../../api/chatApi/chatApi";
import chatApi from "../../api/chatApi";
import jwt from 'jsonwebtoken'
import { TablePagination } from '@material-ui/core';
import CustomTablePagination from '../CustomPagination';

const useStyles = makeStyles((theme: any) => ({
  requiredWarning: {
    '& .cds--toast-notification__details': {
      display: 'flex !important',
      alignItems: 'center !important',
      height: 50
    },
  },
  championTablePageContainer: {
    background: '#F4F4F4',
    // height: '100vh',
    borderRadius: 4,
    '@media (max-width: 800px)': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
  championTablePageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '98%',
    margin: '0 auto',
    height: ''
  },
  championTableFilterBlock: {
    // width: 360,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    background: '#FFFFFF',
    marginRight: '1%',
    width: '100%',
    marginBottom: '1%',
    borderRadius: 4,
    // height: 510,
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlock: {
    display: 'flex',
    // justifyContent: 'space-between',
    // marginBottom: 8,
    // marginRight: '50px',
    alignItems: 'flex-end',
    width: '100%',
    '& .cds--dropdown': {
      minWidth: 200
    },
    '& .cds--list-box': {
      // width: 225
    },
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15
    },
  },
  championSelectBlockText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 15,
    '@media (max-width: 800px)': {
      flexDirection: 'column',
      marginBottom: 15,
      alignSelf: 'start'
    },
  },
  championSelectElement: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'baseline',
    '@media (max-width: 800px)': {
      flexDirection: 'column'
    },
  },
  championSelectBlockMultiSelect: {
    display: 'flex',
    marginBottom: 8,
    width: 300
  },
  championTable: {
    width: '100%',
    marginRight: '1%',
    background: '#FFFFFF',
    overflowY: 'auto',
    '& .cds--data-table-content': {
      maxHeight: '65vh',
    }
  },
  maxBallsBlock: {
    background: '#FFFFFF',
    padding: '20px 10px',
    display: 'flex',
    marginBottom: 16,
    borderRadius: 4,
    // boxShadow: '0px -5px 5px -5px rgba(34, 60, 80, 0.48) inset'
  },
  myResultsBlock: {
    padding: '15px 10px',
    background: '#FFFFFF',
    height: 230,
  },
  maxBalls: {
    borderRight: '2px solid #DADADA',
    width: 220,
  },
  allParticipants: {
    marginLeft: 30,
  },
  titleBlocks: {
    fontFamily: 'Helvetica Neue',
    fontSize: 20,
    lineHeight: '23px',
    fontWeight: 400,
    marginBottom: '6%',
  },
  statisticsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    marginTop: '24px',
  },
  datePickerContainer: {
    display: 'flex',
    width: '100%',
    marginRight: 50,
    '& div.cds--date-picker': {
      display: 'flex',
      maxWidth: 225,
      width: '100%'
    },
    '@media (max-width: 800px)': {
      '& div.cds--date-picker': {
        flexDirection: 'column'
      },
    },
    '& .cds--date-picker__input': {
      maxWidth: 225,
      width: '100%',
      minWidth: 140
    },
    '& .cds--date-picker--range > .cds--date-picker-container': {
      width: '100%',
      marginRight: '15px',
    }
  },
  formationButtonBlock: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'center',
    maxWidth: 225,
  },
  customBtn: {
    borderRadius: 4,
    height: 40,
    width: 175,
    maxWidth: 225,
    background: '#0f62fe',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: 15,
    '&:hover': {
      background: '#0050e6'
    },
    '@media (max-width: 800px)': {
      width: 225,
    },
  },

  comboBoxContainer: {
    marginRight: 15,
    width: '100%',
    display: 'flex',
    // alignItems: 'baseline',
    '@media (max-width: 800px)': {
      marginBottom: 15,
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  comboboxText: {
    whiteSpace: 'pre',
    fontSize: 14,
    fontFamily: 'Helvetica Neue',
    alignSelf: 'center',
    marginRight: 5,
    '@media (max-width: 800px)': {
      alignSelf: 'start',
    },
  },
  customComboBox: {
    width: 325,
    maxWidth: 345,
    minWidth: 300,
    '@media (max-width: 800px)': {
      maxWidth: 225,
      minWidth: 200
    },
    '& .cds--combo-box': {
      borderRadius: 4
    }
  },
  emptyTableText: {
    whiteSpace: 'pre',
    fontSize: 20,
    fontFamily: 'Helvetica Neue',
    fontWeight: 500,
    '@media (max-width: 800px)': {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
    },
  },
}))

interface AssessmentProfessionalStandardInterface {
  countCompleteTask: number
  countPatientTask: number
  countFailedTask: number
  allUsersArray: unknown[]
}

interface getAssessmentProfessionalStandardInterface {
  id: number,
  kind: string,
  serverresponseobject: number,
  type_competence: number,
  values: number,
  competence_name: string
}

interface OneUser {
  fio: string
  jhi_user_id: number
  login: string
  organization: []
  trainer: []
  user_id: number
}

interface UserInfo {
  active: boolean,
  id: number,
  login: string,
  name: string,
  photo: string,
  surname: string
}

export default function ChampionTablePage({
  allUsersArray,
}: AssessmentProfessionalStandardInterface): JSX.Element {

  const style = useStyles()

  const [allAssessment, setAllAssessment] = useState<getAssessmentProfessionalStandardInterface[]>([])
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [userInfo, setUserInfo] = useState<UserInfo>()
  // const [allUsersArray, setAllUsersArray] = useState<unknown[]>([])
  const [body, setBody] = useState({
    start_date: '',
    stop_date: '',
    login: ''
  })
  const [maxValue, setMaxValue] = useState(0)
  const [nameCategories, setNameCategories] = useState('')
  const roleDecode = jwt.decode(localStorage.getItem('role'))
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [errorState, setErrorState] = useState(false)
  const [requiredFieldsState, setRequiredFieldsState] = useState(false)
  const [page, setPage] = useState(0);
  const role = roleDecode.auth.split(',')

  const headerData = [
    {
      key: 'kind',
      header: 'Специальность',
    },
    {
      key: 'category',
      header: 'Категория',
    },
    {
      key: 'competence_name',
      header: 'Наименование компетенции',
    },
    {
      key: 'serverresponseobject',
      header: 'Процент освоения',
    },
    {
      key: 'values',
      header: 'Необходимое значение %',
    },
    {
      key: 'accordance',
      header: 'Соответствие',
    },
  ];

  useEffect(() => {
    if (allUsersArray) {
      getUserInfoHandler()
    }
    // getAllUsersHandler()
  }, [])

  useEffect(() => {
    if (requiredFieldsState) {
      setTimeout(() => setRequiredFieldsState(false), 3000)
    }
  }, [requiredFieldsState])


  const handleStartDateChange = (date: Date) => {
    setStartTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      start_date: res,
    })
  }

  const handleEndDateChange = (date: Date) => {
    setEndTime(date)
    const res = new Date(date).toLocaleDateString().split(/\.|\//).reverse().join('-')
    setBody({
      ...body,
      stop_date: res
    })
  };


  async function getAssessmentProfessionalStandardHandler() {

    try {
      if (!endTime || !startTime || !userInfo) {
        setRequiredFieldsState(true);
        return
      }
      setIsLoading(true)
      const res = await getAssessmentProfessionalStandard(body)
      // let min = res.data.assessment_professional_standard.reduce((first, second) =>
      // Math.min.apply(first.serverresponseobject, second.serverresponseobject))
      let minArr = []
      res.data.assessment_professional_standard.map(item => {
        minArr.push(item.serverresponseobject)
      })
      res.data.assessment_professional_standard.length === 0 ?
        setErrorState(true) :
        setErrorState(false)
      let min = Math.max.apply(null, minArr)
      setMaxValue(min)
      res.data.assessment_professional_standard.map(item => {
        if (item.serverresponseobject === min) {
          setNameCategories(item.competence_name)
        }
      })
      setAllAssessment(res.data.assessment_professional_standard)
    } catch (err) {
      setErrorState(true)
      setAllAssessment([])
      console.log('при получении списка произошла ошибка', err);
    } finally {
      setTimeout(() => setErrorState(false), 5000)
      setIsLoading(false)
    }
  }

  // async function getAllUsersHandler() {
  // 	await allUsers().then(res => {
  // 		setAllUsersArray(res.data.all_users)
  // 		console.log('getAllUsersHandler', res)
  // 	})
  // }

  async function getUserInfoHandler() {
    try {
      const response = await chatApi.getUserInfo()
      setUserInfo(response.result)
      setBody({ ...body, login: response?.result?.login })
      if (role.includes('ROLE_ADMIN') || role.includes('ROLE_ADMIN_TRAINER') || role.includes('ROLE_ADMIN_ORG')) {
        allUsersArray.filter(user => user)
      } else {
        allUsersArray.filter(user => user.login.includes(response.result.login))
      }
    } catch (err) {
      console.log('при получении данных о пользователе произошла ошибка', err)
    }
  }

  function changeUserHandler(value) {
    setUserInfo(value);
    setBody({ ...body, login: value ? value.login : null });
  }

  function itemToString(item) {
    return item && item.fio !== " " ? item.fio : item?.login ?? '';
  }

  const [isLoading, setIsLoading] = useState(false)

  function Loader() {
    if (isLoading) {
      return (
        <Loading className={'some-class'} withOverlay={false} />
      )
    } else return null
  }

  const indexOfFirstItem = page * currentPageSize;
  const indexOfLastItem = indexOfFirstItem + currentPageSize;
  const currentData = allAssessment.slice(indexOfFirstItem, indexOfLastItem);



  return (
    <div className={style.championTablePageContainer}>
      {
        errorState &&
        <div style={{ position: 'fixed', right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="error"
            hideCloseButton={false}
            lowContrast={false}
            iconDescription="Нет результатов"
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title="Нет результатов"
          />
        </div>
      }
      {requiredFieldsState && (
        <div style={{ position: "fixed", right: 0, zIndex: 1000 }}>
          <ToastNotification
            kind="warning"
            hideCloseButton={true}
            lowContrast={false}
            className={style.requiredWarning}
            style={{
              paddingBottom: 7,
              borderRadius: 4,
              width: 300,
              backgroundColor: '#656565',
              color: 'white'
            }}
            timeout={4000}
            title=""
          ><span>Обязательные поля не заполнены</span></ToastNotification>
        </div>
      )}
      <div style={{ width: '98%', padding: '20px 0', margin: '0 auto', borderRadius: 4 }}>
        <p style={{ fontFamily: 'Helvetica Neue', fontWeight: 400, fontSize: 32 }}>
          Соответствие профессиональному стандарту
        </p>
      </div>

      <div
        style={{
          background: '#FFFFFF',
          fontSize: '104%',
          width: '98%',
          padding: '15px 10px',
          margin: 'auto auto 12px auto',
          borderRadius: 4,
          fontFamily: 'Helvetica Neue'
        }}>
        <p>На основании накопленной информации при прохождении ситуационных задач и матрицы оценки профессиональных
          компетенций система определяет соответствие профессиональным компетенциям выбранного пользователя.
        </p>
      </div>

      <div className={style.championTablePageContent}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={style.championTableFilterBlock}>
            <div className={style.championSelectBlock}>
              <div className={style.championSelectElement}>
                {allUsersArray && allUsersArray.length > 0 ? (
                  <div className={style.comboBoxContainer}>
                    <p className={style.comboboxText}>
                      Отображать результаты студента
                    </p>
                    <ComboBox
                      id={'champion-table-page'}
                      className={style.customComboBox}
                      placeholder={"Выберите пользователя"}
                      items={allUsersArray ? allUsersArray : []}
                      itemToString={(item: OneUser) =>
                        item && item.login !== null && item.fio !== " "
                          ? item.fio
                          : item?.login
                      }
                      onChange={({ selectedItem }) =>
                        changeUserHandler(selectedItem)
                      }
                      selectedItem={userInfo}
                    />
                  </div>
                ) : (
                  <p style={{ whiteSpace: "pre", marginRight: "5%" }}>
                    {userInfo?.name} {userInfo?.surname}
                  </p>
                )}
              </div>
              <div className={style.championSelectBlock}>
                <p
                  className={style.championSelectBlockText}
                >за период
                </p>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    id="date-picker-inline"
                    placeholder="с"
                    value={startTime}
                    onChange={handleStartDateChange}
                  />
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    minDate={startTime}
                    id="date-picker-inline"
                    placeholder="по"
                    value={endTime === 'Invalid Date' ? startTime : endTime}
                    onChange={handleEndDateChange}
                    animateYearScrolling={true}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className={style.formationButtonBlock}>
              <button
                onClick={getAssessmentProfessionalStandardHandler}
                className={style.customBtn}
              >Применить фильтры
              </button>
            </div>
          </div>
        </div>

        <div className={style.maxBallsBlock}>
          <div className={style.maxBalls}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                color: "#9A9A9A",
              }}
            >Лучший процент знаний</p>
            <p>{maxValue}</p>
          </div>
          <div className={style.allParticipants}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                color: "#9A9A9A",
              }}
            >Лучшая компетенция</p>
            <p>{nameCategories}</p>
          </div>
        </div>
        {isLoading
          ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Loader /></div>
          : allAssessment && allAssessment.length !== 0 ?
            <div className={style.championTable}>
              <Theme theme={'g10'}>
                <DataTable
                  rows={currentData}
                  headers={headerData}
                  isSortable >
                  {({ rows, headers, getHeaderProps, getTableProps }) => {
                    return (
                      <TableContainer title="">
                        <Table {...getTableProps()} style={{ background: '#FFFFFF' }}>
                          <TableHead style={{
                            backgroundColor: "#FFFFFF",
                            position: "sticky",
                            top: 0,
                            zIndex: 1000,
                            boxShadow: '0 0px 4px 0 rgba(0,0,0,.25)'
                          }}>
                            <TableRow>
                              {headers.map((header) => (
                                <TableHeader {...getHeaderProps({ header })}>
                                  {header.header}
                                </TableHeader >
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => {
                              const valuesCell = row.cells.find((cell) => cell.id.endsWith(':values'));
                              const serverResponseCell = row.cells.find((cell) => cell.id.endsWith(':serverresponseobject'));
                              const lastCellText = valuesCell.value <= serverResponseCell.value ? 'Соответствует' : 'Не соответствует';

                              return (
                                <TableRow key={row.id}>
                                  {row.cells.map((cell, index) => {
                                    if (index === 3 || index === 4) {
                                      return <TableCell key={cell.id}>{`${cell.value} %`}</TableCell>;
                                    }
                                    if (index === row.cells.length - 1) {
                                      return <TableCell key={cell.id}>{lastCellText}</TableCell>;
                                    }
                                    return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )
                  }}
                </DataTable>
              </Theme>

            </div> :
            <div style={{ margin: '25px auto 0 auto', paddingBottom: 20 }}>
              <p className={style.emptyTableText}
              >Чтобы увидеть информацию о соответствии профессиональному стандарту заполните фильтры
              </p>
            </div>
        }
      </div>
      {<div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: 10,
          paddingBottom: 7,
          marginBottom: 10,
          backgroundColor: '#F4F4F4',
          borderRadius: 4
        }}>
        {allAssessment.length > 0 &&
          <CustomTablePagination
            count={allAssessment.length}
            page={page}
            rowsPerPage={currentPageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onRowsPerPageChange={(newPageSize) => {
              setCurrentPageSize(newPageSize);
              setPage(0);
            }}
          />}
      </div>
      }
    </div>
  )
}

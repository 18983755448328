import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Girl5Months(props) {
  const { nodes, materials, animations } = useGLTF('patient/girl_5_monthsAnim.glb')
  const { ref, actions, names, mixer } = useAnimations(animations)
  const [index, setIndex] = useState(4)

  useEffect(() => {
    props.setAnim(animations)
    actions.rigAction.play()
  }, [actions, names, mixer])

  return (
    <group ref={ref} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="Girl_5Months_Cloth003" geometry={nodes.Girl_5Months_Cloth003.geometry} material={materials['materialMaterial.001']} skeleton={nodes.Girl_5Months_Cloth003.skeleton} />
          <group name="Girl_5Months_eyes">
            <skinnedMesh name="Girl_5Months_eyesCloth001" geometry={nodes.Girl_5Months_eyesCloth001.geometry} material={materials['Eye_lightblue.001']} skeleton={nodes.Girl_5Months_eyesCloth001.skeleton} />
            <skinnedMesh name="Girl_5Months_eyesCloth001_1" geometry={nodes.Girl_5Months_eyesCloth001_1.geometry} material={materials.bodyMaterial} skeleton={nodes.Girl_5Months_eyesCloth001_1.skeleton} />
            {/* <skinnedMesh name="Girl_5Months_eyesCloth001_2" geometry={nodes.Girl_5Months_eyesCloth001_2.geometry} material={materials.Eye_Glass} skeleton={nodes.Girl_5Months_eyesCloth001_2.skeleton} /> */}
          </group>
          <skinnedMesh name="Girl_5Months_template" geometry={nodes.Girl_5Months_template.geometry} material={materials.GIRL_5Months_Skin} skeleton={nodes.Girl_5Months_template.skeleton} />
          <skinnedMesh name="GirlCloth1" geometry={nodes.GirlCloth1.geometry} material={materials.FABRIC_1_FRONT_1893} skeleton={nodes.GirlCloth1.skeleton} />
        </group>
      </group>
    </group>
  )
}

// useGLTF.preload('patient/girl_5_monthsAnim.glb')

import { Avatar, createStyles, Theme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAsync } from 'react-use'
import chatApi from '../../api/chatApi'
import { getMediaContent, Medicine_ResponseData } from '../../api/chatApi/chatApi'
//@ts-ignore
import MedicalBoxIcon from '../../assets/img/common/medical_box.svg'
import ChatPagesMenuMobile from '../../components/Chat/ChatPagesMenuMobile'
import ChooseTaskStepper from '../../components/Chat/ChooseTaskStepper'
import CustomCard from '../../components/common/CustomCard'
import CustomHeader1 from '../../components/common/CustomHeader1'
import MainLayout from '../../components/Layout/MainLayout'
import MainHeader from '../../components/MainHeader/MainHeader'
import { RoutePath } from '../../types'
import NewTaskContentLayout from './NewTaskContentLayout'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'center',
      width: '100%',
      display: 'flex',
      minHeight: '100%',
      minWidth: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1)
      }
    },
    cards: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      backgroundColor: 'siver'
      // padding: theme.spacing(5),
    },
    ava: {
      width: '160px',
      height: '160px',
      margin: `0 auto`,
      textAlign: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      backgroundColor: 'transparent',
    },
    categoryName: {
      textTransform: 'none',
      fontSize: 20,
      fontWeight: 400
    },
    newChatTitle: {
      maxWidth: '1060px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px'
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '90%',
      margin: 'auto'

    },
    newChatTitleOrg: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '500px',
      width: '100%',
      margin: '0 auto',
      marginTop: '40px',
      color: '#6f6f6f',
      marginLeft: '12px'
    }
  })
)

type Props = {
  difficultId: number
  universityLevelId: number
  noAcademLevel?: boolean
  taskName?: string
  userOrganizations?: string[]
}

export default function ChooseCategoryPage(props: Props) {
  const history = useHistory()
  const classes = useStyles()

  const onClickItem = (item: Medicine_ResponseData) => {
    history.push(
      `${RoutePath.newChat}?universityLevelId=${props.universityLevelId}&difficultId=${props.difficultId}&categoryId=${item.id}`
    )
  }

  const [items, setItems] = useState<Array<Medicine_ResponseData>>([])
  const [mediaLink, setMediaLink] = useState<any>([])


  const categories = useAsync(() => {
    return chatApi
      .loadMedicineByUniversityLevel(props.universityLevelId, props.difficultId)
      .then((res: any) => {
        setItems(res.result)
      })
  }, [])



  const loadMediaContents = async (tasks) => {
    const mediaLinks = await Promise.all(tasks.map(task =>
      getMediaContent(task.picture)
        .then(res => ({ ...task, mediaLink: res.data.data_base64 }))
        .catch(err => {
          console.error('Ошибка при получении медиаконтента для задачи:', task.id, err);
          return { ...task, mediaLink: null };
        })
    ));

    setItems(mediaLinks);
  };


  useEffect(() => {
    if (items && items.length > 0) {
      loadMediaContents(items);
    }
  }, [items?.length]);

  // Выбор категории
  return (
    // <MainLayout mobileHeader={<ChatPagesMenuMobile title={'Специалитет'} />} title="Специалитет" hiddenSidebar>
    <MainLayout
      mobileHeader={<ChatPagesMenuMobile title={''} />}
      title=''
      hiddenSidebar
    >
      <MainHeader showComponentHelpFilled={false} />
      <div className={classes.headerContainer}>
        <h4 className={classes.newChatTitle}>
          Выбор задачи {props.taskName ? `для ${props.taskName}` : ''}
        </h4>
        {props.userOrganizations
          && <h4 className={classes.newChatTitleOrg}>
            <div style={{ marginRight: 10 }}>
              Организация(и):
            </div>
            {props.userOrganizations?.map((oneOrg) => <div>{oneOrg}</div>)}
          </h4>}
      </div>

      <NewTaskContentLayout>
        {categories.error ? <h1>Ошибка</h1> : null}
        <>
          <CustomHeader1 onClickBackBtn={history.goBack} />
          <ChooseTaskStepper
            activeStep={props.noAcademLevel ? 1 : 2}
            noAcademLevel={props.noAcademLevel}
          />
          {categories.loading ? (
            <CircularProgress style={{ alignSelf: `center` }} />
          ) :
            <div className={classes.cards}>
              {items.map(category => (
                <CustomCard
                  key={category.id}
                  onClick={onClickItem}
                  item={category}
                >
                  <div>
                    <Avatar className={classes.ava}>
                      <img
                        src={`data:image/jpeg;base64,${category?.mediaLink}`}
                        width='160'
                        alt='Patient'
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    </Avatar>
                    {/* {mediaLink.map((media) => (
                    media.id === category.id ?
                    <Avatar className={classes.ava}>
                    <img
                      src={`data:audio/mpeg;base64,${media.id === category.id ? media.link : ''}`}
                      width='160'
                      alt='Medicine'
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </Avatar>
                  :
                  null
                  ))} */}
                  </div>
                  <div className={classes.categoryName}>{category.name}</div>
                </CustomCard>
              ))}
            </div>}
          {/* <ChatHint title="Выберите Категорию" arrowSide="up" /> */}
        </>
      </NewTaskContentLayout>
    </MainLayout>
  )
}

import React from 'react'

export default function PreDiagnosisTooltip() {
  return (
    // ПРЕДВАРИТЕЛЬНЫЙ ДИАГНОЗ
    <div>
      На данном шаге поставьте предварительный диагноз. Программа проверяет
      добуквенное совпадение клинического диагноза и кода МКБ10.
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';

interface GfgInputInterface {
  id: number;
  onChange: (id: number, value: string) => void;
  inputCount: number;
  height?: string;
  value?: string; 
  disabled?: boolean;
}

export default function GfgInput({ id, onChange, inputCount, height, value = '', disabled }: GfgInputInterface) {
  const [inputValue, setInputValue] = useState(value.padEnd(inputCount, ' '));

  useEffect(() => {
    setInputValue(value.padEnd(inputCount, ' '));
  }, [value, inputCount]);

  const props = {
    inputStyle: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '40px',
      borderRadius: '3px',
      fontSize: '14px',
      height: height ? `${height}px` : '26px',
      paddingLeft: '7px',
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid black',
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '40px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: 'red',
      border: '1px solid red',
    },
  };

  return (
    <div>
      <ReactCodeInput
        type="text"
        fields={inputCount}
        value={inputValue}
        onChange={(value) => {
          setInputValue(value.padEnd(inputCount, ' ')); // Дополняем до необходимой длины
          onChange(id, value.trim()); // Обрезаем лишние пробелы перед передачей обратно
        }}
        {...props}
        disabled={disabled} 
      />
    </div>
  );
}
